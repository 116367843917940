
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {ForCards, ForCardsContainer,} from '../../components/ui/cards/style';
import {Content,TopContent,BackgroundContent,StyledContainer,StyledBackground} from '../../components/ui/containers/style';
import {
  TopTitle,StyledParagraph,StyledTitle3,} from '../../components/ui/text/style';
import { VideoContainer } from '../../components/ui/video/style';
import { BottomWave } from '../../components/ui/waves/style';
import { Dots } from '../../components/ui/dots/style';
import { GreenCircle } from '../../components/ui/circle/style';
import { GreenCross } from '../../components/ui/cross/style';
import { GreyCircle } from '../../components/ui/circle/style';
import { GreyCross } from '../../components/ui/cross/style';
import { LeftImages, EllipseImage } from '../../components/ui/image/style';
import { StyledCreditCard } from '../../components/creditCard/style';
import { TopWave } from '../../components/ui/waves/style';
import { ImagesContainer } from '../../components/ui/image/style';
import { ContactBtn } from '../../components/ui/buttons/style';
import { ExpensesSectionContainer, CardExpensesContainer, CardExpensesColumn, CardExpensesBlock, CardColumn } from './styles';

import { useModal } from 'use-modal-hook';
import ContactModalForm from '../../components/contactModalForm';
import AboutImage from '../../assets/about_group-on-floor.png';
import CardImage from '../../assets/card.png';

const greyCircles = [
    { width: 106, top: '0%', left: '52%' },
    { width: 204, top: '25%', right: '1%' },
    { width: 198, top: '56%', right: '6%' },
    { width: 106, top: '73%', left: '10%' },
  ];
  const greenCircles = [
    { width: 16, top: '2%', left: '45%' },
    { width: 24, top: '33%', left: '56%' },
    { width: 33, top: '35%', left: '9%' },
  ];
  const greyCrosses = [
    { width: 18, bottom: '100%', left: '37%' },
    { width: 18, bottom: '23%', left: '89%' },
    { width: 18, bottom: '51%', left: '35%' },
    { width: 10, bottom: '100%', left: '30%' },
    { width: 10, bottom: '80%', left: '90%' },
  ];
  const greenCrosses = [
    { width: 18, bottom: '48%', left: '25%' },
    { width: 18, bottom: '100%', left: '25%' },
    { width: 18, bottom: '70%', left: '55%' },
  ];
  const dotsList = [
    { bottom: '80%', left: '25%' },
    { bottom: '110%', left: '15%' },
    { bottom: '140%', left: '25%', rotate: '90' },
    { bottom: '65%', left: '86%' },
    { bottom: '67rem', left: '66%', rotate: '90' },
    { bottom: '110%', left: '96%' },
  ];


const Employers = () => {

    const [showModal] = useModal(ContactModalForm, {});
    const [textIndex, setTextIndex] = useState(0);
    const videoRef = useRef();


    return (
        <StyledContainer
        style={{
          maxWidth: '1440px',
          margin: '0 auto',
          top: '30px',
        }}
      >
        <Content>
        <TopContent className="attendant-section-container">
           
            <StyledParagraph style={{ margin: 0 }}>
           
                <StyledTitle3
                    as="span"
                    style={{
                    width: '100%',
                    textAlign: 'left',
                    margin: '0px',
                    }}
                >
                    <span>For Employers</span>
                </StyledTitle3>
                <br />
                <br />
                <span>
                Workforce demographics and employee interests are changing. Healthier, more satisfied employees result in reduced absenteeism, higher productivity, and increased talent retention. Employers need to offer a wider range of benefits to attract and retain talent. Employers can use the Tip a ScRxipt platform to eliminate the administrative burden of payroll reimbursements by offering nearly card exclusive options below:
                </span>
                <ForCardsContainer>
            <ForCards>
              <span>Health Reimbursement Arrangement (HRA)</span>
              <ul>
                <li>100% Employer Funded</li>
                <li>
                Funds are non-taxable to employee
                </li>
                <li>Limited to qualified medical expenses</li>
              </ul>
            </ForCards>
            <ForCards>
              <span>Lifestyle Spending Account (LSA)</span>
              <ul>
                <li>100% Employer Funded</li>
                <li>Funds are taxable to employee</li>
                <li>
                Available for nearly any allowed expense
                </li>
              </ul>
            </ForCards>
          </ForCardsContainer>
                <br />
                <br />
                <span>
                Set spending limits, control cash flow, and prevent misuse with location and merchant category-based restrictions, all on one card. Use the Tip a ScRxipt platform to track card activity in real-time and generate actionable insights with customized reporting. All of which is included when you use Tip a ScRxipt.</span>{' '}
           
            </StyledParagraph>
            <LeftImages>
            
              {/* <CelPhone1 src={Celphone1} /> */}
              {/* <CelPhone2 src={Celphone2} /> */}
              <EllipseImage imageURL={AboutImage} />
            
            </LeftImages>
          </TopContent>
          <ContactBtn onClick={() => showModal()}>Contact Us</ContactBtn>
         
          
          <ForCardsContainer>
            <ForCards>
              <span>For Employers</span>
              <ul>
                <li>Issue debit cards to your selected employees</li>
                <li>
                  Direct funds to their accounts from one or multiple sources
                </li>
                <li>Define how and where funds are used</li>
                <li>Measure program outcomes with advanced reporting</li>
                <li>
                  All administered from our secure, compliant, and user-friendly
                  platform
                </li>
              </ul>
            </ForCards>
            <ForCards>
              <span>For Employees</span>
              <ul>
                <li>Receive a debit card from your employer</li>
                <li>Download the mobile app</li>
                <li>
                  Use your card to pay for eligible medical expenses at authorized
                  locations
                </li>
                <li>
                  View your transaction history and manage your account in the app and online
                </li>
              </ul>
            </ForCards>
          </ForCardsContainer>

         
         <ExpensesSectionContainer>
         <StyledParagraph as="div" style={{ width: '100%' }}>
              <StyledTitle3
                as="span"
                style={{
                  width: '100%',
                  textAlign: 'left',
                  margin: '0',
                }}
              >
               <span>One card,</span> multiple accounts for eligible expenses.
              </StyledTitle3>


            </StyledParagraph>

            <CardExpensesContainer>
                <CardExpensesColumn>
                    <CardExpensesBlock>
                        <h4>Wellness: $100</h4>
                        <p>
                        Yoga, gym memberships,
mindfulness, therapy.
41% of employees report
burnout with financial stress
as a leading cause.
                        </p>
                    </CardExpensesBlock>

                    <CardExpensesBlock>
                        <h4>Medical Expenses: $200</h4>
                        <p>
                        OTC drugs, prescriptions,
medical equipment.
1 in 6 adults have cut other
spending to help afford
medical bills.
                        </p>
                    </CardExpensesBlock>
                </CardExpensesColumn>
                <CardColumn>
                <StyledCreditCard src={CardImage} />
                </CardColumn>
                
                <CardExpensesColumn>
                  <CardExpensesBlock>
                        <h4>Employee Assistance:
$100</h4>
                        <p>
                        Housing, childcare,
education, training.
                        </p>
                    </CardExpensesBlock>

                    <CardExpensesBlock>
                        <h4>Commuting: $50</h4>
                        <p>
                        Transit, parking,
Uber/Lyft, gas.
                        </p>
                    </CardExpensesBlock>
                </CardExpensesColumn>

            </CardExpensesContainer>

         </ExpensesSectionContainer>
            
  
          
         
        </Content>
  
        <BackgroundContent>
          <StyledBackground />
          <TopWave />
          <BottomWave />
          {greyCircles.map((circle, key) => (
            <GreyCircle
              key={key}
              width={circle.width}
              top={circle.top}
              left={circle.left}
              bottom={circle.bottom}
              right={circle.right}
            />
          ))}
  
          {greenCircles.map((circle, key) => (
            <GreenCircle
              key={key}
              width={circle.width}
              top={circle.top}
              left={circle.left}
              bottom={circle.bottom}
              right={circle.right}
            />
          ))}
          {greyCrosses.map((cross, key) => (
            <GreyCross
              key={key}
              width={cross.width}
              top={cross.top}
              left={cross.left}
              bottom={cross.bottom}
              right={cross.right}
            />
          ))}
  
          {greenCrosses.map((cross, key) => (
            <GreenCross
              key={key}
              width={cross.width}
              top={cross.top}
              left={cross.left}
              bottom={cross.bottom}
              right={cross.right}
            />
          ))}
  
          {dotsList.map((dot, key) => (
            <Dots
              key={key}
              rotate={dot.rotate}
              top={dot.top}
              left={dot.left}
              bottom={dot.bottom}
              right={dot.right}
            />
          ))}
        </BackgroundContent>
      </StyledContainer>
    )
}

export default Employers;