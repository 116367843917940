import styled from 'styled-components';
export const StyledFooterContainer = styled.div`
  height: fit-content;
  background: #323232;
  display: flex;
  flex-direction: row;
  color: #fff;
  padding: 1rem;
  position: relative;

  @media (max-width: 490px) {
    flex-direction: column-reverse;
    align-items: center;

    & > div {
      width: 100%;
      max-width: unset;
      display: flex;
      justify-content: center;
    }
  }
`;
export const StyledCopyright = styled.span`
  margin: auto 2rem;

  @media (max-width: 490px) {
    margin: 0;
    padding-bottom: 10px;
  }
`;
