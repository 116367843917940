import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.165rem 4rem auto auto;
  justify-content: end;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
  }
`;

export const StyledButtonNav = styled.div`
  flex: 2;
  padding-right: 0.75rem;
`;

export const StyledNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 490px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const StyledButtonNavSpan = styled(Link)`
  cursor: pointer
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 0.65rem;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;

  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 30px;

  background: #32cd32;
  color: #ffffff;
  
  line-height: 0.85rem;

  padding: 0.325rem 0.965rem;
  height: 1.65rem;
  width: 6.75rem;
  outline: none;

  &:hover {
    
  }
`;
