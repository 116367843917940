import styled, { css } from 'styled-components';

export const ExpensesSectionContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: left;
    max-width: 1000px;
    margin: 100px auto;

    @media (max-width: 750px) {
        display: none;
      }

    `

export const CardExpensesContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    margin: 20px;

`

export const CardExpensesColumn = styled.div`
    position: relative;    
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    
`

export const CardColumn = styled.div`
    position: relative;    
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 400px;
`


export const CardExpensesBlock = styled.div`
    position: relative;
    
    display: block;
    margin-top:20px;
    text-align: left;
    background-color: #32cd32;
    color: #ffffff;
    width: 100%;
    padding:20px;
    min-height: 150px;

    & h4 {
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 20px;
      }

      & p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        }
`

