import { useRef, useLayoutEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { fromLargeMediaQuery } from '../../constants';
import {
  ContactTitleContainer,
  ContactContainer,
  ContactFormContainer,
  ContactTitle,
} from './style';
import DecorationContainer from '../../components/decorationContainer';
import Decoration from '../../components/decoration';
import { DIRECTION } from '../../components/line';

const useIsMounted = () => {
  const [state, setState] = useState(false);
  useLayoutEffect(() => {
    setState(true);
  }, []);
  return state;
};

const Contact = () => {
  const isLarge = useMedia(fromLargeMediaQuery);

  // HACK: force re render 1 time
  // eslint-disable-next-line
  const isMounted = useIsMounted();

  const titleRef = useRef(null);
  const title = titleRef?.current;
  const contactFormRef = useRef(null);
  const contactForm = contactFormRef?.current;

  return (
    <>
      {isLarge && (
        <>
          <DecorationContainer
            style={{
              transform: 'translate(-100%, -100%)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.TOP_RIGHT,
                offset: {
                  x: -41,
                  y: -91.59,
                },
              },
            ]}
          >
            <Decoration width={12.95} height={12.95} name="cross"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, -100%)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.TOP_LEFT,
                offset: {
                  x: 55,
                  y: -35,
                },
              },
            ]}
          >
            <Decoration width={19} height={19} name="circle"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(-100%, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_LEFT,
                offset: {
                  x: -61,
                  y: 93,
                },
              },
            ]}
          >
            <Decoration width={13} height={13} name="circle"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(-100%, -100%)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.TOP_LEFT,
                offset: {
                  x: -26,
                  y: -5,
                },
              },
            ]}
          >
            <Decoration width={10} height={10} name="cross"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(-100%, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.TOP_LEFT,
                offset: {
                  x: -66,
                  y: -69,
                },
              },
            ]}
          >
            <Decoration name="squares1x5"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.TOP_LEFT,
                offset: {
                  x: 273,
                  y: -8,
                },
              },
            ]}
          >
            <Decoration
              color="#32cd32"
              width={8}
              height={8}
              name="cross"
            ></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(-100%, -100%)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_RIGHT,
                offset: {
                  x: -52,
                  y: -69,
                },
              },
            ]}
          >
            <Decoration width={10} height={10} name="circle"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_LEFT,
                offset: {
                  x: 106,
                  y: 196,
                },
              },
            ]}
          >
            <Decoration width={11.45} height={11.45} name="cross"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_LEFT,
                offset: {
                  x: -11,
                  y: 413,
                },
              },
            ]}
          >
            <Decoration width={17.93} height={17.93} name="cross"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_RIGHT,
                offset: {
                  x: -44,
                  y: 354,
                },
              },
            ]}
          >
            <Decoration width={11.47} height={11.47} name="cross"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_RIGHT,
                offset: {
                  x: -108,
                  y: 386,
                },
              },
            ]}
          >
            <Decoration
              color="#32cd32"
              width={18}
              height={18}
              name="cross"
            ></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_LEFT,
                offset: {
                  x: 174,
                  y: 387,
                },
              },
            ]}
          >
            <Decoration name="dots6x2"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.BOTTOM_LEFT,
                offset: {
                  x: 80,
                  y: 260,
                },
              },
            ]}
          >
            <Decoration name="squares1x4"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: contactForm,
                direction: DIRECTION.TOP_RIGHT,
                offset: {
                  x: 0,
                  y: 0,
                },
              },
            ]}
          >
            <Decoration width={15} height={15} name="circle"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: contactForm,
                direction: DIRECTION.TOP_RIGHT,
                offset: {
                  x: -20,
                  y: 32,
                },
              },
            ]}
          >
            <Decoration name="squares1x5"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: contactForm,
                direction: DIRECTION.BOTTOM_LEFT,
                offset: {
                  x: 100,
                  y: 0,
                },
              },
            ]}
          >
            <Decoration name="dots3x6"></Decoration>
          </DecorationContainer>
          <DecorationContainer
            style={{
              transform: 'translate(0, 0)',
            }}
            points={[
              {
                node: contactForm,
                direction: DIRECTION.BOTTOM_RIGHT,
                offset: {
                  x: -20,
                  y: 0,
                },
              },
            ]}
          >
            <Decoration
              color="#32cd32"
              width={15}
              height={15}
              name="cross"
            ></Decoration>
          </DecorationContainer>

          <DecorationContainer
            style={{
              transform: 'translate(-100%, -25%)',
            }}
            points={[
              {
                node: title,
                direction: DIRECTION.TOP_RIGHT,
              },
            ]}
          >
            <Decoration name="contact1"></Decoration>
          </DecorationContainer>
        </>
      )}
      <ContactContainer>
        <ContactTitleContainer>
          <ContactTitle ref={titleRef}>
            Ready to learn more about <br /> Tip a ScRxipt?
          </ContactTitle>
        </ContactTitleContainer>
        <ContactFormContainer>
          <div ref={contactFormRef}>
            <iframe
              id="JotFormIFrame-212286018479057"
              title="Contact Us"
              onLoad={() => window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212286018479057"
              frameBorder="0"
              style={{
                minWidth: '100%',
                height: '539px',
                border: 'none',
              }}
              scrolling="no"
            ></iframe>
          </div>
        </ContactFormContainer>
      </ContactContainer>
    </>
  );
};

export default Contact;
