import React, { forwardRef } from 'react';
import { TLContainer, TLTitle, TLYear, TLText, TLImage } from './style';

const TimeLineItem = forwardRef((props, ref) => {
  const { text, month, year, imageURL, maxWidth, offsetTop, imgRef } = props;

  return (
    <TLContainer offsetTop={offsetTop} maxWidth={maxWidth}>
      <TLTitle>{month}</TLTitle>
      <TLYear>
        20<span>{year}</span>
      </TLYear>
      <TLText ref={ref}>{text}</TLText>
      {imageURL !== '' && <TLImage ref={imgRef} imageURL={imageURL} />}
    </TLContainer>
  );
});

export default TimeLineItem;
