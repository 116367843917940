import styled from 'styled-components';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 490px) {
    flex-direction: column;

    & > h2 {
      padding: 0 0 0 0.5rem;
    }

    & > div {
      padding: 0rem;
    }
  }
`;
export const ContactTitle = styled.h2`
  position: relative;
  font-size: 4.375rem;
  font-weight: bold;
  margin: 0;
  max-width: 522px;
`;

export const ContactTitleContainer = styled.div`
  flex: 1;
  padding: 4rem;
  margin: 58px 0;
`;

export const ContactFormContainer = styled.div`
  flex: 1;
  padding: 4rem;
`;

export const ContactFromField = styled.div`
  width: 100%;
  padding: 2rem 0;
  font-size: 12px;
  & span {
    color: #32cd32;
  }
  & input,
  textarea,
  select {
    font-size: 22px;
    border: solid 1px #fff;
    border-bottom: solid 1px #32cd32;
    width: 100%;
  }
  & input[type='submit'] {
    background: #32cd32;
    color: #fff;
    width: fit-content;
    right: 4rem;
    position: absolute;
    padding: 1rem;
    border-radius: 2rem;
  }
`;
