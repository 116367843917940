import React from 'react';
import {
  NavContainer,
  StyledButtonNav,
  StyledFacebook,
  StyledLinkedIn,
  StyledTwitter,
  StyledButtonNavSpan,
  StyledNavContainer,
} from './style';
import { useLocation } from 'react-router-dom';
import { useModal } from 'use-modal-hook';
import ContactModalForm from '../contactModalForm';

const NavToolBar = ({
  footer = false,
  burgerMenu = false,
  hideSocials = false,
}) => {
  const location = useLocation();
  const [showModal] = useModal(ContactModalForm, {});

  const redirectSocialMedia = (url) => () => {
    window.open(url, '_blank');
  };

  return (
    <NavContainer burgerMenu={burgerMenu}>
      <StyledNavContainer>
        <StyledButtonNav>
          <StyledButtonNavSpan
            footer={+footer}
            activenav={+(location.pathname === '/')}
            to="/"
          >
            Home
          </StyledButtonNavSpan>
        </StyledButtonNav>
        <StyledButtonNav>
          <StyledButtonNavSpan
            footer={+footer}
            activenav={+(location.pathname === '/about')}
            to="/about"
          >
            About Us
          </StyledButtonNavSpan>
        </StyledButtonNav>

        <StyledButtonNav>
          <StyledButtonNavSpan
            footer={+footer}
            activenav={+(location.pathname === '/health-plans')}
            to="/health-plans"
          >
            For Health Plans
          </StyledButtonNavSpan>
        </StyledButtonNav>
        <StyledButtonNav>
          <StyledButtonNavSpan
            footer={+footer}
            activenav={+(location.pathname === '/healthcare-providers')}
            to="/healthcare-providers"
          >
            For Healthcare Providers
          </StyledButtonNavSpan>
        </StyledButtonNav>
        <StyledButtonNav>
          <StyledButtonNavSpan
            footer={+footer}
            activenav={+(location.pathname === '/pharmaceutical-companies')}
            to="/pharmaceutical-companies"
          >
            For Pharmaceutical Companies
          </StyledButtonNavSpan>
        </StyledButtonNav>
        <StyledButtonNav>
          <StyledButtonNavSpan
            footer={+footer}
            activenav={+(location.pathname === '/drug-discount-card')}
            to="/drug-discount-card"
          >
            Drug Discount Card
          </StyledButtonNavSpan>
        </StyledButtonNav>
        <StyledButtonNav>
          <StyledButtonNavSpan
            onClick={showModal}
            footer={+footer}
            to={location.pathname}
          >
            Contact Us
          </StyledButtonNavSpan>
        </StyledButtonNav>
      </StyledNavContainer>
      <StyledNavContainer hideSocials={hideSocials}>
        <StyledLinkedIn
          footer={+footer}
          onClick={redirectSocialMedia(
            'https://www.linkedin.com/company/tip-a-scrxipt/'
          )}
        />
        <StyledFacebook
          footer={+footer}
          onClick={redirectSocialMedia(
            'https://www.facebook.com/Tip-a-ScRxipt-256941909537628'
          )}
        />
        <StyledTwitter
          footer={+footer}
          onClick={redirectSocialMedia('https://twitter.com/TipaScRxipt')}
        />
      </StyledNavContainer>
    </NavContainer>
  );
};
export default NavToolBar;
