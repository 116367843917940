import styled, { css } from 'styled-components';
import dots from '../../../assets/dots.png';

export const Dots = styled.div`
  width: 200px;
  height: 200px;
  z-index: 1000;
  position: relative;
  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
${(props) =>
    props.right &&
    css`
      right: ${props.right};
    `}
${(props) =>
    props.top &&
    css`
      top: ${props.top};
    `}
${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `}
background: url(${dots}) no-repeat;
`;


