import React, { useEffect, useState } from 'react';
import {
  BurgerButtonLogoContainer,
  BurgerMenuIcon,
  Logo,
  NavBarContainer,
  StyledButton,
  StyledHeader,
} from './style';
import logo from '../../assets/logo.png';
import NavToolBar from '../../components/navToolBar';
import SignInBar from '../../components/signInBar';
import './header.css';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const location = useLocation();

  const onBurgerIconClick = () =>
    setIsBurgerMenuOpen((prevState) => !prevState);

  useEffect(() => {
    if (isBurgerMenuOpen) setIsBurgerMenuOpen();
    //eslint-disable-next-line
  }, [setIsBurgerMenuOpen, location.pathname]);

  return (
    <>
      <SignInBar />
      <StyledHeader
        className={isBurgerMenuOpen ? 'openBurgerMenu' : 'closeBurgerMenu'}
        burgerMenu={isBurgerMenuOpen}
      >
        <BurgerButtonLogoContainer>
          <Logo
            onClick={(e) => (window.location.href = '/')}
            height="36"
            src={logo}
            alt="Tip A ScRxipt"
          />
          <StyledButton onClick={onBurgerIconClick}>
            <BurgerMenuIcon />
          </StyledButton>
        </BurgerButtonLogoContainer>
        <NavBarContainer>
          <NavToolBar burgerMenu hideSocials />
        </NavBarContainer>
      </StyledHeader>
    </>
  );
};

export default Header;
