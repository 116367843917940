import styled, { css } from 'styled-components';

const Circle = styled.div`
  position: absolute;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
      height: ${props.width}px;
      border-radius: ${props.width / 2}px;
    `}
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
${(props) =>
    props.top &&
    css`
      top: ${props.top};
    `}
${(props) =>
    props.right &&
    css`
      right: ${props.right};
    `}
${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `}
`;




export const GreyCircle = styled(Circle)`
  background: #f2f2f2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
`;
export const GreenCircle = styled(Circle)`
  background: #32cd32;
`;
