import styled, { css } from 'styled-components';

import topWaveImg from '../../../assets/topWave.png';
import bottomWaveImg from '../../../assets/bottomWave.png';
export const TopWave = styled.div`
  width: 500px;
  position: absolute;
  top: 0%;
  left: 55%;
  height: 65%;
  background: url(${topWaveImg}) no-repeat;
`;

export const BottomWave = styled.div`
  width: 100%;
  position: absolute;
  top: 52%;
  left: 0%;
  height: 97%;
  background: url(${bottomWaveImg}) no-repeat;
  background-position: -75% 0;
`;
