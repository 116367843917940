import styled, { css } from 'styled-components';
import { fromLargeMediaQuery } from '../../constants';

export const TLTitle = styled.h5`
  background-color: #fff;
  margin: 0 2rem -0.8rem;
  font-weight: 500;
  font-size: 16px;
  padding: 0.6rem;
  border-radius: 1.2rem;
  border: 0.8px solid #32cd32;
  box-sizing: border-box;
  width: fit-content;
`;

export const TLYear = styled.h3`
  z-index: 1;
  margin: 0 2rem;
  font-weight: 900;
  font-size: 90px;
  line-height: 106px;
  & span {
    color: #32cd32;
  }
`;

export const TLText = styled.div`
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(114, 136, 139, 0.25);
  border-radius: 21px;
  padding: 5rem 2rem 3rem;
  margin-top: -4rem;
`;

export const TLContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.maxWidth &&
    css`
      @media ${fromLargeMediaQuery} {
        max-width: ${props.maxWidth};
      }
    `}

  ${(props) =>
    props.offsetTop &&
    css`
      @media ${fromLargeMediaQuery} {
        margin-top: ${props.offsetTop};
      }
    `}

  @media (max-width: 890px) {
    width: 80%;
  }
`;

export const TLImage = styled.div`
  width: 14rem;
  height: 14rem;
  border-radius: 7rem;
  margin: -7rem;
  position: relative;
  z-index: -1;
  ${(props) =>
    props.imageURL &&
    css`
      background-image: url('${props.imageURL}');
    `}

  @media (max-width: 740px) {
    margin-bottom: 10px;
  }
`;
