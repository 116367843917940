import styled, { css } from 'styled-components';
import { TopContent, StepRectangle } from '../containers/style';

export const ForCards = styled(StepRectangle)`
  left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px;
  box-sizing: border-box;
  max-width: 350px;
  height: auto;
  font-size: 18px;

  span {
    display: block;
    font-weight: 600;
    margin-bottom: 1em;
    flex: none;
  }

  li:not(:last-of-type) {
    margin-bottom: 1em;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    flex: 1 1 0;
    margin: 0;
    padding: 0 0 0 1em;
    list-style-position: outside;
  }

  @media (max-width: 450px) {
    padding: 28px;
  }
`;

export const ForCardsContainer = styled(TopContent)`
  display: flex;
  margin: 2rem auto 0;
  max-width: 800px;
  justify-content: space-between;

  @media (max-width: 890px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

    > *:nth-child(1) {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 450px) {
    padding: 0 2rem;
  }
`;

export const GridFourCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridThreeCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ForSectionCards = styled(StepRectangle)`
  left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  max-width: 350px;
  height: auto;
  font-size: 18px;
  cursor: pointer;
  svg {
    stroke: #32cd32;
    color: #32cd32;
  }

  p {
    margin: 10px 0;
  }

  span {
    display: block;
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 1em;
    flex: none;
  }

  li:not(:last-of-type) {
    margin-bottom: 1em;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 0;
    margin: 0;
    padding: 0 0 0 1em;
    list-style-position: outside;
  }

  @media (max-width: 450px) {
    padding: 28px;
  }
`;
