import styled, { css } from 'styled-components';



export const LeftImages = styled.div`
  width: 40%;
  padding: 4rem;
  flex: 1;

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}

  @media (max-width: 850px) {
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const ImagesContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const EllipseImage = styled.div`
    position: absolute;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    ${(props) =>
        props.imageURL &&
        css`
          background-image: url('${props.imageURL}');
        `}
   
        @media (max-width: 1000px) {
          display: none;
        }
        
        `;
        