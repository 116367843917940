import {createGlobalStyle} from 'styled-components';
export const GlobalStyle = createGlobalStyle`  
 @font-face {
    font-family: 'Work Sans';
    src: local('Work Sans') url('.//assets/WorkSans-VariableFont_wght.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
 body{
    padding: 0;
    margin:0;
    height:100%;
    font-family: 'Work Sans', sans-serif;
    overflow-x:hidden;
    max-width:100vw;
 }
 body > div{
    max-width:100vw;
 }
 `;
