import styled, { css } from 'styled-components';

export const Content = styled.div`
  position: relative;
  left: 0;
  width: 98%;
  height: 100%;
  padding: 0 1%;
  z-index: 100;
`;

export const TopContent = styled.div`
  display: flex;
`;

export const BackgroundContent = styled.div`
  width: 100%;
  height: 105rem;
  top: 1rem;
  left: 0;
  position: absolute;
  z-index: 0;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
`;


export const StyledBackground = styled.div`
  background-color: #f2f2f2;
  top: 2%;
  width: 80%;
  height: 142%;
  margin: auto;
  position: relative;
`;


export const StepRectangle = styled.div`
  position: relative;
  margin: 5%;
  left: 3%;
  bottom: 20%;
  height: 20rem;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(176, 190, 195, 0.3);
  border-radius: 20px;
  & p {
    font-size: 15px;
    margin: auto 3rem;
    flex: 1;
    @media (max-width: 882px) {
      margin: auto 1rem;
    }
  }
`;