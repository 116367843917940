import styled, { css } from 'styled-components';
import { untilLargeMediaQuery, fromLargeMediaQuery } from '../../constants';

export const AboutContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const AboutContentRow = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  align-items: start;
  justify-items: center;

  @media ${untilLargeMediaQuery} {
    grid-template-columns: auto;
    grid-row-gap: 20px;
  }
`;

export const AboutTitle = styled.h3`
  font-size: 70px;
  line-height: 60px;
  font-weight: bold;

  @media ${fromLargeMediaQuery} {
    margin-bottom: 0;
  }

  @media (max-width: 1000px) {
    padding-left: 20px;
  }
`;

export const AboutSubTitle = styled.h3`
  padding-left: 60px;
  & span {
    color: #32cd32;
  }
  font-weight: bold;
  font-size: 50px;
  @media (max-width: 490px) {
    width: 80%;
    padding-left: 0;
  }

  ${(props) =>
    props.offsetTop &&
    css`
      @media ${fromLargeMediaQuery} {
        margin-top: ${props.offsetTop};
      }
    `}
`;

export const AboutLaunchContainer = styled.div`
  position: relative;
  margin: 10rem auto 4rem;
  background: #ffffff;
  padding: 1rem 3rem;
  box-shadow: 0px 15px 35px rgba(114, 136, 139, 0.25);
  border-radius: 2rem;
  width: 50%;
  display: flex;

  @media (max-width: 920px) {
    flex-wrap: wrap;
  }

  @media (max-width: 720px) {
    margin-top: 1rem;
  }
`;

export const AboutMonth = styled.h5`
  font-weight: 500;
  font-size: 16px;
  padding: 0.6rem;
  border-radius: 1.2rem;
  border: 0.8px solid #32cd32;
  box-sizing: border-box;
  width: fit-content;
  margin-bottom: 0;
`;
export const AboutYear = styled.h3`
  & span {
    color: #32cd32;
  }
  font-weight: bold;
  font-size: 7.5rem;
  margin: 0;

  @media (max-width: 450px) {
    font-size: 5rem;
  }
`;

export const AboutLaunchTitle = styled.h4`
  font-weight: bold;
  font-size: 48px;
  margin-left: 3rem;

  & span {
    color: #32cd32;
  }

  @media (max-width: 920px) {
    margin: 0;
  }
`;
