import styled, { css } from 'styled-components';

const Cross = styled.div`
  position: relative;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
      height: ${props.width}px;
    `}
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
${(props) =>
    props.right &&
    css`
      right: ${props.right};
    `}
${(props) =>
    props.top &&
    css`
      top: ${props.top};
    `}
${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `}
z-index:100;
  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
  }
  &:before {
    left: 50%;
    width: 30%;
    margin-left: -15%;
    height: 100%;
  }
  &:after {
    top: 50%;
    height: 30%;
    margin-top: -15%;
    width: 100%;
  }
`;



export const GreyCross = styled(Cross)`
  &:before,
  &:after {
    background: #444444;
  }
`;
export const GreenCross = styled(Cross)`
  &:before,
  &:after {
    background: #32cd32;
  }
`;
