import styled, { css } from 'styled-components';

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .vjs-poster,
  .video-js {
    background-color: #ffffff;
  }
`;