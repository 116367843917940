import React from 'react';

function Decoration(props) {
  const { name, width, height, color } = props;

  if (name === 'squares1x4') {
    return (
      <svg
        width="5"
        height="80"
        viewBox="0 0 5 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.74">
          <path
            opacity="0.74"
            d="M4.06534 0.625L0.492188 0.625L0.492187 4.19711L4.06534 4.19711L4.06534 0.625Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.04984 25.8091L0.476562 25.8091L0.476562 29.3812L4.04984 29.3812L4.04984 25.8091Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.0654 50.9771L0.492188 50.9771L0.492187 54.5491L4.0654 54.5491L4.0654 50.9771Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.04984 76.1606L0.476562 76.1606L0.476562 79.7328L4.04984 79.7328L4.04984 76.1606Z"
            fill="#444444"
          />
        </g>
      </svg>
    );
  }

  if (name === 'squares1x5') {
    return (
      <svg
        width="5"
        height="105"
        viewBox="0 0 5 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.74">
          <path
            opacity="0.74"
            d="M4.32909 4.26103e-08L0.755859 0L0.755859 3.57202L4.32909 3.57202L4.32909 4.26103e-08Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.31346 25.1104L0.740234 25.1104L0.740234 28.6824L4.31346 28.6824L4.31346 25.1104Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.32909 50.2783L0.755859 50.2783L0.755859 53.8505L4.32909 53.8505L4.32909 50.2783Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.31346 75.4624L0.740234 75.4624L0.740234 79.0344L4.31346 79.0344L4.31346 75.4624Z"
            fill="#444444"
          />
          <path
            opacity="0.74"
            d="M4.31346 100.63L0.740234 100.63L0.740234 104.202L4.31346 104.202L4.31346 100.63Z"
            fill="#444444"
          />
        </g>
      </svg>
    );
  }

  if (name === 'dots3x6') {
    return (
      <svg
        width="30"
        height="71"
        viewBox="0 0 30 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.17193 66.2552C2.60502 66.252 3.0293 66.3774 3.39098 66.6156C3.75266 66.8538 4.03545 67.194 4.20343 67.5931C4.37141 67.9922 4.41704 68.4321 4.33453 68.8572C4.25202 69.2822 4.04507 69.6732 3.73997 69.9805C3.43487 70.2878 3.04534 70.4976 2.6208 70.5833C2.19626 70.6689 1.75584 70.6266 1.35539 70.4617C0.954945 70.2967 0.612521 70.0166 0.371557 69.6568C0.130593 69.2971 0.00196521 68.8738 0.00195315 68.4409C0.00193709 67.864 0.230008 67.3105 0.636499 66.9011C1.04299 66.4917 1.59491 66.2595 2.17193 66.2552Z"
          fill="#32CD32"
        />
        <path
          d="M2.17205 53.0106C2.60584 53.0073 3.03079 53.1332 3.39283 53.3721C3.75486 53.611 4.03765 53.9522 4.20515 54.3522C4.37265 54.7523 4.41731 55.1931 4.33347 55.6186C4.24963 56.0441 4.04106 56.435 3.73431 56.7417C3.42756 57.0483 3.0365 57.2568 2.61088 57.3406C2.18525 57.4244 1.7443 57.3798 1.34414 57.2123C0.943971 57.0449 0.602657 56.7622 0.363661 56.4003C0.124665 56.0384 -0.00121177 55.6136 0.00201362 55.1799C0.00628306 54.6059 0.236261 54.0566 0.642304 53.6507C1.04835 53.2448 1.59783 53.0148 2.17205 53.0106Z"
          fill="#32CD32"
        />
        <path
          d="M2.17193 39.7498C2.60502 39.7466 3.0293 39.872 3.39098 40.1102C3.75266 40.3484 4.03545 40.6886 4.20343 41.0877C4.37141 41.4868 4.41704 41.9268 4.33453 42.3518C4.25202 42.7769 4.04507 43.1678 3.73997 43.4751C3.43487 43.7824 3.04534 43.9922 2.6208 44.0779C2.19626 44.1636 1.75584 44.1212 1.35539 43.9563C0.954945 43.7914 0.612521 43.5112 0.371557 43.1515C0.130593 42.7917 0.00196521 42.3685 0.00195315 41.9355C0.00193709 41.3586 0.230008 40.8052 0.636499 40.3957C1.04299 39.9863 1.59491 39.7541 2.17193 39.7498V39.7498Z"
          fill="#32CD32"
        />
        <path
          d="M2.17193 26.571C2.60111 26.571 3.02068 26.6982 3.37753 26.9366C3.73439 27.1749 4.0125 27.5137 4.17674 27.9101C4.34098 28.3065 4.38395 28.7427 4.30022 29.1635C4.21649 29.5843 4.00984 29.9709 3.70636 30.2743C3.40288 30.5777 3.01624 30.7843 2.5953 30.868C2.17437 30.9517 1.73801 30.9087 1.3415 30.7445C0.944985 30.5803 0.606115 30.3023 0.367673 29.9455C0.129231 29.5888 0.00195315 29.1694 0.00195315 28.7403C0.00622247 28.1663 0.236194 27.617 0.642225 27.2111C1.04826 26.8051 1.59773 26.5752 2.17193 26.571Z"
          fill="#32CD32"
        />
        <path
          d="M2.17193 13.2444C2.60502 13.2412 3.0293 13.3667 3.39098 13.6048C3.75266 13.843 4.03545 14.1832 4.20343 14.5823C4.37141 14.9814 4.41704 15.4214 4.33453 15.8464C4.25202 16.2715 4.04507 16.6624 3.73997 16.9698C3.43487 17.2771 3.04534 17.4869 2.6208 17.5725C2.19626 17.6582 1.75584 17.6159 1.35539 17.4509C0.954945 17.286 0.612521 17.0059 0.371557 16.6461C0.130593 16.2863 0.00196521 15.8631 0.00195315 15.4301C0.00193709 14.8533 0.230008 14.2998 0.636499 13.8904C1.04299 13.4809 1.59491 13.2488 2.17193 13.2444V13.2444Z"
          fill="#32CD32"
        />
        <path
          d="M2.17153 0.000302113C2.60804 -0.00265097 3.03546 0.125092 3.39872 0.367078C3.76198 0.609063 4.04446 0.954214 4.20979 1.35809C4.37513 1.76196 4.41574 2.20607 4.32641 2.63322C4.23707 3.06037 4.02187 3.451 3.70853 3.75482C3.39519 4.05865 2.99804 4.26175 2.56823 4.33798C2.13842 4.4142 1.69564 4.36006 1.29688 4.18253C0.898111 4.00499 0.56161 3.71219 0.33075 3.34183C0.0998905 2.97147 -0.0147567 2.54049 0.0015215 2.10441C0.0225228 1.5418 0.25985 1.00899 0.664111 0.617008C1.06837 0.225027 1.60836 0.00411204 2.17153 0.000302113V0.000302113Z"
          fill="#32CD32"
        />
        <path
          d="M16.9221 68.441C16.9221 67.2429 15.9506 66.2716 14.7521 66.2716C13.5536 66.2716 12.582 67.2429 12.582 68.441C12.582 69.6391 13.5536 70.6104 14.7521 70.6104C15.9506 70.6104 16.9221 69.6391 16.9221 68.441Z"
          fill="#32CD32"
        />
        <path
          d="M16.9221 55.1803C16.9221 53.9822 15.9506 53.0109 14.7521 53.0109C13.5536 53.0109 12.582 53.9822 12.582 55.1803C12.582 56.3784 13.5536 57.3496 14.7521 57.3496C15.9506 57.3496 16.9221 56.3784 16.9221 55.1803Z"
          fill="#32CD32"
        />
        <path
          d="M16.9221 41.9356C16.9221 40.7375 15.9506 39.7663 14.7521 39.7663C13.5536 39.7663 12.582 40.7375 12.582 41.9356C12.582 43.1337 13.5536 44.105 14.7521 44.105C15.9506 44.105 16.9221 43.1337 16.9221 41.9356Z"
          fill="#32CD32"
        />
        <path
          d="M16.9221 28.6749C16.9221 27.4768 15.9506 26.5055 14.7521 26.5055C13.5536 26.5055 12.582 27.4768 12.582 28.6749C12.582 29.873 13.5536 30.8442 14.7521 30.8442C15.9506 30.8442 16.9221 29.873 16.9221 28.6749Z"
          fill="#32CD32"
        />
        <path
          d="M16.9221 15.4303C16.9221 14.2322 15.9506 13.2609 14.7521 13.2609C13.5536 13.2609 12.582 14.2322 12.582 15.4303C12.582 16.6284 13.5536 17.5996 14.7521 17.5996C15.9506 17.5996 16.9221 16.6284 16.9221 15.4303Z"
          fill="#32CD32"
        />
        <path
          d="M16.9221 2.16952C16.9221 0.971422 15.9506 0.000167887 14.7521 0.000167873C13.5536 0.000167858 12.582 0.971422 12.582 2.16952C12.582 3.36762 13.5536 4.33887 14.7521 4.33887C15.9506 4.33887 16.9221 3.36762 16.9221 2.16952Z"
          fill="#32CD32"
        />
        <path
          d="M27.3315 66.2554C27.7653 66.2489 28.1911 66.3716 28.555 66.6078C28.9188 66.844 29.2041 67.183 29.3746 67.5818C29.5451 67.9805 29.5931 68.421 29.5124 68.8471C29.4318 69.2732 29.2262 69.6657 28.9217 69.9746C28.6173 70.2836 28.2278 70.495 27.8028 70.582C27.3779 70.669 26.9366 70.6277 26.5352 70.4633C26.1338 70.2988 25.7904 70.0188 25.5487 69.6587C25.3069 69.2986 25.1778 68.8747 25.1778 68.441C25.1756 68.1562 25.2297 67.8736 25.3367 67.6096C25.4438 67.3456 25.6019 67.1052 25.8019 66.9023C26.0018 66.6993 26.2399 66.5377 26.5023 66.4267C26.7648 66.3157 27.0466 66.2575 27.3315 66.2554V66.2554Z"
          fill="#32CD32"
        />
        <path
          d="M27.3316 53.0107C27.766 53.0043 28.1925 53.1274 28.5567 53.3643C28.9209 53.6012 29.2062 53.9412 29.3762 54.3409C29.5462 54.7407 29.5933 55.182 29.5113 55.6085C29.4293 56.0351 29.222 56.4275 28.9159 56.7358C28.6099 57.0441 28.2189 57.2543 27.7928 57.3394C27.3667 57.4246 26.9249 57.3809 26.5238 57.214C26.1227 57.047 25.7804 56.7644 25.5407 56.4021C25.3009 56.0399 25.1746 55.6144 25.1778 55.1801C25.1778 54.6076 25.4042 54.0582 25.8076 53.6519C26.2111 53.2455 26.7589 53.0151 27.3316 53.0107V53.0107Z"
          fill="#32CD32"
        />
        <path
          d="M27.3315 39.75C27.7653 39.7435 28.1911 39.8662 28.555 40.1024C28.9188 40.3386 29.2041 40.6776 29.3746 41.0764C29.5451 41.4752 29.5931 41.9156 29.5124 42.3417C29.4318 42.7678 29.2262 43.1603 28.9217 43.4693C28.6173 43.7782 28.2278 43.9896 27.8028 44.0766C27.3779 44.1637 26.9366 44.1223 26.5352 43.9579C26.1338 43.7935 25.7904 43.5134 25.5487 43.1533C25.3069 42.7932 25.1778 42.3693 25.1778 41.9357C25.1756 41.6508 25.2297 41.3683 25.3367 41.1042C25.4438 40.8402 25.6019 40.5999 25.8019 40.3969C26.0018 40.194 26.2399 40.0324 26.5023 39.9214C26.7648 39.8104 27.0466 39.7521 27.3315 39.75V39.75Z"
          fill="#32CD32"
        />
        <path
          d="M27.3305 26.5705C27.7631 26.5675 28.1868 26.6938 28.5471 26.9332C28.9074 27.1726 29.1879 27.5142 29.3526 27.9141C29.5172 28.314 29.5586 28.754 29.4713 29.1776C29.384 29.6012 29.172 29.989 28.8626 30.2913C28.5531 30.5936 28.1604 30.7965 27.7348 30.874C27.3092 30.9516 26.8701 30.9001 26.4739 30.7264C26.0778 30.5526 25.7426 30.2644 25.5115 29.8988C25.2803 29.5332 25.1638 29.1069 25.1768 28.6746C25.1936 28.1135 25.4275 27.5807 25.8291 27.1883C26.2307 26.7959 26.7689 26.5745 27.3305 26.5705Z"
          fill="#32CD32"
        />
        <path
          d="M27.3315 13.2446C27.7653 13.2382 28.1911 13.3608 28.555 13.597C28.9188 13.8332 29.2041 14.1723 29.3746 14.571C29.5451 14.9698 29.5931 15.4102 29.5124 15.8364C29.4318 16.2625 29.2262 16.6549 28.9217 16.9639C28.6173 17.2728 28.2278 17.4843 27.8028 17.5713C27.3779 17.6583 26.9366 17.617 26.5352 17.4525C26.1338 17.2881 25.7904 17.008 25.5487 16.6479C25.3069 16.2878 25.1778 15.864 25.1778 15.4303C25.1756 15.1454 25.2297 14.8629 25.3367 14.5989C25.4438 14.3349 25.6019 14.0945 25.8019 13.8915C26.0018 13.6886 26.2399 13.527 26.5023 13.416C26.7648 13.305 27.0466 13.2468 27.3315 13.2446Z"
          fill="#32CD32"
        />
        <path
          d="M27.3311 -2.27695e-05C27.7682 -0.00624354 28.1972 0.118707 28.5626 0.35869C28.9281 0.598673 29.2131 0.942667 29.3809 1.34624C29.5488 1.74982 29.5918 2.19444 29.5043 2.62268C29.4169 3.05091 29.203 3.4431 28.8903 3.74859C28.5776 4.05408 28.1805 4.25882 27.7502 4.33639C27.3199 4.41397 26.8762 4.36079 26.4765 4.18375C26.0768 4.0067 25.7393 3.71392 25.5077 3.3432C25.2761 2.97248 25.161 2.54086 25.1773 2.10409C25.1942 1.54294 25.428 1.01017 25.8296 0.617785C26.2313 0.225398 26.7695 0.00394481 27.3311 -2.27695e-05Z"
          fill="#32CD32"
        />
      </svg>
    );
  }

  if (name === 'dots6x2') {
    return (
      <svg
        width="72"
        height="18"
        viewBox="0 0 72 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.95334 2.29352C4.95334 2.72258 4.82606 3.142 4.58761 3.49876C4.34917 3.85551 4.01027 4.13356 3.61375 4.29776C3.21723 4.46195 2.78089 4.50491 2.35995 4.42121C1.939 4.3375 1.55234 4.1309 1.24885 3.82751C0.94537 3.52412 0.73871 3.13757 0.654979 2.71675C0.571248 2.29594 0.61422 1.85973 0.778464 1.46333C0.942709 1.06693 1.22085 0.728134 1.57771 0.489761C1.93457 0.251387 2.35412 0.124145 2.78331 0.124145C3.35884 0.124145 3.91078 0.352718 4.31774 0.759554C4.7247 1.16639 4.95334 1.71817 4.95334 2.29352Z"
          fill="#32CD32"
        />
        <path
          d="M18.2184 2.29367C18.2216 2.72732 18.0957 3.15213 17.8567 3.51406C17.6177 3.87598 17.2764 4.15865 16.8762 4.3261C16.4761 4.49354 16.0351 4.53821 15.6095 4.4544C15.1839 4.37058 14.7928 4.16208 14.4861 3.85543C14.1793 3.54878 13.9708 3.15782 13.8869 2.73233C13.8031 2.30683 13.8478 1.86603 14.0153 1.46599C14.1828 1.06596 14.4655 0.724774 14.8275 0.485855C15.1896 0.246935 15.6145 0.121073 16.0483 0.124297C16.6225 0.128565 17.172 0.358495 17.5781 0.764408C17.9841 1.17032 18.2141 1.71963 18.2184 2.29367Z"
          fill="#32CD32"
        />
        <path
          d="M31.4338 2.29352C31.4338 2.72258 31.3066 3.142 31.0681 3.49876C30.8297 3.85551 30.4907 4.13356 30.0942 4.29776C29.6977 4.46195 29.2614 4.50491 28.8404 4.42121C28.4195 4.3375 28.0328 4.1309 27.7293 3.82751C27.4258 3.52412 27.2192 3.13757 27.1354 2.71675C27.0517 2.29594 27.0947 1.85973 27.2589 1.46333C27.4232 1.06693 27.7013 0.728134 28.0582 0.489761C28.415 0.251387 28.8346 0.124145 29.2638 0.124145C29.8393 0.124145 30.3913 0.352718 30.7982 0.759554C31.2052 1.16639 31.4338 1.71817 31.4338 2.29352Z"
          fill="#32CD32"
        />
        <path
          d="M44.7321 2.29367C44.7353 2.72732 44.6094 3.15213 44.3704 3.51406C44.1314 3.87598 43.7901 4.15865 43.3899 4.3261C42.9898 4.49354 42.5488 4.53821 42.1232 4.4544C41.6976 4.37058 41.3065 4.16208 40.9998 3.85543C40.693 3.54878 40.4844 3.15782 40.4006 2.73233C40.3168 2.30683 40.3614 1.86603 40.5289 1.46599C40.6964 1.06596 40.9792 0.724774 41.3412 0.485855C41.7033 0.246935 42.1282 0.121073 42.562 0.124297C43.1362 0.128565 43.6857 0.358495 44.0918 0.764408C44.4978 1.17032 44.7278 1.71963 44.7321 2.29367V2.29367Z"
          fill="#32CD32"
        />
        <path
          d="M57.9807 2.29352C57.9807 2.72258 57.8534 3.142 57.615 3.49876C57.3765 3.85551 57.0376 4.13356 56.6411 4.29776C56.2446 4.46195 55.8083 4.50491 55.3873 4.42121C54.9664 4.3375 54.5797 4.1309 54.2762 3.82751C53.9727 3.52412 53.7661 3.13757 53.6823 2.71675C53.5986 2.29594 53.6416 1.85973 53.8058 1.46333C53.9701 1.06693 54.2482 0.728134 54.605 0.489761C54.9619 0.251387 55.3815 0.124145 55.8106 0.124145C56.3862 0.124145 56.9381 0.352718 57.3451 0.759554C57.7521 1.16639 57.9807 1.71817 57.9807 2.29352Z"
          fill="#32CD32"
        />
        <path
          d="M71.2459 2.29367C71.2491 2.72732 71.1232 3.15213 70.8842 3.51406C70.6452 3.87598 70.3039 4.15865 69.9037 4.3261C69.5035 4.49354 69.0626 4.53821 68.6369 4.4544C68.2113 4.37058 67.8202 4.16208 67.5135 3.85543C67.2067 3.54878 66.9981 3.15782 66.9143 2.73233C66.8304 2.30683 66.8751 1.86603 67.0426 1.46599C67.2101 1.06596 67.4929 0.724774 67.8549 0.485855C68.217 0.246935 68.6419 0.121073 69.0758 0.124297C69.65 0.128565 70.1995 0.358495 70.6055 0.764408C71.0116 1.17032 71.2416 1.71963 71.2459 2.29367V2.29367Z"
          fill="#32CD32"
        />
        <path
          d="M4.95334 14.8692C4.95334 15.2983 4.82606 15.7177 4.58761 16.0745C4.34917 16.4312 4.01027 16.7093 3.61375 16.8734C3.21723 17.0376 2.78089 17.0806 2.35995 16.9969C1.939 16.9132 1.55234 16.7066 1.24885 16.4032C0.94537 16.0998 0.73871 15.7133 0.654979 15.2924C0.571248 14.8716 0.61422 14.4355 0.778464 14.0391C0.942709 13.6427 1.22085 13.3039 1.57771 13.0655C1.93457 12.8271 2.35412 12.6999 2.78331 12.6999C3.35884 12.6999 3.91078 12.9285 4.31774 13.3353C4.7247 13.7421 4.95334 14.2939 4.95334 14.8692Z"
          fill="#32CD32"
        />
        <path
          d="M18.2184 14.8694C18.2216 15.303 18.0957 15.7278 17.8567 16.0898C17.6177 16.4517 17.2764 16.7344 16.8762 16.9018C16.4761 17.0692 16.0351 17.1139 15.6095 17.0301C15.1839 16.9463 14.7928 16.7378 14.4861 16.4311C14.1793 16.1245 13.9708 15.7335 13.8869 15.308C13.8031 14.8825 13.8478 14.4417 14.0153 14.0417C14.1828 13.6417 14.4655 13.3005 14.8275 13.0616C15.1896 12.8226 15.6145 12.6968 16.0483 12.7C16.6225 12.7043 17.172 12.9342 17.5781 13.3401C17.9841 13.746 18.2141 14.2953 18.2184 14.8694V14.8694Z"
          fill="#32CD32"
        />
        <path
          d="M31.4338 14.8692C31.4338 15.2983 31.3066 15.7177 31.0681 16.0745C30.8297 16.4312 30.4907 16.7093 30.0942 16.8734C29.6977 17.0376 29.2614 17.0806 28.8404 16.9969C28.4195 16.9132 28.0328 16.7066 27.7293 16.4032C27.4258 16.0998 27.2192 15.7133 27.1354 15.2924C27.0517 14.8716 27.0947 14.4355 27.2589 14.0391C27.4232 13.6427 27.7013 13.3039 28.0582 13.0655C28.415 12.8271 28.8346 12.6999 29.2638 12.6999C29.8393 12.6999 30.3913 12.9285 30.7982 13.3353C31.2052 13.7421 31.4338 14.2939 31.4338 14.8692Z"
          fill="#32CD32"
        />
        <path
          d="M44.7321 14.8694C44.7353 15.303 44.6094 15.7278 44.3704 16.0898C44.1314 16.4517 43.7901 16.7344 43.3899 16.9018C42.9898 17.0692 42.5488 17.1139 42.1232 17.0301C41.6976 16.9463 41.3065 16.7378 40.9998 16.4311C40.693 16.1245 40.4844 15.7335 40.4006 15.308C40.3168 14.8825 40.3614 14.4417 40.5289 14.0417C40.6964 13.6417 40.9792 13.3005 41.3412 13.0616C41.7033 12.8226 42.1282 12.6968 42.562 12.7C43.1362 12.7043 43.6857 12.9342 44.0918 13.3401C44.4978 13.746 44.7278 14.2953 44.7321 14.8694V14.8694Z"
          fill="#32CD32"
        />
        <path
          d="M57.9807 14.8692C57.9807 15.2983 57.8534 15.7177 57.615 16.0745C57.3765 16.4312 57.0376 16.7093 56.6411 16.8734C56.2446 17.0376 55.8083 17.0806 55.3873 16.9969C54.9664 16.9132 54.5797 16.7066 54.2762 16.4032C53.9727 16.0998 53.7661 15.7133 53.6823 15.2924C53.5986 14.8716 53.6416 14.4355 53.8058 14.0391C53.9701 13.6427 54.2482 13.3039 54.605 13.0655C54.9619 12.8271 55.3815 12.6999 55.8106 12.6999C56.3862 12.6999 56.9381 12.9285 57.3451 13.3353C57.7521 13.7421 57.9807 14.2939 57.9807 14.8692Z"
          fill="#32CD32"
        />
        <path
          d="M71.2459 14.8694C71.2491 15.303 71.1232 15.7278 70.8842 16.0898C70.6452 16.4517 70.3039 16.7344 69.9037 16.9018C69.5035 17.0692 69.0626 17.1139 68.6369 17.0301C68.2113 16.9463 67.8202 16.7378 67.5135 16.4311C67.2067 16.1245 66.9981 15.7335 66.9143 15.308C66.8304 14.8825 66.8751 14.4417 67.0426 14.0417C67.2101 13.6417 67.4929 13.3005 67.8549 13.0616C68.217 12.8226 68.6419 12.6968 69.0758 12.7C69.65 12.7043 70.1995 12.9342 70.6055 13.3401C71.0116 13.746 71.2416 14.2953 71.2459 14.8694V14.8694Z"
          fill="#32CD32"
        />
      </svg>
    );
  }

  if (name === 'dots6x3') {
    return (
      <svg
        width="71"
        height="30"
        viewBox="0 0 71 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.37127 2.17156C4.3745 2.60465 4.24907 3.02894 4.01088 3.39061C3.77268 3.75229 3.43247 4.03508 3.03339 4.20306C2.63431 4.37105 2.19434 4.41667 1.76929 4.33417C1.34424 4.25166 0.953272 4.0447 0.645967 3.7396C0.338662 3.4345 0.128872 3.04498 0.0432071 2.62043C-0.0424577 2.19589 -0.00013724 1.75547 0.164801 1.35503C0.329738 0.954578 0.609861 0.612155 0.969637 0.371191C1.32941 0.130227 1.75263 0.00159898 2.18561 0.00158691C2.76247 0.00157085 3.31594 0.229642 3.72537 0.636133C4.13479 1.04262 4.36697 1.59455 4.37127 2.17156Z"
          fill="#32CD32"
        />
        <path
          d="M17.6159 2.17156C17.6191 2.60536 17.4933 3.0303 17.2544 3.39234C17.0154 3.75438 16.6743 4.03716 16.2742 4.20466C15.8742 4.37216 15.4334 4.41682 15.0079 4.33298C14.5824 4.24914 14.1914 4.04057 13.8848 3.73382C13.5781 3.42707 13.3697 3.03602 13.2858 2.61039C13.202 2.18476 13.2467 1.74382 13.4141 1.34365C13.5816 0.943483 13.8642 0.602169 14.2262 0.363173C14.5881 0.124177 15.0129 -0.00170007 15.4465 0.00152531C16.0206 0.00579475 16.5699 0.235772 16.9758 0.641815C17.3817 1.04786 17.6116 1.59734 17.6159 2.17156Z"
          fill="#32CD32"
        />
        <path
          d="M30.8766 2.17156C30.8799 2.60465 30.7544 3.02894 30.5162 3.39061C30.2781 3.75229 29.9378 4.03508 29.5388 4.20306C29.1397 4.37105 28.6997 4.41667 28.2747 4.33417C27.8496 4.25166 27.4586 4.0447 27.1513 3.7396C26.844 3.4345 26.6342 3.04498 26.5486 2.62043C26.4629 2.19589 26.5052 1.75547 26.6702 1.35503C26.8351 0.954578 27.1152 0.612155 27.475 0.371191C27.8348 0.130227 28.258 0.00159898 28.691 0.00158691C29.2678 0.00157085 29.8213 0.229642 30.2307 0.636133C30.6402 1.04262 30.8723 1.59455 30.8766 2.17156Z"
          fill="#32CD32"
        />
        <path
          d="M44.0558 2.17156C44.0558 2.60074 43.9285 3.02031 43.6902 3.37717C43.4518 3.73402 43.113 4.01213 42.7166 4.17637C42.3202 4.34061 41.884 4.38358 41.4632 4.29986C41.0424 4.21613 40.6558 4.00947 40.3524 3.70599C40.049 3.40251 39.8424 3.01588 39.7587 2.59494C39.675 2.174 39.718 1.73765 39.8822 1.34113C40.0464 0.944618 40.3244 0.605749 40.6812 0.367307C41.0379 0.128865 41.4573 0.00158691 41.8864 0.00158691C42.4604 0.00585623 43.0097 0.235827 43.4156 0.641858C43.8216 1.04789 44.0515 1.59736 44.0558 2.17156Z"
          fill="#32CD32"
        />
        <path
          d="M57.3818 2.17156C57.385 2.60465 57.2596 3.02894 57.0214 3.39061C56.7832 3.75229 56.443 4.03508 56.0439 4.20306C55.6448 4.37105 55.2048 4.41667 54.7798 4.33417C54.3547 4.25166 53.9638 4.0447 53.6565 3.7396C53.3492 3.4345 53.1394 3.04498 53.0537 2.62043C52.968 2.19589 53.0104 1.75547 53.1753 1.35503C53.3402 0.954578 53.6204 0.612155 53.9801 0.371191C54.3399 0.130227 54.7631 0.00159898 55.1961 0.00158691C55.773 0.00157085 56.3264 0.229642 56.7359 0.636133C57.1453 1.04262 57.3775 1.59455 57.3818 2.17156Z"
          fill="#32CD32"
        />
        <path
          d="M70.6262 2.17153C70.6291 2.60804 70.5014 3.03546 70.2594 3.39872C70.0174 3.76198 69.6723 4.04446 69.2684 4.20979C68.8645 4.37513 68.4204 4.41574 67.9932 4.32641C67.5661 4.23707 67.1755 4.02187 66.8716 3.70853C66.5678 3.39519 66.3647 2.99804 66.2885 2.56823C66.2123 2.13842 66.2664 1.69564 66.4439 1.29688C66.6215 0.898111 66.9143 0.56161 67.2846 0.33075C67.655 0.0998904 68.086 -0.0147567 68.5221 0.00152147C69.0847 0.0225228 69.6175 0.25985 70.0095 0.664111C70.4014 1.06837 70.6224 1.60836 70.6262 2.17153Z"
          fill="#32CD32"
        />
        <path
          d="M2.18571 16.9213C3.38381 16.9213 4.35507 15.9497 4.35507 14.7512C4.35507 13.5528 3.38381 12.5812 2.18571 12.5812C0.987611 12.5812 0.0163574 13.5528 0.0163574 14.7512C0.0163574 15.9497 0.987611 16.9213 2.18571 16.9213Z"
          fill="#32CD32"
        />
        <path
          d="M15.4465 16.9213C16.6446 16.9213 17.6158 15.9497 17.6158 14.7512C17.6158 13.5528 16.6446 12.5812 15.4465 12.5812C14.2484 12.5812 13.2771 13.5528 13.2771 14.7512C13.2771 15.9497 14.2484 16.9213 15.4465 16.9213Z"
          fill="#32CD32"
        />
        <path
          d="M28.691 16.9213C29.8891 16.9213 30.8603 15.9497 30.8603 14.7512C30.8603 13.5528 29.8891 12.5812 28.691 12.5812C27.4929 12.5812 26.5216 13.5528 26.5216 14.7512C26.5216 15.9497 27.4929 16.9213 28.691 16.9213Z"
          fill="#32CD32"
        />
        <path
          d="M41.9516 16.9213C43.1497 16.9213 44.1209 15.9497 44.1209 14.7512C44.1209 13.5528 43.1497 12.5812 41.9516 12.5812C40.7535 12.5812 39.7822 13.5528 39.7822 14.7512C39.7822 15.9497 40.7535 16.9213 41.9516 16.9213Z"
          fill="#32CD32"
        />
        <path
          d="M55.1962 16.9213C56.3943 16.9213 57.3656 15.9497 57.3656 14.7512C57.3656 13.5528 56.3943 12.5812 55.1962 12.5812C53.9981 12.5812 53.0269 13.5528 53.0269 14.7512C53.0269 15.9497 53.9981 16.9213 55.1962 16.9213Z"
          fill="#32CD32"
        />
        <path
          d="M68.4569 16.9213C69.655 16.9213 70.6263 15.9497 70.6263 14.7512C70.6263 13.5528 69.655 12.5812 68.4569 12.5812C67.2588 12.5812 66.2876 13.5528 66.2876 14.7512C66.2876 15.9497 67.2588 16.9213 68.4569 16.9213Z"
          fill="#32CD32"
        />
        <path
          d="M4.37121 27.3309C4.37768 27.7647 4.25502 28.1905 4.01882 28.5543C3.78263 28.9182 3.44358 29.2035 3.04482 29.374C2.64605 29.5445 2.2056 29.5925 1.77949 29.5118C1.35339 29.4312 0.960896 29.2256 0.651953 28.9211C0.343011 28.6167 0.131583 28.2272 0.0445707 27.8022C-0.0424416 27.3772 -0.00110522 26.936 0.163321 26.5346C0.327747 26.1332 0.607829 25.7898 0.967933 25.548C1.32804 25.3063 1.75188 25.1772 2.18555 25.1772C2.47043 25.175 2.75295 25.2291 3.01697 25.3361C3.28099 25.4432 3.52135 25.6012 3.72431 25.8012C3.92727 26.0012 4.08886 26.2393 4.19985 26.5017C4.31085 26.7642 4.36908 27.046 4.37121 27.3309Z"
          fill="#32CD32"
        />
        <path
          d="M17.6157 27.331C17.6222 27.7654 17.4991 28.1919 17.2622 28.5561C17.0253 28.9202 16.6853 29.2056 16.2855 29.3756C15.8858 29.5456 15.4445 29.5926 15.018 29.5107C14.5914 29.4287 14.1989 29.2214 13.8906 28.9153C13.5823 28.6093 13.3722 28.2183 13.2871 27.7922C13.2019 27.3661 13.2456 26.9243 13.4125 26.5232C13.5794 26.1221 13.8621 25.7798 14.2243 25.5401C14.5866 25.3003 15.012 25.174 15.4464 25.1772C16.0189 25.1772 16.5682 25.4036 16.9746 25.807C17.381 26.2105 17.6114 26.7582 17.6157 27.331Z"
          fill="#32CD32"
        />
        <path
          d="M30.8765 27.3309C30.8829 27.7647 30.7603 28.1905 30.5241 28.5543C30.2879 28.9182 29.9488 29.2035 29.5501 29.374C29.1513 29.5445 28.7108 29.5925 28.2847 29.5118C27.8586 29.4312 27.4661 29.2256 27.1572 28.9211C26.8483 28.6167 26.6368 28.2272 26.5498 27.8022C26.4628 27.3772 26.5041 26.936 26.6686 26.5346C26.833 26.1332 27.1131 25.7898 27.4732 25.548C27.8333 25.3063 28.2571 25.1772 28.6908 25.1772C28.9757 25.175 29.2582 25.2291 29.5222 25.3361C29.7862 25.4432 30.0266 25.6012 30.2296 25.8012C30.4325 26.0012 30.5941 26.2393 30.7051 26.5017C30.8161 26.7642 30.8743 27.046 30.8765 27.3309Z"
          fill="#32CD32"
        />
        <path
          d="M44.0558 27.331C44.0589 27.7636 43.9326 28.1873 43.6932 28.5476C43.4538 28.9079 43.1122 29.1884 42.7123 29.3531C42.3123 29.5177 41.8723 29.5591 41.4487 29.4718C41.0251 29.3845 40.6373 29.1725 40.335 28.863C40.0327 28.5536 39.8298 28.1609 39.7523 27.7353C39.6748 27.3097 39.7262 26.8706 39.9 26.4744C40.0737 26.0782 40.3619 25.7431 40.7275 25.512C41.0931 25.2808 41.5194 25.1642 41.9517 25.1772C42.5129 25.1941 43.0456 25.4279 43.438 25.8296C43.8304 26.2312 44.0518 26.7694 44.0558 27.331Z"
          fill="#32CD32"
        />
        <path
          d="M57.3818 27.3309C57.3883 27.7647 57.2656 28.1905 57.0294 28.5543C56.7932 28.9182 56.4542 29.2035 56.0554 29.374C55.6567 29.5445 55.2162 29.5925 54.7901 29.5118C54.364 29.4312 53.9715 29.2256 53.6626 28.9211C53.3536 28.6167 53.1422 28.2272 53.0552 27.8022C52.9682 27.3772 53.0095 26.936 53.1739 26.5346C53.3384 26.1332 53.6185 25.7898 53.9786 25.548C54.3387 25.3063 54.7625 25.1772 55.1962 25.1772C55.4811 25.175 55.7636 25.2291 56.0276 25.3361C56.2916 25.4432 56.532 25.6012 56.7349 25.8012C56.9379 26.0012 57.0995 26.2393 57.2105 26.5017C57.3215 26.7642 57.3797 27.046 57.3818 27.3309Z"
          fill="#32CD32"
        />
        <path
          d="M70.6262 27.3309C70.6325 27.7681 70.5075 28.1971 70.2675 28.5625C70.0275 28.9279 69.6836 29.2129 69.28 29.3808C68.8764 29.5487 68.4318 29.5917 68.0035 29.5042C67.5753 29.4167 67.1831 29.2028 66.8776 28.8902C66.5721 28.5775 66.3674 28.1804 66.2898 27.7501C66.2123 27.3198 66.2654 26.8761 66.4425 26.4764C66.6195 26.0766 66.9123 25.7392 67.283 25.5076C67.6537 25.276 68.0854 25.1609 68.5221 25.1772C69.0833 25.194 69.616 25.4279 70.0084 25.8295C70.4008 26.2312 70.6223 26.7694 70.6262 27.3309Z"
          fill="#32CD32"
        />
      </svg>
    );
  }

  if (name === 'cross') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.79"
          d="M8.71793 0V4.27023H13V8.71793H8.71793V13H4.27025V8.71793H0V4.27023H4.27025V0H8.71793Z"
          fill={color || "#444444"}
        />
      </svg>
    );
  }

  if (name === 'circle') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 10.0031C7.76142 10.0031 10 7.76386 10 5.00157C10 2.23928 7.76142 0 5 0C2.23858 0 0 2.23928 0 5.00157C0 7.76386 2.23858 10.0031 5 10.0031Z"
          fill={color || "#32CD32"}
        />
      </svg>
    );
  }

  if (name === 'about1') {
    return (
      <svg
        width="646"
        height="756"
        viewBox="0 0 646 756"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M136.839 7.24946C136.839 7.24946 197.167 -5.79463 251.166 5.80748C309.009 18.0394 364.085 54.4535 345.76 157.762C345.163 161.077 344.55 164.591 343.805 168.088C298.339 379.445 445.788 152.873 431.787 341.573C431.39 346.876 431.086 352.103 430.876 357.252C424.397 535.576 554.946 607.626 628.861 612.598"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M135.894 7.69697C135.894 7.69697 196.852 -5.24767 249.592 7.39863C306.077 20.9234 359.877 57.6855 342.38 160.613C341.8 163.928 341.27 167.458 340.541 170.955C297.162 381.219 444.927 155.491 430.859 343.611C430.445 348.932 430.114 354.169 429.882 359.357C422.343 537.599 552.776 610.128 629.027 612.913"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M135.016 8.12788C135.016 8.12788 196.621 -4.76704 248.068 8.97317C303.194 23.6913 355.768 60.9175 339.199 163.464C338.652 166.861 338.171 170.325 337.542 173.823C296.069 382.959 444.314 158.11 429.948 345.65C429.534 350.987 429.17 356.258 428.921 361.462C420.636 539.654 550.556 612.615 629.127 613.095"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M134.022 8.57542C134.022 8.57542 196.273 -4.30293 246.444 10.5478C300.228 26.4758 351.443 64.1661 335.769 166.348C335.255 169.745 334.824 173.226 334.112 176.723C294.86 384.733 443.469 160.762 429.087 347.738C428.656 353.075 428.275 358.363 427.993 363.6C418.913 541.726 548.418 615.134 629.309 613.377"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M133.077 9.02296C133.077 9.02296 196.04 -3.83882 244.87 12.139C297.295 29.2438 347.234 67.4147 332.438 169.165C331.941 172.58 331.577 176.06 330.947 179.557C293.749 386.423 443.286 163.364 428.291 349.744C427.844 355.097 427.429 360.401 427.148 365.672C417.306 543.748 546.347 617.604 629.557 613.593"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M132.15 9.47046C132.15 9.47046 195.676 -3.37473 243.313 13.7135C294.362 32.0283 343.043 70.6633 329.108 172.033C328.644 175.464 328.346 178.944 327.749 182.441C292.573 388.147 442.624 166.016 427.364 351.799C426.916 357.169 426.485 362.49 426.171 367.793C415.599 545.803 544.16 620.106 629.674 613.858"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M131.205 9.9014C131.205 9.9014 195.361 -2.91064 241.738 15.2881C291.429 34.7962 338.817 73.9119 325.777 174.867C325.33 178.298 325.098 181.795 324.535 185.292C291.396 389.821 441.944 168.618 426.485 353.821C426.038 359.208 425.557 364.545 425.226 369.882C413.926 547.825 542.005 622.576 629.839 614.09"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M130.277 10.3489C130.277 10.3489 195.063 -2.44656 240.164 16.8792C288.497 37.5642 334.609 77.1771 322.447 177.651C322.033 181.099 321.85 184.596 321.32 188.093C290.187 391.445 441.281 171.171 425.59 355.794C425.143 361.197 424.646 366.567 424.281 371.92C412.252 549.93 539.852 625.062 630.005 614.338"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M129.333 10.7964C129.333 10.7964 194.748 -1.9825 238.59 18.4538C285.548 40.3486 330.533 80.4257 319.117 180.568C318.719 184.016 318.62 187.53 318.106 191.027C288.961 393.235 440.602 173.856 424.712 357.898C424.248 363.318 423.718 368.705 423.337 374.092C410.579 551.919 537.648 627.565 630.171 614.57"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M128.388 11.2605C128.388 11.2605 194.417 -1.50183 237 20.0449C282.582 43.1331 326.308 83.724 315.77 183.436C315.405 186.9 315.355 190.414 314.875 193.911C287.735 394.843 439.89 176.491 423.817 359.954C423.354 365.374 422.807 370.777 422.393 376.197C408.922 553.958 535.527 630.051 630.336 614.869"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M127.461 11.6749C127.461 11.6749 194.119 -1.05432 235.442 21.6195C279.665 45.8845 322.248 86.9726 312.456 186.27C312.125 189.734 312.125 193.264 311.677 196.762C286.492 396.484 439.26 179.11 422.939 361.992C422.459 367.429 421.879 372.849 421.448 378.302C407.265 555.996 533.373 632.537 630.436 615.084"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M126.533 12.1223C126.533 12.1223 193.804 -0.606837 233.868 23.1941C276.716 48.6358 318.007 90.2543 309.125 189.121C308.811 192.602 308.877 196.132 308.479 199.629C285.282 398.125 438.581 181.729 422.045 364.031C421.564 369.484 420.968 374.92 420.504 380.407C405.591 558.035 531.219 635.04 630.651 615.333"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M125.588 12.5698C125.588 12.5698 193.522 -0.159341 232.294 24.7686C273.717 51.4038 313.864 93.5194 305.795 191.972C305.513 195.469 305.646 198.999 305.265 202.496C283.957 399.732 437.818 184.347 421.249 366.07C420.752 371.539 420.122 377.009 419.592 382.511C404.001 560.073 529.098 637.526 630.85 615.581"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M124.66 13.0173C124.66 13.0173 193.158 0.288173 230.703 26.2769C270.884 54.1551 309.722 96.8012 302.465 194.822C302.216 198.32 302.465 201.867 302.05 205.364C282.664 401.373 437.222 186.966 420.288 368.108C419.775 373.594 419.129 379.081 418.631 384.683C402.344 562.178 526.928 640.078 630.999 615.896"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M123.716 13.4483C123.716 13.4483 192.826 0.735724 229.146 27.9344C267.851 56.9065 305.679 100.1 299.134 197.673C298.902 201.187 299.134 204.734 298.836 208.231C281.372 402.964 436.576 189.601 419.393 370.163C418.88 375.65 418.2 381.152 417.736 386.738C400.77 564.085 524.823 642.515 631.214 616.095"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M122.788 13.8958C122.788 13.8958 192.495 1.1832 227.572 29.5089C264.886 59.6578 301.47 103.381 295.737 200.524C295.539 204.054 295.837 207.585 295.555 211.099C279.98 404.555 435.78 192.22 418.449 372.202C417.919 377.705 417.223 383.224 416.66 388.777C398.997 566.123 522.537 644.968 631.247 616.294"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M121.844 14.3433C121.844 14.3433 192.18 1.61415 226.014 31.1498C261.936 62.3926 297.394 106.68 292.424 203.375C292.241 206.905 292.606 210.452 292.424 213.966C278.721 406.23 435.184 194.839 417.637 374.241C417.107 379.76 416.378 385.296 415.798 390.915C397.456 568.261 520.466 647.487 631.496 616.576"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M120.899 14.7908C120.899 14.7908 191.849 2.04507 224.49 32.6746C259.02 65.144 293.285 109.978 289.11 206.226C288.961 209.773 289.392 213.32 289.11 216.833C277.263 407.705 434.389 197.458 416.693 376.279C416.146 381.815 415.4 387.384 414.787 393.02C395.766 570.283 518.229 649.924 631.579 616.775"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M119.971 15.2217C119.971 15.2217 191.517 2.476 222.833 34.2658C255.971 67.8788 289.11 113.276 285.796 209.076C285.68 212.64 286.161 216.187 285.978 219.701C276.037 409.279 433.776 200.077 415.831 378.318C415.284 383.871 414.506 389.456 413.876 395.125C394.192 572.322 516.108 652.459 631.794 617.073"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M119.027 15.6693C119.027 15.6693 191.186 2.97325 221.292 35.8404C253.055 70.597 285.05 116.575 282.482 211.927C282.383 215.491 282.929 219.054 282.78 222.568C274.579 410.821 433.096 202.679 414.97 380.357C414.406 385.926 413.611 391.528 412.948 397.23C392.585 574.344 514.02 654.962 631.96 617.322"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M118.099 16.1168C118.099 16.1168 190.854 3.3213 219.718 37.4149C250.106 73.3318 281.024 119.889 279.152 214.762C279.152 218.342 279.682 221.889 279.566 225.419C273.17 412.346 432.4 205.314 414.075 382.395C413.511 387.964 412.699 393.583 412.003 399.318C390.977 576.449 511.816 657.448 632.092 617.57"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M117.155 16.5643C117.155 16.5643 190.54 3.73569 218.144 39.0061C247.14 76.05 276.882 123.188 275.854 217.612C275.854 221.226 276.467 224.772 276.385 228.303C271.762 413.937 431.737 207.949 413.23 384.451C412.65 390.036 411.805 395.672 411.109 401.44C389.403 578.421 509.696 660.001 632.324 617.819"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M116.227 16.9953C116.227 16.9953 190.208 4.13349 216.57 40.5807C244.191 78.7019 272.806 126.503 272.491 220.463C272.491 224.093 273.203 227.64 273.137 231.187C270.287 415.412 431.009 210.568 412.319 386.489C411.722 392.092 410.861 397.743 410.131 403.545C387.763 580.46 507.509 662.437 632.457 618.084"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M115.282 17.4427C115.282 17.4427 189.844 4.54782 214.996 42.1552C241.241 81.4698 268.746 129.817 269.227 223.314C269.227 226.944 270.022 230.507 269.989 234.054C268.879 416.937 430.379 213.187 411.49 388.528C410.894 394.147 409.999 399.832 409.253 405.649C386.239 582.499 505.355 664.923 632.689 618.333"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M114.354 17.8903C114.354 17.8903 189.562 4.94564 213.422 43.7464C238.292 84.1881 264.687 133.132 265.913 226.165C265.913 229.811 266.791 233.358 266.808 236.922C267.404 418.428 429.699 215.806 410.628 390.567C410.015 396.202 409.104 401.904 408.325 407.754C384.648 584.537 503.283 667.41 632.855 618.581"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M113.476 18.3378C113.476 18.3378 189.314 5.34344 211.914 45.321C235.426 86.8731 260.694 136.48 262.566 229.016C262.649 232.679 263.527 236.225 263.577 239.789C265.88 419.937 428.987 218.441 409.717 392.622C409.104 398.257 408.176 403.975 407.364 409.859C382.975 586.576 501.03 669.896 632.938 618.83"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M112.482 18.7687C112.482 18.7687 188.949 5.74119 210.241 46.9453C232.377 89.6245 256.552 139.762 259.136 231.916C259.252 235.579 260.18 239.143 260.263 242.706C264.256 421.478 428.175 221.159 408.74 394.71C408.11 400.362 407.083 406.097 406.321 412.014C381.367 588.665 498.843 672.448 633.07 619.195"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M111.538 19.2162C111.538 19.2162 188.634 6.13897 208.716 48.4867C229.461 92.2764 252.558 143.077 255.839 234.717C255.971 238.397 256.966 241.944 257.082 245.524C262.748 422.87 427.512 223.679 407.861 396.699C407.232 402.368 406.204 408.119 405.393 414.069C379.81 590.653 496.722 674.885 633.269 619.327"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M110.593 19.6638C110.593 19.6638 188.336 6.5368 207.142 50.0613C226.545 94.978 248.565 146.409 252.509 237.568C252.674 241.264 253.718 244.811 253.867 248.391C261.174 424.379 426.8 226.297 407 398.738C406.337 404.423 405.343 410.191 404.465 416.174C378.236 592.692 494.568 677.371 633.435 619.543"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M109.665 20.1112C109.665 20.1112 188.021 6.85166 205.568 51.6027C223.612 97.6465 244.505 149.69 249.178 240.386C249.377 244.098 250.47 247.645 250.653 251.225C259.583 425.821 426.087 228.883 406.105 400.743C405.442 406.445 404.448 412.246 403.52 418.246C376.661 594.681 492.414 679.874 633.6 619.825"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M108.721 20.5422C108.721 20.5422 187.723 7.2826 203.994 53.227C220.695 100.365 240.446 153.055 245.848 243.27C246.08 246.982 247.223 250.529 247.504 254.126C257.976 427.279 425.375 231.535 405.227 402.815C404.547 408.533 403.57 414.417 402.576 420.384C375.087 596.769 490.26 682.36 633.749 620.073"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M107.793 20.9897C107.793 20.9897 187.441 7.73015 202.42 54.8016C217.763 103.066 236.486 156.386 242.517 246.12C242.782 249.85 243.975 253.397 244.174 256.993C256.286 428.771 424.613 234.17 404.282 404.87C403.586 410.589 402.526 416.473 401.565 422.473C373.397 598.825 488.04 684.846 633.849 620.322"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M106.849 21.4372C106.849 21.4372 187.143 8.17768 200.846 56.3762C214.847 105.751 232.493 159.701 239.187 248.971C239.469 252.717 240.728 256.264 241.026 259.861C254.696 430.229 423.967 236.789 403.404 406.909C402.708 412.644 401.631 418.511 400.637 424.578C371.956 600.863 485.952 687.332 634.081 620.57"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M105.921 21.8848C105.921 21.8848 186.861 8.47602 199.272 57.9673C211.93 108.453 228.5 163.033 235.856 251.888C236.171 255.651 237.513 259.181 237.812 262.794C253.022 431.738 423.237 239.474 402.576 409.014C401.863 414.765 400.77 420.616 399.742 426.749C370.382 602.968 483.781 689.835 634.246 620.819"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M104.976 22.3156C104.976 22.3156 186.58 8.87376 197.764 59.5418C209.097 111.138 224.589 166.364 232.559 254.673C232.907 258.452 234.216 261.982 234.63 265.595C251.365 433.13 422.559 242.026 401.714 410.986C401.002 416.754 399.875 422.588 398.831 428.787C368.858 604.941 481.677 692.321 634.446 621.051"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M104.048 22.7631C104.048 22.7631 186.381 9.25497 196.123 61.1164C206.065 113.84 220.546 169.696 229.262 257.524C229.643 261.303 231.051 264.849 231.449 268.463C249.675 434.572 421.879 244.645 400.869 413.025C400.14 418.809 398.997 424.727 397.92 430.892C367.267 606.979 479.474 694.807 634.578 621.316"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M103.104 23.2106C103.104 23.2106 186.016 9.603 194.549 62.6412C203.215 116.458 216.57 172.961 225.865 260.308C226.279 264.104 227.737 267.634 228.168 271.264C247.886 435.931 421.1 247.198 399.908 415.014C399.179 420.798 398.019 426.732 396.909 432.931C365.726 608.952 477.32 697.227 634.727 621.498"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M102.176 23.6582C102.176 23.6582 185.751 10.0506 193.008 64.2987C200.349 119.243 212.643 176.375 222.568 263.192C223.015 267.004 224.523 270.535 225.004 274.164C246.162 437.406 420.404 249.85 399.063 417.086C398.318 422.887 397.125 428.837 395.998 435.069C364.202 611.023 475.199 699.763 634.926 621.78"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M101.231 24.0891C101.231 24.0891 185.469 10.4318 191.351 65.9562C197.349 122.011 208.583 179.773 219.138 266.159C219.618 269.988 221.176 273.501 221.69 277.148C244.373 438.881 419.658 252.502 398.135 419.158C397.389 424.975 396.18 430.942 395.02 437.207C362.627 613.112 473.028 702.282 635.058 622.062"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M100.303 24.52C100.303 24.52 185.204 10.813 189.777 67.4313C194.45 124.613 204.69 182.988 215.807 268.91C216.321 272.739 217.928 276.269 218.475 279.916C242.5 440.29 418.863 255.054 397.191 421.18C396.428 427.014 395.202 432.997 394.026 439.296C361.02 615.134 470.791 704.752 635.158 622.294"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M99.3594 24.9841C99.3594 24.9841 184.939 11.2605 188.27 69.0224C191.584 127.331 200.763 186.336 212.56 271.778C213.09 275.623 214.747 279.153 215.327 282.8C240.744 441.732 418.201 257.739 396.379 423.235C395.6 429.086 394.341 435.102 393.065 441.467C359.463 617.156 468.621 707.321 635.307 622.609"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M98.4148 25.4316C98.4148 25.4316 184.724 11.6251 186.696 70.6136C188.734 130.033 196.819 189.668 209.23 274.628C209.793 278.49 211.5 282.004 212.113 285.667C238.905 443.124 417.571 260.358 395.484 425.274C394.705 431.141 393.43 437.174 392.17 443.505C357.971 619.195 466.5 709.741 635.522 622.791"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M97.4868 25.8626C97.4868 25.8626 184.409 12.0229 185.121 72.1882C185.834 132.735 192.892 192.999 205.899 277.479C206.496 281.358 208.235 284.871 208.898 288.534C237.066 444.599 416.759 262.977 394.606 427.329C393.811 433.196 392.518 439.246 391.292 445.561C356.497 621.25 464.412 712.177 635.738 622.99"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M96.5422 26.3101C96.5422 26.3101 184.144 12.4207 183.547 73.7627C182.951 135.436 188.965 196.414 202.569 280.33C203.198 284.225 204.988 287.722 205.684 291.402C235.177 446.025 416.113 265.595 393.711 429.367C392.916 435.251 391.607 441.318 390.397 447.732C355.022 623.322 462.308 714.73 635.953 623.305"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M95.6147 26.7576C95.6147 26.7576 183.879 12.9179 181.973 75.3539C180.051 138.154 185.055 199.662 199.239 283.181C199.901 287.076 201.741 290.59 202.552 294.269C233.288 447.467 415.301 268.164 392.833 431.406C392.021 437.307 390.679 443.389 389.37 449.837C353.415 625.377 460.071 717.232 636.036 623.57"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M94.6702 27.2051C94.6702 27.2051 183.63 13.2328 180.399 76.9284C177.168 140.624 181.145 202.994 195.908 286.032C196.604 289.943 198.493 293.44 199.222 297.137C231.316 448.892 414.522 270.849 391.889 433.445C391.077 439.362 389.718 445.478 388.376 451.942C351.841 627.416 457.867 719.719 636.152 623.819"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7422 27.636C93.7422 27.636 183.365 13.6472 178.825 78.503C174.285 143.359 177.168 206.309 192.577 288.882C193.307 292.811 195.229 296.308 196.057 300.004C229.444 450.334 413.826 273.485 391.06 435.483C390.232 441.417 388.856 447.55 387.481 454.047C350.416 629.487 455.763 722.205 636.367 624.068"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M92.7979 28.0835C92.7979 28.0835 183.1 14.045 177.301 80.0941C171.502 146.143 173.391 209.64 189.297 291.733C190.059 295.678 192.031 299.175 192.893 302.871C227.539 451.759 413.147 276.07 390.215 437.539C389.387 443.472 387.995 449.621 386.587 456.152C348.892 631.509 453.659 724.658 636.583 624.316"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M91.9363 28.5311C91.9363 28.5311 182.918 14.4594 175.743 81.6687C168.569 148.878 169.513 212.971 185.983 294.584C186.778 298.529 188.8 302.026 189.695 305.739C225.584 453.251 412.435 278.689 389.354 439.577C388.492 445.527 387.084 451.693 385.659 458.24C347.318 633.565 451.488 727.276 636.682 624.515"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M90.9255 28.9786C90.9255 28.9786 182.586 14.8737 174.12 83.2433C165.653 151.613 165.553 216.286 182.586 297.435C183.415 301.396 185.469 304.893 186.414 308.606C223.529 454.644 411.623 281.308 388.409 441.616C387.531 447.583 386.106 453.765 384.648 460.345C345.81 635.603 449.268 729.68 636.848 624.813"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M89.9976 29.4924C89.9976 29.4924 182.338 15.2715 172.545 84.8344C162.753 154.397 161.676 219.618 179.256 300.302C180.118 304.297 182.222 307.777 183.216 311.507C221.524 456.119 410.894 283.96 387.514 443.688C386.636 449.671 385.195 455.887 383.703 462.483C344.285 637.658 447.13 732.249 637.063 625.062"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M89.053 29.857C89.053 29.857 182.073 15.6859 170.971 86.409C159.87 157.132 157.799 222.933 175.942 303.12C176.837 307.131 178.991 310.612 180.018 314.341C219.502 457.544 410.164 286.545 386.653 445.693C385.758 451.693 384.3 457.925 382.775 464.555C342.794 639.714 444.993 734.718 637.196 625.327"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M88.1252 30.3045C88.1252 30.3045 181.824 16.1002 169.397 87.9836C156.97 159.867 153.938 226.264 172.595 305.971C173.523 309.982 175.71 313.479 176.787 317.208C217.448 459.003 409.402 289.164 385.741 447.749C384.847 453.749 383.355 459.997 381.814 466.66C341.27 641.752 442.822 737.221 637.345 625.559"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M87.1809 30.7521C87.1809 30.7521 181.625 16.5146 167.823 89.5748C154.021 162.635 150.061 229.579 169.265 308.822C170.226 312.849 172.463 316.33 173.573 320.076C215.393 460.461 408.673 291.783 384.863 449.787C383.952 455.804 382.444 462.069 380.87 468.765C339.762 643.808 440.669 739.641 637.511 625.808"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M86.2363 31.1498C86.2363 31.1498 181.31 16.8792 166.249 91.0996C151.188 165.32 146.2 232.844 165.934 311.623C166.945 315.667 169.248 319.147 170.358 322.893C213.322 461.87 407.928 294.352 383.968 451.776C383.057 457.809 381.533 464.091 379.926 470.82C338.271 645.863 438.514 742.193 637.66 626.056"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M85.3086 31.6305C85.3086 31.6305 181.062 17.3433 164.675 92.7239C148.288 168.105 142.356 236.209 162.604 314.573C163.648 318.634 165.918 322.114 167.144 325.86C211.218 463.428 407.182 297.07 383.09 453.914C382.162 459.947 380.622 466.279 378.981 473.025C336.763 647.968 436.36 744.68 637.825 626.355"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M84.364 32.0779C84.364 32.0779 180.797 17.7742 163.184 94.315C145.571 170.856 138.578 239.524 159.356 317.374C160.433 321.435 162.77 324.915 164.012 328.678C209.196 464.87 406.519 299.639 382.278 455.903C381.351 461.953 379.793 468.301 378.12 475.08C335.272 649.973 434.206 747.166 637.991 626.554"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M83.4363 32.5255C83.4363 32.5255 180.548 18.1886 161.527 95.8896C142.506 173.591 134.652 242.822 155.943 320.225C157.053 324.302 159.439 327.783 160.732 331.545C206.993 466.362 405.691 302.258 381.318 457.942C380.373 464.008 378.782 470.373 377.109 477.185C333.847 652.029 432.052 749.602 638.157 626.802"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M82.4917 32.973C82.4917 32.973 180.25 18.6029 159.953 97.4807C139.655 176.359 130.824 246.137 152.613 323.076C153.772 327.169 156.175 330.65 157.583 334.412C204.922 467.837 404.995 304.877 380.489 459.997C379.545 466.063 377.937 472.444 376.231 479.29C332.273 654.084 429.948 752.138 638.322 627.051"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M81.5637 33.4039C81.5637 33.4039 180.035 19.0339 158.379 99.105C136.723 179.176 126.897 249.485 149.282 325.976C150.475 330.086 152.911 333.551 154.253 337.33C202.651 469.395 404.133 307.496 379.495 462.085C378.517 468.168 376.91 474.582 375.17 481.444C330.731 656.189 427.678 754.641 638.438 627.299"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M80.6196 33.8514C80.6196 33.8514 179.786 19.4482 156.838 100.63C133.89 181.812 123.169 252.75 145.969 328.777C147.195 332.888 149.68 336.368 151.105 340.147C200.548 470.853 403.437 310.098 378.667 464.074C377.672 470.174 376.049 476.605 374.276 483.5C329.29 658.194 425.574 757.111 638.72 627.548"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M79.6917 34.2989C79.6917 34.2989 179.521 19.8792 155.247 102.254C130.973 184.629 119.325 256.082 142.622 331.661C143.881 335.788 146.399 339.269 147.874 343.048C198.36 472.328 402.692 312.75 377.772 466.146C376.778 472.262 375.137 478.71 373.331 485.638C327.799 660.249 423.42 759.564 638.803 627.83"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M78.6809 34.7464C78.6809 34.7464 179.206 20.2935 153.607 103.796C128.007 187.298 115.498 259.347 139.225 334.479C140.534 338.622 143.069 342.086 144.593 345.882C196.107 473.886 401.863 315.335 376.827 468.152C375.817 474.284 374.16 480.748 372.32 487.709C326.308 662.305 421.266 762.083 638.969 628.045"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M77.8193 35.1774C77.8193 35.1774 179.007 20.7245 152.099 105.37C125.191 190.016 111.687 262.645 135.961 337.412C137.303 341.556 139.888 345.037 141.462 348.832C193.969 475.428 401.184 317.888 375.999 470.207C374.988 476.339 373.298 482.82 371.442 489.814C324.833 664.377 419.112 764.552 639.134 628.294"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M76.8748 35.625C76.8748 35.625 178.759 21.1389 150.525 106.895C122.291 192.651 107.859 265.877 132.63 340.114C134.022 344.274 136.623 347.755 138.247 351.55C191.749 476.903 400.422 320.49 375.187 472.179C374.159 478.328 372.453 484.842 370.564 491.853C323.408 666.366 416.958 766.989 639.366 628.476"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M75.947 36.1222C75.947 36.1222 178.494 21.6195 148.951 108.586C119.408 195.552 104.048 269.358 129.3 343.081C130.725 347.258 133.359 350.738 135.033 354.534C189.529 478.56 399.66 323.225 374.226 474.334C373.199 480.5 371.475 487.03 369.553 494.058C321.867 668.537 414.804 769.591 639.466 628.841"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M75.0024 36.52C75.0024 36.52 178.245 21.9842 147.377 110.111C116.508 198.237 100.237 272.54 125.969 345.882C127.427 350.059 130.095 353.556 131.818 357.352C187.292 480.085 398.914 325.86 373.348 476.323C372.112 482.994 370.542 489.599 368.642 496.113C320.426 670.559 412.666 772.028 639.665 629.04"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M74.0581 36.9509C74.0581 36.9509 177.98 22.4151 145.803 111.702C113.625 200.988 96.4431 275.789 122.606 348.716C124.114 352.91 126.798 356.39 128.571 360.202C185.006 481.643 398.119 328.396 372.42 478.345C371.163 485.052 369.565 491.69 367.631 498.234C318.935 672.581 410.479 774.514 639.781 629.305"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M73.1301 37.3984C73.1301 37.3984 177.732 22.8626 144.229 113.276C110.726 203.69 92.6321 279.021 119.375 351.584C120.916 355.777 123.633 359.274 125.456 363.086C182.851 483.234 397.456 331.015 371.641 480.4C370.366 487.109 368.746 493.748 366.786 500.289C317.427 674.686 408.408 777 639.946 629.487"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M72.1855 37.7796C72.1855 37.7796 177.466 23.2107 142.654 114.784C107.842 206.358 88.8376 282.302 115.978 354.368C117.635 358.578 120.303 362.075 122.175 365.887C180.532 484.759 396.627 333.567 370.713 482.389C369.403 489.128 367.744 495.795 365.742 502.361C315.935 676.675 406.138 779.436 640.079 629.736"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M71.2578 38.2934C71.2578 38.2934 177.301 23.6913 141.08 116.442C104.86 209.193 85.0434 285.634 112.648 357.285C114.305 361.512 117.055 365.009 118.977 368.887C178.245 486.566 395.865 336.319 369.802 484.56C368.497 491.322 366.838 498.011 364.831 504.599C314.494 678.813 404.017 781.972 640.278 630.051"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M70.3962 38.7243C70.3962 38.7243 177.036 24.1222 139.606 118.016C102.176 211.911 81.3319 288.916 109.4 360.136C111.057 364.362 113.874 367.876 115.846 371.738C176.058 488.107 395.186 338.904 369.006 486.582C367.705 493.367 366.046 500.078 364.036 506.687C313.019 680.868 401.863 784.475 640.377 630.299"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M69.3855 39.1719C69.3855 39.1719 176.704 24.5201 137.949 119.591C99.1935 214.662 77.4713 292.181 105.987 362.987C107.644 367.23 110.527 370.744 112.615 374.589C173.755 489.715 394.407 341.44 368.095 488.605C366.77 495.416 365.072 502.15 363.008 508.776C311.644 682.973 399.775 786.994 640.675 630.581"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M68.4412 39.6194C68.4412 39.6194 176.439 24.9841 136.375 121.182C96.3106 217.38 73.7102 295.446 102.657 365.838C104.413 370.081 107.263 373.611 109.284 377.44C171.468 491.323 393.496 344.092 367.151 490.61C365.806 497.444 364.091 504.2 362.014 510.848C310.103 685.012 397.572 789.464 640.775 630.797"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M67.5132 40.0669C67.5132 40.0669 176.191 25.4151 134.801 122.757C93.4108 220.099 69.8991 298.711 99.326 368.688C101.116 372.948 103.982 376.478 106.119 380.291C169.082 492.997 392.767 346.694 366.256 492.665C364.898 499.517 363.167 506.29 361.07 512.952C308.612 687.084 395.385 791.917 640.924 631.045"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M66.5688 40.4978C66.5688 40.4978 175.926 25.846 133.227 124.331C90.5279 222.817 66.1215 301.976 95.9958 371.539C97.8349 375.799 100.718 379.346 102.905 383.141C166.779 494.654 392.038 349.379 365.328 494.704C363.971 501.601 362.234 508.418 360.125 515.124C307.104 689.155 393.264 794.503 641.089 631.36"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M65.6409 40.9453C65.6409 40.9453 175.66 26.2769 131.653 125.922C87.6448 225.568 62.3436 305.225 92.6652 374.39C94.5376 378.666 97.4537 382.213 99.7072 385.992C164.476 496.295 391.325 351.882 364.483 496.709C363.105 503.611 361.335 510.429 359.181 517.129C305.696 691.161 391.077 796.906 641.255 631.493"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M64.6963 41.3928C64.6963 41.3928 175.412 26.7078 130.078 127.58C84.745 228.452 58.5657 308.556 89.3347 377.324C91.2567 381.6 94.1895 385.163 96.4926 388.926C162.156 498.052 390.497 354.567 363.605 498.814C362.209 505.74 360.417 512.58 358.236 519.3C304.237 693.332 388.923 799.409 641.421 631.824"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M63.7686 41.8403C63.7686 41.8403 175.147 27.1222 128.504 129.088C81.8621 231.054 54.7881 311.722 86.0045 380.092C87.9596 384.384 90.9752 387.948 93.2783 391.694C159.837 499.66 389.718 357.07 362.727 500.754C361.313 507.703 359.498 514.566 357.292 521.306C302.779 695.338 386.769 801.845 641.586 631.974"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M62.8242 42.2713C62.8242 42.2713 174.898 27.5532 126.93 130.596C78.9626 233.64 51.0104 314.904 82.6741 382.876C84.679 387.169 87.6449 390.766 90.064 394.561C157.517 501.383 388.956 359.755 361.799 502.792C360.384 509.765 358.564 516.65 356.348 523.411C301.321 697.443 384.615 804.331 641.736 632.222"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M61.8796 42.7519C61.8796 42.7519 174.633 28.0172 125.356 132.254C76.0795 236.491 47.1993 318.219 79.3436 385.843C81.5813 389.912 84.0889 393.827 86.8495 397.561C155.181 503.256 388.177 362.44 360.954 504.98C359.502 511.95 357.643 518.829 355.387 525.582C299.863 699.514 382.461 806.834 641.901 632.537"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M60.9517 43.1663C60.9517 43.1663 174.368 28.415 123.782 133.828C73.1963 239.242 43.4712 321.451 76.0131 388.627C78.278 392.711 80.8248 396.633 83.6349 400.362C152.861 504.93 387.398 364.976 360.059 506.952C358.611 513.97 356.741 520.895 354.459 527.687C298.405 701.586 380.24 809.386 642.034 632.802"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M60.0072 43.6138C60.0072 43.6138 174.119 28.8459 122.225 135.403C70.3298 241.96 39.7099 324.683 72.6826 391.478C74.9867 395.575 77.5721 399.507 80.4204 403.246C150.541 506.704 386.636 367.578 359.181 508.991C357.709 516.026 355.817 522.967 353.514 529.775C296.947 703.675 378.136 811.873 642.233 633.034"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M59.0795 44.0447C59.0795 44.0447 173.854 29.2769 120.651 136.978C67.4469 244.678 35.9323 327.915 69.2861 394.329C71.6253 398.44 74.2554 402.378 77.1565 406.114C148.272 508.494 385.857 370.197 358.286 511.03C356.81 518.091 354.901 525.054 352.57 531.88C295.489 705.73 375.982 814.375 642.398 633.283"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M58.135 44.4922C58.135 44.4922 173.589 29.7078 119.077 138.569C64.5638 247.43 32.171 331.147 66.055 397.13C68.4277 401.252 71.0968 405.196 74.0414 408.931C145.902 510.218 385.112 372.749 357.375 513.019C355.875 520.102 353.944 527.088 351.592 533.936C294.081 707.835 373.828 816.861 642.564 633.531"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M57.1408 44.9397C57.1408 44.9397 173.274 30.1388 117.436 140.143C61.598 250.148 28.3435 334.363 62.6252 400.031C65.0274 404.167 67.7358 408.117 70.7276 411.848C143.483 512.057 384.234 375.384 356.447 515.124C354.941 522.227 352.993 529.229 350.615 536.09C292.523 709.906 371.608 819.347 642.647 633.78"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M56.2626 45.3873C56.2626 45.3873 173.291 30.6857 115.912 141.718C59.0463 252.767 24.632 337.578 59.3445 402.882C61.8033 407.03 64.5728 410.986 67.6291 414.716C141.263 513.881 383.571 378.02 355.685 517.162C354.16 524.29 352.19 531.315 349.786 538.195C291.197 711.995 369.57 821.834 642.928 634.029"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M55.3348 45.8182C55.3348 45.8182 172.976 31.1167 114.354 143.309C56.1633 255.568 20.8874 340.794 56.0307 405.732C58.4877 409.887 61.2572 413.849 64.3153 417.583C138.877 515.704 382.693 380.622 354.707 519.201C353.167 526.353 351.174 533.4 348.742 540.3C289.706 714.017 367.366 824.337 643.045 634.277"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M54.3904 46.2658C54.3904 46.2658 172.744 31.5311 112.78 144.884C53.2471 258.303 17.1262 344.009 52.7003 408.583C55.1973 412.757 58.0231 416.726 61.1506 420.45C136.557 517.56 381.947 383.241 353.862 521.24C352.317 528.417 350.308 535.487 347.848 542.405C288.199 716.155 365.212 826.823 643.211 634.526"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M53.4627 46.7132C53.4627 46.7132 172.463 31.9619 111.206 146.458C50.3477 261.037 13.3652 347.208 49.3701 411.434C51.9066 415.62 54.7769 419.594 57.9529 423.318C134.171 519.45 381.169 385.843 352.984 523.278C351.413 530.478 349.382 537.57 346.903 544.51C286.807 718.244 363.058 829.309 643.376 634.791"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M52.518 47.1607C52.518 47.1607 172.181 32.3929 109.632 148.049C47.4479 263.756 9.60379 350.423 46.0395 414.285C48.6059 418.485 51.5155 422.465 54.7383 426.185C131.918 521.289 380.39 388.445 352.089 525.317C350.516 532.543 348.468 539.658 345.959 546.615C285.365 720.315 360.871 831.812 643.542 635.04"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M51.5902 47.5916C51.5902 47.5916 171.899 32.8072 108.058 149.624C44.5317 266.507 5.77635 353.622 42.7091 417.135C45.3111 421.346 48.2596 425.332 51.5239 429.053C129.598 523.179 379.594 391.064 351.211 527.372C349.617 534.617 347.547 541.748 345.014 548.72C283.924 722.404 358.701 834.298 643.691 635.288"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M50.6458 48.0392C50.6458 48.0392 171.601 33.2382 106.484 151.199C41.6322 269.225 2.09808 356.722 39.3788 419.986C42.0108 424.211 44.9986 428.204 48.3096 431.92C127.295 525.085 378.815 393.666 350.316 529.411C348.714 536.678 346.633 543.832 344.086 550.825C282.482 724.492 356.58 836.784 643.856 635.537"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M49.7013 48.4867C49.7013 48.4867 171.352 33.6526 104.926 152.79C38.5005 271.927 -1.66327 359.97 36.0483 422.837C38.7103 427.076 41.7375 431.075 45.0951 434.787C124.975 526.991 378.037 396.268 349.438 531.45C347.812 538.762 345.709 545.959 343.142 552.996C281.041 726.647 354.426 839.336 644.022 635.869"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M48.8564 48.9343C48.8564 48.9343 171.154 34.0173 103.435 154.364C35.717 274.711 -5.3415 363.202 32.8008 425.688C35.4984 429.938 38.5645 433.942 41.9636 437.655C122.755 528.93 377.324 398.87 348.627 533.488C346.995 540.804 344.875 548.003 342.281 555.035C279.682 728.669 352.355 841.773 644.271 636.034"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M47.8292 49.3817C47.8292 49.3817 170.789 34.4647 101.779 155.955C32.7678 277.446 -9.23519 366.451 29.3876 428.555C32.1156 432.82 35.221 436.83 38.6664 440.539C120.369 530.886 376.231 401.439 347.666 535.543C346.006 542.859 343.863 550.057 341.253 557.09C278.158 730.757 350.118 844.259 644.354 636.283"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M46.901 49.8127C46.901 49.8127 170.524 34.8956 100.204 157.547C29.8844 280.197 -12.947 369.55 26.0569 431.389C28.8206 435.669 31.9709 439.686 35.4682 443.389C118.082 532.891 375.502 404.042 346.787 537.582C345.119 544.941 342.954 552.178 340.309 559.245C277.014 732.961 347.964 846.745 644.52 636.531"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M45.9569 50.2601C45.9569 50.2601 170.226 35.3431 98.6305 159.104C27.0349 282.866 -16.6912 372.733 22.7269 434.24C25.521 438.534 28.7106 442.557 32.2542 446.257C115.796 534.797 374.756 406.66 345.893 539.621C344.22 547.005 342.039 554.266 339.365 561.35C275.49 735.017 345.81 849.231 644.669 636.78"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M45.0287 50.7076C45.0287 50.7076 169.961 35.7906 97.056 160.696C24.1515 285.601 -20.4528 375.898 19.3962 437.091C22.2258 441.396 25.4545 445.425 29.0395 449.124C113.542 536.786 374.027 409.346 345.014 541.659C343.312 549.069 341.097 556.352 338.387 563.455C273.916 737.072 343.606 851.734 644.801 637.029"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M44.0844 51.1386C44.0844 51.1386 169.679 36.2216 95.4489 162.27C21.2188 288.319 -24.2471 379.064 16.0327 439.942C92.2511 555.134 402.493 391.976 337.475 565.543C272.458 739.111 341.485 854.22 645 637.277"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
      </svg>
    );
  }

  if (name === 'about2') {
    return (
      <svg
        width="947"
        height="887"
        viewBox="0 0 947 887"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M887.901 415.967C887.901 415.967 876.58 476.642 844.983 521.943C811.317 570.544 756.425 607.235 668.26 550.354C665.434 548.521 662.432 546.596 659.496 544.555C482.206 420.832 634.055 644.48 465.479 558.538C460.743 556.119 456.041 553.816 451.374 551.629C289.454 476.639 172.494 569.13 139.302 635.36"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.854 414.923C887.854 414.923 876.197 476.14 844.125 519.876C809.793 566.724 755.074 602.103 666.939 546.134C664.107 544.317 661.057 542.461 658.115 540.436C481.026 419.06 631.974 642.672 463.958 556.893C459.213 554.452 454.512 552.12 449.818 549.898C288.385 473.962 171.027 566.159 138.948 635.391"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.796 413.947C887.796 413.947 875.844 475.741 843.263 517.861C808.356 562.994 753.684 597.064 665.542 542.098C662.621 540.279 659.612 538.495 656.631 536.561C479.844 417.379 629.796 641.093 462.431 555.264C457.67 552.817 452.952 550.441 448.249 548.197C287.15 471.594 169.593 563.15 138.741 635.412"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.768 412.857C887.768 412.857 875.55 475.24 842.439 515.754C806.937 559.182 752.362 591.819 664.21 537.819C661.276 536.031 658.233 534.286 655.284 532.276C478.677 415.577 627.678 639.288 460.839 553.662C456.085 551.199 451.357 548.801 446.637 546.514C285.907 469.203 168.097 560.204 138.411 635.471"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.721 411.813C887.721 411.813 875.212 474.847 841.582 513.687C805.52 555.406 750.996 586.681 662.901 533.658C659.945 531.878 656.877 530.195 653.896 528.26C477.548 413.899 625.349 638.112 459.298 552.152C454.535 549.667 449.804 547.233 445.054 544.933C284.665 466.938 166.622 557.339 138.116 635.617"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.668 410.784C887.668 410.784 874.925 474.331 840.732 511.642C804.087 551.625 749.622 581.558 661.546 529.477C658.562 527.722 655.468 526.099 652.474 524.196C476.414 412.147 623.16 636.475 457.762 550.501C452.983 548.01 448.244 545.554 443.476 543.211C283.43 464.569 165.161 554.354 137.826 635.621"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.636 409.746C887.636 409.746 874.619 473.861 839.89 509.581C802.67 547.849 748.262 576.405 660.222 525.309C657.231 523.569 654.096 522.001 651.09 520.128C475.326 410.415 621.024 634.841 456.237 548.909C451.443 546.411 446.708 543.903 441.915 541.532C282.213 462.243 163.717 551.411 137.548 635.684"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.582 408.717C887.582 408.717 874.307 473.406 839.032 507.513C801.253 544.073 746.881 571.261 658.943 521.161C655.925 519.444 652.771 517.923 649.751 516.08C474.296 408.671 618.927 633.242 454.765 547.32C449.956 544.816 445.197 542.28 440.401 539.872C280.92 459.886 162.259 548.463 137.255 635.741"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.535 407.673C887.535 407.673 874.001 472.937 838.189 505.453C799.827 540.276 745.463 566.245 657.543 516.961C654.518 515.26 651.316 513.808 648.29 511.981C473.12 406.847 616.714 631.577 453.164 545.696C448.346 543.171 443.584 540.597 438.765 538.161C279.734 457.573 160.804 545.463 136.977 635.804"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.473 406.623C887.473 406.623 873.686 472.445 837.337 503.37C798.407 536.464 744.056 561.073 656.194 512.765C653.141 511.088 649.92 509.682 646.881 507.886C472.112 405.095 614.56 629.9 451.615 544.076C446.797 541.55 442.026 538.955 437.189 536.475C278.495 455.256 159.332 542.545 136.638 635.841"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.45 405.607C887.45 405.607 873.389 471.997 836.488 501.325C796.999 532.71 742.632 556.072 654.863 508.612C651.797 506.966 648.542 505.6 645.49 503.834C471.08 403.314 612.389 628.306 450.075 542.477C445.248 539.93 440.475 537.297 435.614 534.79C277.256 452.939 157.873 539.596 136.401 635.849"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.396 404.578C887.396 404.578 873.098 471.533 835.646 499.264C795.604 528.926 741.248 550.891 653.523 504.438C650.436 502.801 647.155 501.496 644.084 499.775C470.035 401.563 610.237 626.666 448.542 540.863C443.7 538.309 438.918 535.655 434.039 533.104C276.025 450.607 156.399 536.642 136.088 635.952"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.349 403.534C887.349 403.534 872.794 471.1 834.803 497.203C794.212 525.089 739.841 545.808 652.184 500.264C649.068 498.651 645.762 497.406 642.684 495.701C469.066 399.719 608.117 624.949 446.97 539.34C442.119 536.765 437.319 534.067 432.45 531.449C274.76 448.351 154.928 533.724 135.782 636.039"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.296 402.505C887.296 402.505 872.523 470.591 834.028 495.153C792.772 521.412 738.418 540.718 650.844 496.09C647.716 494.507 644.349 493.364 641.285 491.628C468.053 397.892 605.934 623.386 445.462 537.665C440.602 535.068 435.794 532.349 430.82 529.723C273.461 446.009 153.415 530.735 135.434 636.055"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.264 401.468C887.264 401.468 872.238 470.112 833.103 493.075C791.408 517.551 736.941 535.714 649.504 491.915C646.354 490.342 642.994 489.183 639.885 487.554C467.086 396.085 603.754 621.77 443.913 536.045C439.053 533.448 434.242 530.692 429.272 528.102C272.313 443.82 151.982 527.851 135.167 636.176"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.21 400.439C887.21 400.439 871.954 469.633 832.26 491.014C790.019 513.752 735.544 530.563 648.19 487.68C645.012 486.131 641.641 485.039 638.51 483.42C466.158 394.185 601.647 620.023 442.399 534.385C437.53 531.766 432.71 528.988 427.809 526.32C271.119 441.396 150.605 524.794 134.971 636.13"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.163 399.395C887.163 399.395 871.678 469.176 831.35 488.943C788.639 509.974 734.08 525.528 646.844 483.521C643.659 481.987 640.248 480.95 637.078 479.422C465.102 392.376 599.463 618.46 440.833 532.847C435.949 530.222 431.127 527.407 426.17 524.698C269.744 439.148 149.084 521.909 134.615 636.25"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.116 398.351C887.116 398.351 871.409 468.704 830.534 486.947C787.231 506.22 732.629 520.463 645.498 479.363C642.285 477.852 638.848 476.877 635.717 475.257C464.306 390.461 597.356 616.713 439.319 531.188C434.426 528.541 429.58 525.698 424.621 522.952C268.533 436.807 147.703 518.903 134.399 636.249"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.078 397.329C887.078 397.329 871.14 468.232 829.708 484.804C785.889 502.35 731.204 515.336 644.152 475.204C640.911 473.717 637.455 472.788 634.285 471.26C463.328 388.721 595.179 615.134 437.772 529.604C432.864 526.951 428.015 524.071 423.032 521.297C267.263 434.566 146.186 515.966 134.041 636.332"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.031 396.285C887.031 396.285 870.81 467.734 828.852 482.773C784.511 498.61 729.734 510.317 642.806 471.045C639.559 469.574 636.061 468.699 632.879 467.201C462.472 386.78 593.042 613.501 436.226 528.021C431.309 525.346 426.452 522.444 421.451 519.626C266.02 432.302 144.686 513.073 133.747 636.389"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.977 395.256C886.977 395.256 870.617 467.293 828.01 480.712C783.131 494.831 728.235 505.321 641.481 466.877C638.18 465.491 634.705 464.607 631.494 463.134C461.61 384.891 590.881 611.839 434.693 526.406C429.776 523.731 424.909 520.808 419.891 517.947C264.701 430.005 143.246 510.078 133.467 636.415"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.93 394.212C886.93 394.212 870.357 466.843 827.152 478.645C781.773 491.045 726.797 500.225 640.129 462.733C636.797 461.335 633.29 460.527 630.066 459.085C460.689 382.977 588.708 610.208 433.125 524.832C428.199 522.135 423.33 519.175 418.281 516.301C263.492 427.79 141.714 507.135 133.148 636.533"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.892 393.19C886.892 393.19 870.119 466.383 826.309 476.585C780.469 487.299 725.318 495.184 638.802 458.528C635.455 457.124 631.909 456.408 628.664 454.974C459.899 381.046 586.575 608.522 431.598 523.203C426.663 520.484 421.785 517.503 416.718 514.585C262.247 425.489 140.314 504.175 132.852 636.552"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.845 392.145C886.845 392.145 869.878 465.887 825.467 474.524C779.058 483.508 723.833 490.158 637.437 454.415C634.09 453.01 630.496 452.365 627.239 450.961C459.038 379.157 584.405 606.928 430.039 521.65C425.089 518.925 420.193 515.9 415.117 512.961C260.957 423.294 138.855 501.227 132.533 636.67"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.791 391.117C886.791 391.117 869.62 465.473 824.608 472.456C777.693 479.737 722.347 485.132 636.091 450.256C632.728 448.845 629.118 448.282 625.826 446.918C458.233 377.22 582.253 605.288 428.492 520.066C423.533 517.32 418.629 514.273 413.535 511.29C259.693 421.039 137.364 498.355 132.24 636.726"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.718 390.134C886.718 390.134 869.349 465.09 823.74 470.457C776.327 476.055 720.806 480.154 634.757 446.067C631.348 444.726 627.737 444.163 624.432 442.829C457.432 375.231 580.098 603.611 426.95 518.431C421.991 515.684 417.077 512.616 411.966 509.59C258.461 418.707 135.944 495.315 131.978 636.707"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.706 389.05C886.706 389.05 869.123 464.6 822.89 468.285C774.97 472.179 719.383 475.064 633.41 441.782C629.988 440.471 626.343 439.948 623.025 438.645C456.64 373.137 577.906 601.811 425.402 516.721C420.435 513.953 415.544 510.786 410.384 507.793C257.157 416.416 134.437 492.311 131.591 636.688"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.659 388.006C886.659 388.006 868.878 464.156 822.059 466.283C773.653 468.463 717.872 470.1 632.104 437.657C628.66 436.356 625.004 435.9 621.658 434.621C455.939 371.208 575.84 600.225 423.908 515.141C418.925 512.367 414.02 509.194 408.848 506.142C255.926 414.21 133.011 489.412 131.392 636.82"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.612 386.962C886.612 386.962 868.627 463.727 821.216 464.222C772.291 464.729 716.346 465.128 630.764 433.483C627.292 432.206 623.617 431.795 620.259 430.547C455.158 369.173 573.701 598.554 422.362 513.558C417.377 510.747 412.443 507.598 407.266 504.472C254.656 411.97 131.552 486.464 131.129 636.889"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.558 385.933C886.558 385.933 868.459 463.315 820.404 462.174C770.965 460.992 714.891 460.115 629.455 429.322C625.955 428.068 622.261 427.704 618.889 426.486C454.444 367.148 571.593 596.896 420.859 511.957C415.858 509.139 410.894 505.977 405.721 502.799C253.431 409.749 130.078 483.509 130.805 636.933"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.526 384.896C886.526 384.896 868.177 462.873 819.516 460.094C769.587 457.251 713.359 455.069 628.085 425.135C624.572 423.912 620.859 423.593 617.433 422.461C453.721 365.102 569.423 595.213 419.289 510.345C414.279 507.505 409.232 504.327 404.115 501.101C252.114 407.489 128.619 480.56 130.518 636.974"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.473 383.867C886.473 383.867 867.873 462.44 818.673 458.033C768.231 453.501 711.82 450.129 626.745 420.961C623.204 419.762 619.472 419.489 616.078 418.28C453 362.966 567.288 593.49 417.759 508.679C412.756 505.824 407.741 502.569 402.581 499.361C250.874 405.135 127.186 477.551 130.25 636.969"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.426 382.823C886.426 382.823 867.576 461.992 817.83 455.973C766.884 449.773 710.309 445.164 625.405 416.786C621.842 415.596 618.084 415.385 614.653 414.268C452.27 360.935 565.124 591.881 416.219 507.08C411.201 504.219 406.207 500.955 400.999 497.691C249.551 403.017 125.702 474.664 129.931 637.087"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.372 381.794C886.372 381.794 867.41 461.616 816.972 453.905C765.522 446.038 708.783 440.192 624.005 412.586C620.414 411.421 616.639 411.292 613.192 410.168C451.528 358.808 562.93 590.169 414.599 505.501C409.572 502.619 404.6 499.346 399.343 496.025C248.22 400.751 124.234 471.694 129.638 637.144"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.34 380.757C886.34 380.757 867.152 461.203 816.104 451.906C764.142 442.387 707.224 435.298 622.713 408.469C619.094 407.327 615.332 407.167 611.84 406.151C450.885 356.742 560.839 588.555 413.114 503.944C408.071 501.055 403.128 497.758 397.816 494.396C246.991 398.582 122.756 468.791 129.347 637.238"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.287 379.728C886.287 379.728 866.878 460.872 815.287 449.784C762.825 438.545 705.717 430.28 621.361 404.325C617.729 403.214 613.913 403.14 610.428 402.108C450.21 354.625 558.688 586.915 411.561 502.376C406.51 499.465 401.496 496.12 396.228 492.741C245.727 396.326 121.317 465.797 129.051 637.257"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.24 378.684C886.24 378.684 866.698 460.401 814.49 447.742C761.513 434.904 704.245 425.35 620.108 400.115C616.448 399.028 612.628 399.006 609.114 397.999C449.649 352.449 556.635 585.209 410.099 500.72C405.047 497.809 400.025 494.443 394.739 491.02C244.505 394.142 119.919 462.874 128.825 637.324"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.186 377.655C886.186 377.655 866.387 459.984 813.558 445.68C760.055 431.183 702.617 420.408 618.725 395.959C615.036 394.896 611.198 394.92 607.665 393.958C448.956 350.289 554.459 583.541 408.516 499.139C403.455 496.206 398.43 492.803 393.12 489.352C243.184 391.935 118.401 459.937 128.488 637.398"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.155 376.617C886.155 376.617 866.145 459.576 812.671 443.51C758.664 427.346 701.055 415.349 617.317 391.648C613.6 390.609 609.758 390.685 606.197 389.748C448.288 348.068 552.303 581.827 406.965 497.481C401.889 494.542 396.855 491.117 391.527 487.623C241.868 389.675 116.919 456.961 128.178 637.411"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.116 375.595C886.116 375.595 865.896 459.184 811.921 441.488C757.387 423.666 699.597 410.515 616.069 387.512C612.339 386.504 608.462 386.619 604.888 385.712C447.714 345.796 550.257 580.106 405.466 495.828C400.381 492.867 395.339 489.42 389.986 485.898C240.626 387.41 115.507 453.942 127.925 637.413"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.054 374.545C886.054 374.545 865.586 458.767 811.037 439.482C755.989 419.971 698.03 405.598 614.682 383.408C610.931 382.409 607.034 382.57 603.448 381.694C447.064 343.619 548.038 578.456 403.885 494.284C398.791 491.301 393.731 487.812 388.356 484.172C239.364 385.192 113.979 450.947 127.577 637.429"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.007 373.501C886.007 373.501 865.333 458.427 810.179 437.415C754.601 416.297 696.484 400.673 613.342 379.234C609.563 378.259 605.662 378.472 602.048 377.62C446.492 341.384 545.867 576.862 402.351 492.67C397.242 489.681 392.173 486.169 386.823 482.558C238.061 383.028 112.568 448.055 127.325 637.557"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.968 372.478C885.968 372.478 865.088 457.982 809.336 435.354C753.232 412.578 694.932 395.762 612.002 375.059C608.195 374.108 604.282 374.353 600.648 373.546C445.843 339.117 543.766 575.1 400.796 491.064C395.694 488.06 390.616 484.527 385.268 480.953C236.737 380.873 111.129 445.187 127.059 637.678"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.921 371.434C885.921 371.434 864.824 457.584 808.493 433.293C751.857 408.874 693.304 390.82 610.662 370.885C606.827 369.958 602.91 370.255 599.248 369.473C445.26 336.824 541.602 573.49 399.263 489.45C394.145 486.44 389.058 482.885 383.612 479.287C235.397 378.711 109.59 442.259 126.685 637.755"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.868 370.405C885.868 370.405 864.468 457.147 807.636 431.226C750.473 405.148 691.822 385.957 609.323 366.711C605.475 365.815 601.523 366.151 597.816 365.475C444.662 334.525 539.547 571.748 397.723 487.852C392.596 484.82 387.507 481.228 382.069 477.526C234.124 376.434 108.148 439.228 126.408 637.729"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.821 369.361C885.821 369.361 864.274 456.796 806.793 429.165C749.312 401.534 690.264 381.062 607.983 362.537C604.107 361.665 600.151 362.053 596.461 361.295C444.111 332.155 537.373 569.99 396.209 486.192C391.067 483.153 385.953 479.533 380.512 475.794C232.854 374.193 106.709 436.233 126.134 637.74"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.782 368.339C885.782 368.339 863.995 456.391 805.95 427.104C747.906 397.817 688.746 376.112 606.643 358.363C602.739 357.515 598.771 357.933 595.042 357.267C443.505 329.87 535.212 568.328 394.649 484.639C389.499 481.578 384.376 477.937 378.918 474.154C231.495 372.077 105.231 433.331 125.821 637.842"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.735 367.295C885.735 367.295 863.73 455.993 805.073 425.083C746.416 394.173 687.136 371.34 605.284 354.234C601.352 353.411 597.364 353.875 593.623 353.239C442.929 327.562 533.091 566.702 393.081 483.064C387.931 480.004 382.799 476.341 377.323 472.515C230.22 369.89 103.783 430.441 125.509 637.945"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.656 366.327C885.656 366.327 863.419 455.665 804.224 423.038C745.029 390.41 685.565 366.475 603.938 350.076C599.993 349.282 595.986 349.793 592.217 349.181C442.31 325.182 530.952 565.031 391.535 481.481C386.382 478.384 381.242 474.699 375.757 470.851C228.934 367.643 102.209 427.426 125.287 637.959"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.635 365.222C885.635 365.222 863.165 455.199 803.4 420.931C743.636 386.663 684.041 361.541 602.624 345.84C598.651 345.071 594.631 345.612 590.842 345.046C441.821 322.748 528.851 563.269 390.02 479.821C384.859 476.702 379.71 472.995 374.207 469.104C227.638 365.463 100.852 424.449 124.948 637.997"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.52 364.168C885.52 364.168 862.894 454.816 802.543 418.864C742.191 382.912 682.47 356.677 601.269 341.66C597.253 340.908 593.229 341.501 589.406 340.975C441.237 320.329 526.688 561.57 388.457 478.194C383.279 475.069 378.106 471.334 372.601 467.406C226.333 363.262 99.3104 421.484 124.635 638.099"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.549 363.156C885.549 363.156 862.615 454.411 801.7 416.803C740.785 379.195 680.914 351.819 599.954 337.513C595.909 336.786 591.866 337.425 588.03 336.929C440.705 317.913 524.587 559.897 386.941 476.623C381.755 473.476 376.573 469.72 371.05 465.749C225.015 361.092 97.8607 418.557 124.339 638.118"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.496 362.127C885.496 362.127 862.329 454.021 800.857 414.742C739.385 375.463 679.336 346.969 598.621 333.324C594.563 332.627 590.492 333.29 586.636 332.84C440.155 315.454 522.467 558.181 385.398 474.988C380.212 471.84 375.028 468.047 369.481 464.048C223.725 358.897 96.3929 415.587 124.068 638.166"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.449 361.083C885.449 361.083 862.024 453.678 799.999 412.675C737.975 371.672 677.78 342.112 597.281 329.15C593.195 328.477 589.12 329.193 585.237 328.766C439.606 312.995 520.334 556.495 383.859 473.389C378.664 470.22 373.47 466.405 367.905 462.362C222.414 356.712 94.9954 412.664 123.774 638.223"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.447 360.058C885.447 360.058 861.81 453.246 799.203 410.633C736.595 368.02 676.264 337.288 595.987 324.995C591.867 324.362 587.766 325.138 583.883 324.712C439.108 310.54 518.254 554.813 382.371 471.794C377.161 468.619 371.958 464.782 366.376 460.696C221.096 354.541 93.4754 409.69 123.487 638.264"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.363 359.017C885.363 359.017 861.478 452.837 798.314 408.553C735.15 364.269 674.649 332.441 594.556 320.782C590.407 320.173 586.319 320.919 582.391 320.6C438.486 307.997 516.036 553.074 380.739 470.157C375.535 466.967 370.294 463.095 364.709 458.972C219.738 352.336 92.0165 406.742 123.148 638.301"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.316 357.972C885.316 357.972 861.183 452.426 797.424 406.562C733.665 360.699 673.055 327.675 593.23 316.704C589.069 316.125 584.955 316.932 581.005 316.622C437.939 305.575 513.924 551.468 379.22 468.639C374.001 465.442 368.75 461.549 363.147 457.382C218.466 350.185 90.5579 403.794 122.901 638.377"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.263 356.944C885.263 356.944 860.897 452.037 796.613 404.425C732.33 356.814 671.533 322.778 591.922 312.453C587.733 311.899 583.6 312.751 579.631 312.487C437.416 302.966 511.83 549.691 377.712 466.964C372.484 463.745 367.231 459.815 361.597 455.635C217.123 348.076 89.145 400.864 122.607 638.434"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.216 355.9C885.216 355.9 860.631 451.601 795.756 402.358C730.88 353.115 669.958 317.965 590.583 308.279C586.359 307.764 582.22 308.632 578.205 308.474C436.858 300.485 509.685 548.036 376.137 465.404C370.909 462.186 365.648 458.234 359.996 454.011C215.837 345.829 87.6212 397.943 122.314 638.49"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.177 354.877C885.177 354.877 860.317 451.236 794.867 400.278C729.417 349.32 668.391 313.049 589.197 304.085C584.945 303.594 580.808 304.499 576.805 304.274C436.3 297.789 507.603 546.228 374.597 463.679C369.366 460.424 364.074 456.459 358.42 452.199C214.493 343.593 86.1921 394.881 122.04 638.501"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.13 353.834C885.13 353.834 860.031 450.846 794.057 398.267C728.084 345.688 666.822 308.348 587.896 299.946C583.632 299.485 579.461 300.43 575.425 300.281C435.769 295.284 505.473 544.579 373.083 462.146C367.844 458.868 362.542 454.882 356.871 450.579C213.201 341.488 84.729 391.985 121.701 638.665"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.077 352.805C885.077 352.805 859.736 450.435 793.175 396.172C726.614 341.908 665.239 303.514 586.533 295.743C582.24 295.307 578.056 296.282 574.001 296.18C435.255 292.696 503.316 542.865 371.519 460.519C366.264 457.235 360.954 453.227 355.265 448.881C211.883 339.317 83.301 389.049 121.41 638.632"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.055 351.699C885.055 351.699 859.476 449.984 792.389 394.062C725.302 338.141 663.709 298.721 585.249 291.521C580.922 291.124 576.747 292.121 572.657 292.058C434.691 290.016 501.253 541.101 370.036 458.872C364.772 455.566 359.453 451.537 353.746 447.147C210.565 337.147 81.8119 386.088 121.147 638.701"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.991 350.738C884.991 350.738 859.156 449.634 791.521 392.063C723.886 334.491 662.143 293.93 583.808 287.376C579.468 287.01 575.258 288.046 571.149 288.028C434.097 287.448 499.163 539.486 368.461 457.312C363.198 454.007 357.876 449.941 352.145 445.522C209.226 334.985 80.3683 383.146 120.854 638.758"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.944 349.694C884.944 349.694 858.87 449.245 790.724 390.021C722.578 330.798 660.644 289.15 582.606 283.259C578.231 282.932 574.015 283.984 569.887 284.012C433.596 284.83 497.058 537.776 366.957 455.8C361.684 452.473 356.339 448.379 350.605 443.923C207.943 332.902 78.9554 380.217 120.596 638.901"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.845 348.646C884.845 348.646 858.529 448.814 789.774 387.915C721.019 327.017 658.91 284.289 581.159 279.04C576.756 278.737 572.527 279.82 568.38 279.894C432.927 282.141 494.831 536.015 365.342 454.08C360.054 450.747 354.7 446.63 348.964 442.138C206.538 330.64 77.3897 377.224 120.221 638.852"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.844 347.621C884.844 347.621 858.289 448.444 788.977 385.874C719.666 323.304 657.45 279.544 579.865 274.885C575.449 274.613 571.192 275.719 567.026 275.839C432.387 279.489 492.69 534.308 363.848 452.501C358.174 448.78 352.692 444.775 347.421 440.503C205.231 328.529 75.9701 374.31 119.96 638.958"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.812 346.584C884.812 346.584 857.994 448.033 788.119 383.807C718.245 319.581 655.923 274.788 578.553 270.687C574.103 270.454 569.855 271.582 565.654 271.741C431.835 276.777 490.659 532.593 362.342 450.863C356.645 447.108 351.142 443.065 345.856 438.75C203.944 326.371 74.5243 371.331 119.671 638.962"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.758 345.555C884.758 345.555 857.678 447.63 787.277 381.746C716.876 315.862 654.418 270.023 577.16 266.598C572.697 266.396 568.421 267.548 564.2 267.753C431.202 274.175 488.501 530.968 360.749 449.349C355.056 445.577 349.562 441.514 344.288 437.175C202.586 324.166 73.0337 368.459 119.439 639.045"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.772 344.536C884.772 344.536 857.46 447.251 786.495 379.71C715.531 312.17 652.861 265.254 575.907 262.388C571.384 262.286 567.127 263.393 562.887 263.644C430.694 271.446 486.469 529.216 359.274 447.724C353.567 443.908 348.062 439.798 342.782 435.41C201.33 322.021 71.6657 365.423 119.159 639.07"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.658 343.482C884.658 343.482 857.08 446.912 785.576 377.618C714.072 308.323 651.257 260.466 574.506 258.188C569.968 258.08 565.679 259.263 561.359 259.534C429.913 268.639 484.226 527.449 357.624 446.043C351.895 442.223 346.369 438.104 341.071 433.705C199.916 319.865 70.148 362.486 118.791 639.132"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.594 342.521C884.594 342.521 856.786 446.501 784.695 375.649C712.604 304.796 649.668 255.774 573.135 254.091C568.596 253.983 564.266 255.22 559.942 255.543C429.338 266.025 482.105 525.822 356.068 444.527C350.315 440.702 344.77 436.575 339.454 432.163C198.592 317.709 68.674 359.531 118.523 639.127"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.572 341.416C884.572 341.416 856.547 446.042 783.884 373.511C711.222 300.981 648.151 250.95 571.827 249.84C567.273 249.725 562.918 251.024 558.564 251.461C428.747 263.28 480.068 524.122 354.556 442.904C348.788 439.046 343.236 434.874 337.926 430.407C197.183 315.627 67.1591 356.631 118.148 639.293"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.525 340.372C884.525 340.372 856.222 445.618 783.026 371.444C709.831 297.271 646.596 246.218 570.487 245.666C565.895 245.643 561.537 246.904 557.224 247.287C428.149 260.549 477.976 522.256 353.072 441.257C347.291 437.372 341.726 433.177 336.4 428.689C195.899 313.417 65.7346 353.643 117.911 639.302"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.472 339.343C884.472 339.343 855.921 445.222 782.184 369.383C708.447 293.545 645.06 241.441 569.147 241.491C564.527 241.493 560.163 242.77 555.82 243.265C427.529 257.701 475.859 520.576 351.523 439.637C345.731 435.733 340.157 431.515 334.824 427.003C194.566 311.24 64.3193 350.677 117.624 639.343"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.44 338.305C884.44 338.305 855.626 444.81 781.341 367.323C707.056 289.835 643.512 236.694 567.808 237.317C563.168 237.365 558.782 238.65 554.436 239.198C426.892 254.936 473.665 518.865 350.003 437.992C344.168 434.071 338.555 429.832 333.188 425.292C193.239 309.048 62.756 347.721 117.269 639.374"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.386 337.277C884.386 337.277 855.331 444.399 780.483 365.256C705.635 286.112 641.978 231.953 566.468 233.143C561.801 233.215 557.401 234.531 553.045 235.146C426.271 252.177 471.633 517.239 348.481 436.437C342.65 432.495 337.049 428.224 331.704 423.645C191.935 306.973 61.3866 344.774 117.083 639.475"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.339 336.232C884.339 336.232 855.03 444.003 779.564 363.163C704.098 282.322 640.369 227.18 565.052 228.937C560.365 229.054 555.945 230.379 551.584 231.046C425.549 249.358 469.478 515.436 346.88 434.813C341.034 430.845 335.421 426.545 330.068 421.934C190.498 304.788 59.9126 341.82 116.713 639.5"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.286 335.204C884.286 335.204 854.75 443.598 778.782 361.128C702.815 278.657 638.912 222.472 563.789 224.795C559.074 224.936 554.621 226.337 550.276 227.011C424.964 246.597 467.471 513.75 345.431 433.252C339.57 429.259 333.945 424.93 328.584 420.287C189.213 302.668 58.4995 338.89 116.512 639.595"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.254 334.166C884.254 334.166 854.449 443.203 778.001 359.092C701.553 274.982 637.44 217.757 562.51 220.646C557.776 220.833 553.312 222.176 548.876 222.938C424.272 243.791 465.291 512.008 343.911 431.608C338.029 427.605 332.385 423.262 327.009 418.601C187.836 300.509 57.0408 335.942 116.225 639.636"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.176 333.109C884.176 333.109 854.124 442.778 777.082 356.999C700.04 271.221 635.858 212.96 561.064 216.427C556.446 216.915 551.865 217.712 547.354 218.813C423.449 240.912 463.116 510.25 342.221 429.982C336.355 425.946 330.732 421.569 325.379 416.875C186.49 298.362 55.5669 332.987 115.87 639.667"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.153 332.093C884.153 332.093 853.86 442.38 776.239 354.939C698.619 267.497 634.321 208.272 559.785 212.278C555.143 212.786 550.542 213.617 546.015 214.765C422.804 238.125 461.079 508.551 340.748 428.394C334.838 424.342 329.177 419.938 323.797 415.205C185.144 296.216 54.0726 329.952 115.574 639.687"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.106 331.049C884.106 331.049 853.559 441.984 775.39 352.893C697.222 263.802 632.796 203.552 558.446 208.104C553.776 208.643 549.15 209.505 544.6 210.684C422.066 235.3 458.975 506.841 339.208 426.795C333.291 422.715 327.624 418.284 322.237 413.526C183.783 294.063 52.5947 327.05 115.284 639.78"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.068 330.027C884.068 330.027 853.264 441.573 774.548 350.832C695.831 260.092 631.278 198.818 557.131 203.869C552.436 204.435 547.786 205.336 543.219 206.565C421.294 232.514 456.862 505.109 337.675 425.181C331.735 421.087 326.054 416.632 320.661 411.84C182.452 291.923 51.1205 324.095 114.99 639.837"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M884.021 328.983C884.021 328.983 852.969 441.162 773.69 348.765C694.41 256.369 629.754 194.099 555.799 199.806C551.08 200.398 546.41 201.333 541.827 202.602C420.622 229.662 454.797 503.434 336.193 423.571C330.242 419.446 324.549 414.962 319.145 410.143C181.056 289.81 49.6617 321.147 114.697 639.893"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.993 327.893C883.993 327.893 852.694 440.705 772.873 346.643C693.052 252.582 628.27 189.326 554.452 195.521C549.709 196.135 545.018 197.103 540.42 198.418C419.862 226.72 452.707 501.604 334.612 421.901C328.645 417.762 322.942 413.256 317.536 408.408C179.749 287.572 48.2287 318.137 114.435 639.873"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.92 326.91C883.92 326.91 852.183 440.508 772.011 344.628C691.625 249.215 626.742 184.659 553.093 191.392C548.316 192.054 543.597 193.076 538.975 194.451C419.04 223.967 450.533 499.973 333.027 420.409C327.045 416.244 321.33 411.709 315.916 406.829C178.336 285.541 46.7251 315.296 114.097 640.037"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.882 325.888C883.882 325.888 851.908 440.051 771.147 342.576C690.158 245.473 625.226 179.962 551.747 187.233C546.965 187.891 542.24 188.911 537.614 190.286C418.282 221.061 448.474 498.156 331.526 418.718C325.527 414.529 319.8 409.965 314.379 405.052C177.049 283.384 45.2703 312.295 113.823 640.047"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.835 324.844C883.835 324.844 851.615 439.677 770.304 340.515C688.765 241.726 623.717 175.249 550.407 183.059C545.592 183.746 540.839 184.816 536.194 186.258C417.468 218.204 446.348 496.455 329.973 417.15C323.952 412.947 318.211 408.359 312.785 403.413C175.661 281.166 43.8115 309.347 113.53 640.104"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.781 323.815C883.781 323.815 851.327 439.251 769.461 338.455C687.365 237.994 622.223 170.543 549.067 178.885C544.226 179.604 539.451 180.712 534.788 182.2C416.649 215.273 444.249 494.73 328.433 415.552C322.402 411.316 316.649 406.699 311.209 401.727C174.274 279.075 42.3524 306.399 113.221 640.155"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.734 322.771C883.734 322.771 851.039 438.824 768.603 336.387C685.981 234.268 620.714 165.83 547.728 174.711C542.862 175.451 538.066 176.594 533.388 178.126C415.825 212.483 442.152 493.005 326.899 413.937C320.845 409.689 315.077 405.047 309.634 400.041C172.922 276.943 40.8916 303.413 112.928 640.211"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.696 321.749C883.696 321.749 850.765 438.404 767.76 334.327C684.573 230.514 619.246 161.063 546.388 170.536C541.499 171.306 536.682 172.482 531.988 174.052C414.981 209.612 440.045 491.258 325.344 412.332C319.281 408.058 313.507 403.39 308.058 398.356C171.554 274.806 39.439 300.45 112.641 640.252"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.649 320.704C883.649 320.704 850.483 437.962 766.918 332.266C683.189 226.789 617.812 156.472 545.049 166.362C540.134 167.154 535.297 168.364 530.589 169.978C414.115 206.751 437.947 489.533 323.811 410.718C317.73 406.428 311.939 401.74 306.477 396.686C170.186 272.669 37.9676 297.532 112.347 640.309"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.602 319.66C883.602 319.66 850.197 437.572 766.053 330.214C681.909 222.855 616.272 151.747 543.709 162.188C538.77 163.002 533.911 164.246 529.189 165.905C413.255 203.875 435.849 487.808 322.271 409.119C316.158 404.79 310.335 400.065 304.84 394.975C168.757 270.506 36.4474 294.558 111.977 640.333"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.516 318.708C883.516 318.708 849.938 437.248 765.179 328.23C680.419 219.211 614.715 147.104 542.337 158.09C537.374 158.933 532.495 160.21 527.757 161.908C412.326 201.077 433.725 486.144 320.705 407.582C314.59 403.246 308.773 398.506 303.293 393.391C167.418 268.47 35.0025 291.705 111.728 640.498"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.501 317.588C883.501 317.588 849.667 436.739 764.353 326.086C679.039 215.433 613.227 142.256 541.014 153.833C536.026 154.698 531.127 156.01 526.374 157.751C411.446 198.12 431.779 484.141 319.182 405.9C313.079 401.538 307.271 396.777 301.796 391.649C166.083 266.256 33.5759 288.681 111.467 640.479"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.463 316.565C883.463 316.565 849.372 436.327 763.495 324.018C677.618 211.71 611.805 137.634 539.69 149.665C534.674 150.557 529.751 151.907 524.983 153.699C410.482 195.235 429.662 482.462 317.642 404.301C311.502 399.915 305.667 395.118 300.175 389.944C164.493 264.349 32.1172 285.732 111.174 640.535"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.416 315.521C883.416 315.521 849.075 435.879 762.668 321.964C676.26 208.05 610.32 132.95 538.35 145.491C533.309 146.406 528.365 147.79 523.583 149.626C409.609 192.389 427.536 480.761 316.109 402.688C309.947 398.287 304.096 393.466 298.599 388.258C163.187 262.148 30.6583 282.784 110.887 640.576"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.362 314.492C883.362 314.492 848.765 435.461 761.81 319.897C674.855 204.333 608.857 128.257 537.01 141.317C531.946 142.26 526.981 143.678 522.183 145.552C408.647 189.542 425.342 479.049 314.569 401.089C308.395 396.651 302.537 391.791 297.037 386.542C161.902 259.901 29.2036 279.783 110.606 640.602"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.33 313.455C883.33 313.455 848.476 435.035 760.98 317.806C673.483 200.576 607.406 123.533 535.683 137.112C399.97 162.808 430.342 512.02 295.464 384.894C160.586 257.768 27.7318 276.865 110.3 640.689"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
      </svg>
    );
  }

  if (name === 'about3') {
    return (
      <svg
        style={{ transform: 'scaleX(-1)' }}
        width="1287"
        height="890"
        viewBox="0 0 1287 890"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M954.174 173.001C954.174 173.001 1008.97 239.722 1029.05 314.323C1050.8 394.108 1040.7 485.909 900.456 529.145C895.951 530.522 891.187 532.005 886.36 533.313C594.332 611.849 970.48 650.472 726.755 753.673C719.902 756.569 713.205 759.532 706.664 762.562C480.657 868.489 474.464 1076.97 515.526 1172.11"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M953.013 172.112C953.013 172.112 1008.08 239.68 1026.06 313.381C1045.34 392.303 1033.99 482.738 894.749 526.762C890.254 528.159 885.522 529.756 880.705 531.085C591.373 611.519 966.67 651.074 723.625 753.821C716.74 756.707 710.012 759.643 703.41 762.67C476.827 867.226 469.963 1075.87 515.24 1172.52"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M951.916 171.295C951.916 171.295 1007.34 239.699 1023.13 312.491C1040.05 390.485 1027.35 479.692 889.169 524.626C884.592 526.118 879.975 527.735 875.221 529.187C588.51 611.271 963.021 651.986 720.507 753.991C713.602 756.887 706.811 759.803 700.177 762.82C473.18 866.416 465.451 1074.7 515.077 1172.76"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M950.725 170.345C950.725 170.345 1006.54 239.563 1020.13 311.478C1034.7 388.576 1020.54 476.389 883.389 522.203C878.833 523.736 874.227 525.425 869.421 526.774C585.531 610.9 959.182 652.63 717.358 754.255C710.443 757.13 703.621 760.036 696.924 763.033C469.501 865.597 460.951 1073.65 514.844 1173.17"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M949.563 169.455C949.563 169.455 1005.81 239.571 1017.15 310.536C1029.38 386.696 1013.81 473.229 877.754 519.86C873.188 521.424 868.624 523.196 863.871 524.648C582.717 610.599 955.827 654.066 714.354 754.547C707.407 757.412 700.543 760.287 693.806 763.306C465.958 864.89 456.554 1072.65 514.734 1173.61"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M948.414 168.587C948.414 168.587 1005 239.414 1014.19 309.605C1024.04 384.827 1007.09 470.09 872.058 517.55C867.493 519.166 862.972 521.019 858.239 522.513C579.821 610.238 952.105 654.937 711.204 754.706C704.237 757.582 697.342 760.447 690.542 763.446C462.31 864.08 452.042 1071.53 514.478 1173.93"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M947.274 167.688C947.274 167.688 1004.22 239.319 1011.23 308.653C1018.72 382.947 1000.34 466.909 866.402 515.218C861.848 516.854 857.348 518.801 852.637 520.336C576.986 609.844 948.432 655.755 708.126 754.906C701.139 757.793 694.191 760.606 687.339 763.605C458.724 863.291 447.592 1070.43 514.295 1174.28"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M946.124 166.819C946.124 166.819 1003.46 239.244 1008.24 307.712C1013.4 381.068 993.591 463.76 860.81 512.854C856.255 514.542 851.788 516.551 847.098 518.127C574.192 609.377 944.834 656.562 705.101 755.053C698.093 757.951 691.093 760.765 684.2 763.733C455.036 862.554 443.123 1069.34 514.092 1174.65"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M944.964 165.93C944.964 165.93 1002.68 239.149 1005.28 306.76C1008.05 379.178 986.944 460.766 855.051 510.575C850.508 512.284 846.073 514.406 841.393 516.003C571.181 608.996 941.059 657.433 701.921 755.306C694.881 758.194 687.84 760.977 680.895 763.946C451.492 861.743 438.6 1068.19 513.91 1175"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M943.782 165.052C943.782 165.052 1001.87 239.044 1002.28 305.798C1002.69 377.268 980.139 457.617 849.344 508.244C844.801 510.004 840.398 512.189 835.739 513.826C568.397 608.498 937.324 658.231 698.792 755.507C691.752 758.394 684.68 761.168 677.672 764.117C447.896 860.985 434.151 1067.14 513.644 1175.4"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M942.674 164.162C942.674 164.162 1001.12 238.959 999.325 304.867C997.403 375.398 973.502 454.643 843.7 505.932C839.178 507.734 834.785 509.991 830.148 511.67C565.561 608.001 933.663 659.122 695.694 755.718C688.623 758.595 681.509 761.338 674.449 764.287C444.3 860.226 429.681 1066.05 513.439 1175.66"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M941.524 163.294C941.524 163.294 1000.36 238.854 996.359 303.915C992.094 373.488 966.707 451.464 838.024 503.611C833.492 505.445 829.142 507.784 824.536 509.524C562.746 607.545 929.971 659.951 692.585 755.908C685.494 758.795 678.348 761.528 671.226 764.458C440.694 859.448 425.19 1064.97 513.267 1176.09"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M940.363 162.405C940.363 162.405 999.625 238.789 993.394 302.962C986.734 371.525 959.997 448.397 832.348 501.289C827.817 503.174 823.509 505.596 818.914 507.357C559.899 606.923 926.225 660.676 689.54 756.221C682.418 759.099 675.21 761.811 668.025 764.669C437.141 858.771 420.741 1063.92 513.085 1176.49"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M939.214 161.536C939.214 161.536 998.836 238.622 990.501 301.948C981.5 369.759 953.267 445.341 826.673 498.968C822.163 500.894 817.908 503.471 813.293 505.191C557.031 606.364 922.586 661.608 686.39 756.328C679.236 759.196 671.997 761.899 664.71 764.862C433.463 858.055 416.179 1062.85 512.79 1176.88"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M938.073 160.637C938.073 160.637 998.067 238.496 987.443 301.069C976.138 367.745 946.578 442.419 820.996 496.647C816.476 498.604 812.212 501.16 807.67 503.025C554.225 605.773 918.894 662.488 683.261 756.529C676.107 759.397 668.826 762.069 661.58 765.062C430.085 857.315 411.802 1061.79 512.679 1177.27"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M936.924 159.768C936.924 159.768 997.298 238.369 984.478 300.117C970.819 365.814 939.806 439.281 815.279 494.243C810.759 496.252 806.558 498.88 802.006 500.776C551.356 605.059 915.128 663.173 680.121 756.657C672.936 759.515 665.624 762.177 658.356 765.025C426.416 856.413 407.289 1060.52 512.453 1177.44"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M935.763 158.879C935.763 158.879 996.561 238.253 981.441 299.228C965.531 363.893 933.096 436.318 809.613 491.942C805.104 493.972 800.924 496.693 796.436 498.712C548.468 604.562 911.488 664.104 677.065 756.95C669.859 759.818 662.506 762.45 655.144 765.32C422.77 855.862 402.83 1059.55 512.261 1177.93"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M934.602 157.991C934.602 157.991 995.812 238.116 978.569 298.307C960.244 362.023 926.365 433.314 803.948 489.642C799.439 491.724 795.302 494.527 790.75 496.423C545.7 603.691 907.721 664.789 673.925 757.078C666.688 759.937 659.282 762.569 651.879 765.408C419.174 855.052 398.368 1058.32 512.066 1178.16"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M933.474 157.112C933.474 157.112 995.065 237.98 975.531 297.262C954.893 359.978 919.593 430.227 798.283 487.341C793.775 489.475 789.669 492.34 785.181 494.359C542.958 603.172 904.072 665.7 670.838 757.309C663.58 760.178 656.133 762.78 648.678 765.62C415.632 854.397 393.858 1057.31 511.833 1178.62"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M932.313 156.223C932.313 156.223 994.234 237.885 972.586 296.352C949.647 358.088 912.895 427.285 792.618 485.041C788.12 487.195 784.036 490.153 779.569 492.213C540.108 602.343 900.4 666.519 667.751 757.541C660.462 760.4 652.983 762.991 645.466 765.811C412.088 853.69 389.409 1056.31 511.63 1178.99"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M931.163 155.354C931.163 155.354 993.589 237.695 969.621 295.4C944.359 356.166 906.197 424.394 786.963 482.708C782.508 484.997 778.434 487.924 773.968 490.036C537.311 601.565 896.676 667.337 664.642 757.73C657.353 760.589 649.844 763.171 642.264 765.971C408.442 853.036 384.908 1055.16 511.405 1179.31"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M930.003 154.465C930.003 154.465 992.873 237.568 966.635 294.458C939.081 354.214 899.445 421.349 781.308 480.428C776.813 482.738 772.791 485.768 768.346 487.922C534.431 600.83 892.973 668.197 661.545 757.993C654.225 760.842 646.674 763.392 639.052 766.214C404.982 852.338 380.376 1054.15 511.244 1179.76"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M928.874 153.586C928.874 153.586 992.166 237.411 963.671 293.507C933.897 352.24 892.716 418.397 775.612 478.066C771.095 480.386 767.138 483.54 762.683 485.725C531.653 599.939 889.25 668.964 658.427 758.163C651.075 761.001 643.493 763.542 635.809 766.343C401.397 851.601 375.947 1052.99 510.999 1180.09"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M927.712 152.697C927.712 152.697 991.417 237.222 960.704 292.554C928.567 350.339 885.996 415.465 769.977 475.827C765.461 478.147 761.536 481.415 757.102 483.641C528.855 599.161 885.588 669.854 655.36 758.434C647.988 761.284 640.343 763.805 632.628 766.596C397.886 851.007 371.476 1051.89 510.837 1180.54"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M926.563 151.829C926.563 151.829 990.742 237.126 957.719 291.613C923.3 348.407 879.277 412.533 764.313 473.526C759.776 475.857 755.924 479.217 751.501 481.516C526.057 598.28 881.896 670.684 652.273 758.666C644.87 761.505 637.194 764.016 629.417 766.787C394.333 850.331 367.06 1050.91 510.635 1180.9"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M925.445 151.022C925.445 151.022 990.088 237.071 954.796 290.744C918.127 346.557 872.559 409.705 758.626 471.184C754.122 473.629 750.27 476.989 745.868 479.328C523.206 597.347 878.162 671.481 649.133 758.846C641.73 761.685 634.023 764.186 626.183 766.937C390.727 849.552 362.526 1049.69 510.378 1181.17"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M924.273 150.061C924.273 150.061 989.36 236.872 951.705 289.7C912.755 344.523 865.828 406.649 752.825 468.771C748.341 471.257 744.501 474.689 740.12 477.07C520.25 596.313 874.261 672.209 645.91 758.965C638.475 761.794 630.684 764.182 622.835 767.016C387.101 848.888 357.952 1048.6 510.009 1181.56"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M923.113 149.172C923.113 149.172 988.664 236.734 948.813 288.789C907.592 342.59 859.151 403.8 747.232 466.459C742.739 468.976 738.962 472.48 734.581 474.913C517.554 595.327 870.703 672.995 642.874 759.143C635.419 761.983 627.607 764.381 619.685 767.175C383.631 848.221 353.565 1047.52 509.971 1181.9"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M921.953 148.283C921.953 148.283 987.979 236.617 945.849 287.837C902.367 340.689 852.454 400.961 741.557 464.138C737.064 466.707 733.319 470.273 728.96 472.747C514.672 594.332 866.99 673.782 639.788 759.375C632.291 762.184 624.479 764.634 616.473 767.366C380.089 847.566 349.096 1046.43 509.81 1182.24"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M920.802 147.414C920.802 147.414 987.385 236.427 942.924 286.863C897.171 338.745 845.775 398.008 735.922 461.795C731.429 464.416 727.716 468.044 723.378 470.559C511.86 593.275 863.316 674.548 636.719 759.543C629.202 762.363 621.349 764.834 613.291 767.515C376.607 846.878 344.604 1045.35 509.564 1182.63"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M919.663 146.515C919.663 146.515 986.659 236.331 939.897 285.943C891.926 336.854 838.995 395.108 730.205 459.495C725.734 462.157 722.052 465.847 717.757 468.496C509.019 592.207 859.562 675.357 633.581 759.775C626.032 762.585 618.087 765.129 610.028 767.707C373.003 846.255 340.134 1044.26 509.351 1182.97"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M918.513 145.646C918.513 145.646 985.923 236.267 936.932 284.991C886.69 334.932 832.319 392.31 724.53 457.174C720.059 459.887 716.409 463.639 712.061 466.185C506.083 591.058 855.797 676.093 630.421 759.913C622.861 762.703 614.863 765.144 606.784 767.784C369.366 845.466 335.622 1043.09 509.105 1183.25"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M917.352 144.758C917.352 144.758 985.175 236.182 933.967 284.04C881.485 333.021 825.642 389.461 718.854 454.853C714.374 457.598 710.766 461.432 706.481 464.102C503.251 590.012 852.125 676.964 627.323 760.124C619.743 762.924 611.755 765.335 603.572 767.975C365.908 844.976 331.194 1042.08 508.944 1183.7"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M916.202 143.889C916.202 143.889 984.624 236.022 930.981 283.098C876.26 331.119 818.945 386.623 713.095 452.574C708.616 455.37 705.081 459.297 700.776 461.978C500.305 588.894 848.318 677.773 624.174 760.439C616.563 763.229 608.585 765.608 600.298 768.25C362.283 844.363 326.693 1040.98 508.74 1184.07"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M915.063 142.99C915.063 142.99 983.949 235.926 928.058 282.229C871.108 329.311 812.3 383.887 707.524 450.252C703.044 453.1 699.489 456.985 695.258 459.81C497.514 587.722 844.709 678.56 621.17 760.628C613.538 763.429 605.559 765.756 597.179 768.42C358.855 843.727 322.255 1039.95 508.579 1184.46"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M913.912 142.121C913.912 142.121 983.347 235.922 925.05 281.195C865.808 327.265 805.571 380.987 701.869 447.972C697.41 450.861 693.898 454.881 689.656 457.685C494.639 586.541 841.016 679.388 618.092 760.88C610.43 763.67 602.337 766.03 593.977 768.631C355.301 843.051 317.752 1038.8 508.333 1184.8"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M912.752 141.232C912.752 141.232 982.681 235.691 922.147 280.211C860.729 325.393 798.967 378.126 696.233 445.525C691.776 448.467 688.316 452.537 684.075 455.394C491.804 585.184 837.343 680.051 615.004 760.955C607.341 763.746 599.217 766.096 590.795 768.677C351.863 842.395 313.365 1037.66 508.202 1185.1"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M911.602 140.364C911.602 140.364 981.955 235.648 919.1 279.353C855.431 323.607 792.208 375.423 690.537 443.267C686.08 446.26 682.651 450.393 678.442 453.311C488.867 583.983 833.598 680.88 611.886 761.228C604.191 764.009 596.026 766.328 587.552 768.91C348.31 841.823 308.854 1036.65 507.978 1185.53"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M910.462 139.464C910.462 139.464 981.301 235.54 915.979 278.351C850.071 321.645 785.386 372.544 684.654 440.895C680.197 443.94 676.821 448.124 672.613 451.094C485.888 582.699 829.822 681.647 608.715 761.398C601 764.189 592.803 766.498 584.267 769.06C344.706 841.199 304.332 1035.56 507.713 1185.87"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M909.333 138.585C909.333 138.585 980.657 235.454 913.138 277.335C844.98 319.7 778.897 369.755 679.102 438.51C674.667 441.597 671.302 445.853 667.115 448.864C482.939 581.27 826.138 682.289 605.596 761.515C597.85 764.296 589.622 766.596 581.034 769.158C341.163 840.492 299.83 1034.35 507.488 1186.14"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M908.152 137.707C908.152 137.707 979.931 235.411 910.194 276.477C839.766 317.872 772.221 367.01 673.459 436.303C669.013 439.421 665.68 443.739 661.514 446.791C480.022 580.007 822.374 683.181 602.52 761.819C594.743 764.59 586.452 766.869 577.718 769.35C337.652 839.847 295.246 1033.29 507.191 1186.53"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M906.991 136.818C906.991 136.818 979.339 235.375 907.207 275.535C834.582 316.053 765.555 364.232 667.782 433.981C663.337 437.151 660.057 441.52 655.891 444.624C477.113 578.608 818.713 684.071 599.411 762.009C591.613 764.79 583.291 767.059 574.609 769.54C334.161 839.294 290.879 1032.2 507.101 1186.91"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M905.862 135.939C905.862 135.939 978.643 235.238 904.242 274.583C829.368 314.172 758.9 361.476 662.107 431.66C657.662 434.881 654.403 439.292 650.269 442.458C474.103 577.263 814.936 684.735 596.293 762.23C588.484 764.991 580.131 767.25 571.491 769.761C330.662 838.773 286.514 1031.16 506.992 1187.31"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M904.702 135.049C904.702 135.049 977.979 235.162 901.278 273.631C824.164 312.312 752.142 358.773 656.431 429.338C651.988 432.612 648.781 437.073 644.647 440.291C471.122 575.824 811.265 685.605 593.185 762.42C585.356 765.191 576.972 767.44 568.218 770.036C327.142 838.263 281.993 1030.17 506.739 1187.78"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M903.551 134.181C903.551 134.181 977.19 235.15 898.291 272.69C818.928 310.442 745.6 355.984 650.755 427.017C646.333 430.332 643.136 434.866 639.077 438.228C468.12 574.395 807.549 686.237 590.086 762.63C582.226 765.392 573.8 767.61 564.942 770.103C323.557 837.577 277.449 1028.99 506.461 1188.05"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M902.391 133.292C902.391 133.292 976.64 235.042 895.327 271.738C814.013 308.433 738.956 353.249 645.08 424.696C640.658 428.062 637.514 432.648 633.382 435.917C465.087 572.853 803.711 686.985 586.947 762.758C579.066 765.531 570.588 767.749 561.688 770.212C320.014 836.921 272.947 1027.84 506.226 1188.35"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M901.262 132.413C901.262 132.413 975.955 234.978 892.361 270.786C808.767 306.595 732.29 350.42 639.404 422.375C634.983 425.793 631.86 430.42 627.791 433.813C462.096 571.446 799.987 687.804 583.881 763.031C575.969 765.793 567.46 768.002 558.497 770.444C316.526 836.473 268.509 1026.81 506.054 1188.78"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M900.101 131.524C900.101 131.524 975.29 234.902 889.407 269.907C803.523 304.911 725.73 347.85 633.76 420.115C629.339 423.585 626.248 428.274 622.2 431.708C459.104 569.986 796.336 688.611 580.783 763.294C572.871 766.056 564.331 768.255 555.306 770.677C313.036 835.869 264.112 1025.76 505.883 1189.21"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M898.993 130.739C898.993 130.739 974.658 234.94 886.452 268.975C798.246 303.011 719.107 345.155 628.094 417.815C623.695 421.326 620.636 426.076 616.588 429.563C455.998 568.507 792.622 689.399 577.696 763.525C569.742 766.256 561.17 768.445 552.114 770.857C309.472 835.225 259.466 1024.73 505.698 1189.46"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M897.791 129.767C897.791 129.767 973.931 234.793 883.455 267.962C792.98 301.13 712.451 342.347 622.376 415.411C617.977 418.974 614.94 423.765 610.924 427.314C452.953 566.841 788.845 690.063 574.556 763.653C566.571 766.374 557.968 768.553 548.849 770.945C305.972 834.652 255.057 1023.51 505.433 1189.86"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M896.559 128.941C896.559 128.941 973.277 234.738 880.47 267.021C787.662 299.303 705.828 339.653 616.68 413.1C612.261 416.726 609.276 421.569 605.271 425.189C449.836 565.29 785.08 690.851 571.406 763.864C563.401 766.596 554.746 768.776 545.586 771.137C302.441 834.069 250.495 1022.49 505.262 1190.28"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M895.501 127.999C895.501 127.999 972.592 234.673 877.504 266.068C782.416 297.464 699.225 336.948 611.056 410.778C606.638 414.455 603.684 419.36 599.701 423.022C446.771 563.685 781.397 691.596 568.36 764.074C560.323 766.796 551.638 768.965 542.415 771.307C298.93 833.527 246.056 1021.41 505.017 1190.62"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M894.352 127.13C894.352 127.13 971.918 234.628 874.539 265.116C777.161 295.604 692.613 334.274 605.37 408.436C600.973 412.154 598.02 417.111 594.068 420.835C443.643 562.061 777.651 692.322 565.221 764.253C557.184 766.976 548.457 769.114 539.182 771.456C295.42 832.934 241.554 1020.31 504.823 1190.95"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M893.191 126.242C893.191 126.242 971.275 234.646 871.553 264.175C771.831 293.705 686.01 331.569 599.694 406.115C595.297 409.885 592.397 414.893 588.446 418.669C440.515 560.438 773.927 693.089 562.123 764.465C554.055 767.177 545.296 769.306 535.959 771.627C291.899 832.372 237.167 1019.18 504.62 1191.32"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M892.092 125.321C892.092 125.321 970.62 234.487 868.65 263.191C766.68 291.895 679.48 328.853 594.08 403.762C589.695 407.604 586.836 412.695 582.886 416.47C437.438 558.761 770.254 693.803 559.077 764.622C550.988 767.345 542.198 769.464 532.798 771.765C288.388 831.83 232.614 1018.13 504.406 1191.66"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M890.901 124.474C890.901 124.474 969.884 234.475 865.623 262.271C761.362 290.068 672.836 326.221 588.281 401.504C583.896 405.399 581.016 410.448 577.139 414.368C434.154 557.139 766.395 694.613 555.855 764.897C547.755 767.599 538.893 769.729 529.452 772C284.805 831.3 228.144 1017.04 504.14 1192.06"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M889.74 123.585C889.74 123.585 969.178 234.42 862.69 261.433C756.201 288.445 666.297 323.64 582.72 399.254C578.356 403.19 575.519 408.321 571.632 412.272C431.068 555.546 762.775 695.431 552.861 765.158C544.741 767.87 535.848 769.99 526.344 772.241C281.357 830.726 223.673 1015.95 503.999 1192.39"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M888.591 122.717C888.591 122.717 968.504 234.376 859.672 260.378C750.84 286.38 659.683 320.863 576.991 396.83C572.628 400.817 569.822 406.011 565.967 410.024C427.804 553.758 758.988 696.074 549.711 765.265C541.56 767.968 532.624 770.057 523.079 772.33C277.889 830.267 219.266 1014.82 503.795 1192.76"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M887.43 121.827C887.43 121.827 967.798 234.27 856.686 259.436C745.574 284.603 653.112 318.219 571.315 394.508C566.963 398.568 564.168 403.782 560.387 407.939C424.644 552.123 755.285 696.882 546.624 765.548C538.473 768.251 529.506 770.33 519.899 772.582C274.326 829.622 214.765 1013.83 503.592 1193.12"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M886.301 120.948C886.301 120.948 967.124 234.277 853.659 258.516C740.193 282.756 646.437 315.473 565.577 392.219C561.226 396.33 558.473 401.575 554.629 405.66C421.255 550.295 751.476 697.484 543.39 765.646C535.197 768.318 526.189 770.418 516.54 772.64C270.722 829.05 210.2 1012.6 503.357 1193.42"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M885.141 120.06C885.141 120.06 966.45 234.233 850.777 257.574C735.104 280.915 639.992 312.923 559.975 389.886C555.644 394.039 552.903 399.357 549.112 403.545C418.096 548.61 747.794 698.282 540.387 765.887C532.162 768.549 523.123 770.639 513.412 772.841C267.306 828.539 205.783 1011.56 503.228 1193.93"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M883.991 119.191C883.991 119.191 965.744 234.179 847.739 256.633C729.734 279.088 633.39 310.269 554.247 387.566C549.917 391.771 547.197 397.129 543.438 401.379C414.862 546.831 744.018 699.049 537.237 766.098C528.992 768.771 519.922 770.851 510.147 773.033C263.795 827.998 201.354 1010.45 502.93 1194.22"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M882.788 118.219C882.788 118.219 965.028 234.052 844.773 255.578C724.519 277.103 626.881 307.594 548.571 385.141C544.252 389.418 541.563 394.786 537.815 399.109C411.484 545.023 740.272 699.671 534.138 766.205C525.862 768.867 516.761 770.938 506.924 773.099C260.286 827.456 196.844 1009.38 502.769 1194.56"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M881.701 117.423C881.701 117.423 964.364 234.08 841.85 254.708C719.336 275.337 620.341 304.961 542.834 382.955C538.536 387.273 535.858 392.714 532.142 397.099C408.199 543.35 736.661 700.458 531.051 766.488C522.775 769.151 513.632 771.19 503.743 773.352C256.765 826.946 192.393 1008.28 502.564 1194.93"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M880.541 116.534C880.541 116.534 963.69 234.036 838.947 253.725C714.204 273.414 613.874 302.265 537.344 380.538C533.058 384.929 530.39 390.39 526.706 394.837C404.945 541.53 732.937 701.173 528.079 766.739C519.772 769.391 510.576 771.431 500.646 773.563C253.38 826.444 187.986 1007.16 502.487 1195.33"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M879.328 115.697C879.328 115.697 962.922 234.013 835.837 252.847C708.752 271.681 607.106 299.749 531.523 378.291C527.237 382.734 524.591 388.236 520.938 392.745C401.463 539.827 729.046 701.972 524.783 766.92C516.455 769.583 507.228 771.614 497.256 773.714C249.693 825.915 183.37 1006.14 502.095 1195.69"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M878.23 114.777C878.23 114.777 962.31 233.937 832.934 251.863C703.558 269.789 600.711 297.041 525.909 375.938C521.645 380.422 518.999 385.976 515.378 390.546C398.136 538.019 725.291 702.729 521.747 767.099C512.657 769.826 503.435 772.094 494.117 773.894C246.256 825.414 178.973 1005.04 501.976 1196.06"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M877.091 113.877C877.091 113.877 961.604 233.883 829.948 250.922C698.292 267.961 594.244 294.397 520.234 373.565C515.98 378.121 513.365 383.686 509.756 388.328C394.737 536.17 721.628 703.361 518.639 767.237C509.491 769.96 500.21 772.215 490.832 773.993C242.787 824.851 174.482 1003.91 501.72 1196.38"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M875.94 113.009C875.94 113.009 960.888 233.859 826.983 249.97C693.077 266.081 587.787 291.722 514.6 371.378C510.368 375.976 507.754 381.592 504.176 386.296C391.379 534.507 717.946 704.21 515.583 767.582C506.421 770.284 497.126 772.512 487.734 774.256C239.204 824.321 170.064 1002.92 501.599 1196.7"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M874.862 112.077C874.862 112.077 960.285 233.751 824.099 248.975C687.914 264.199 581.278 289.098 508.964 368.931C504.786 373.684 502.14 379.238 498.594 384.004C387.999 532.595 714.24 704.81 512.515 767.698C503.293 770.376 493.939 772.573 484.489 774.28C235.776 823.736 165.582 1001.65 501.374 1197.02"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M873.63 111.252C873.63 111.252 959.582 233.853 821.032 248.077C682.481 262.3 574.708 286.508 503.207 366.653C499.007 371.416 496.414 377.074 492.818 381.944C384.29 530.906 710.33 705.622 509.232 767.953C499.985 770.652 490.602 772.863 481.123 774.578C232.195 823.31 161.072 1000.63 501.11 1197.47"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M872.543 110.455C872.543 110.455 958.876 233.799 818.13 247.248C677.383 260.698 568.252 283.987 497.584 364.434C493.384 369.198 490.813 374.948 487.269 379.87C380.974 529.17 706.679 706.429 506.207 768.256C496.934 770.974 487.524 773.199 478.016 774.923C228.695 822.789 156.581 999.553 500.863 1197.75"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M871.34 109.483C871.34 109.483 958.169 233.64 815.111 246.193C672.053 258.746 561.722 281.271 491.855 362.01C487.635 366.784 485.106 372.617 481.656 377.672C377.501 527.332 703.026 707.081 503.109 768.414C493.787 771.121 484.323 773.312 474.761 774.979C225.196 822.423 152.111 998.566 500.703 1198.3"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M870.18 108.594C870.18 108.594 957.423 233.607 812.126 245.252C666.829 256.897 555.256 278.679 486.179 359.689C482.023 364.586 479.452 370.388 475.981 375.351C374.04 525.515 699.144 707.642 500.01 768.522C490.648 771.217 481.147 773.402 471.548 775.067C221.675 821.809 147.631 997.403 500.499 1198.57"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M869.03 107.726C869.03 107.726 956.728 233.574 809.161 244.3C661.594 255.026 548.757 276.024 480.504 357.367C476.348 362.316 473.788 368.14 470.411 373.236C370.432 523.616 695.441 708.399 496.881 768.722C487.489 771.412 477.956 773.587 468.326 775.237C218.145 821.278 143.181 996.25 500.285 1198.91"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M867.89 106.826C867.89 106.826 956.022 233.519 806.196 243.348C656.369 253.177 542.28 273.411 474.828 355.046C470.704 360.057 468.134 365.911 464.809 371.059C366.898 521.81 691.634 709.208 493.752 768.871C484.303 771.591 474.711 773.787 465.02 775.45C214.603 820.727 138.608 995.264 499.999 1199.32"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M866.739 105.958C866.739 105.958 955.316 233.465 803.209 242.407C651.102 251.349 535.823 270.788 469.151 352.725C465.028 357.788 462.479 363.683 459.218 368.902C363.383 519.994 688.064 709.921 490.716 769.101C481.248 771.799 471.634 773.954 461.921 775.557C211.207 820.256 134.219 994.079 499.94 1199.61"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M865.58 105.069C865.58 105.069 954.622 233.431 800.142 241.508C645.661 249.585 529.264 268.217 463.373 350.456C459.282 355.581 456.723 361.508 453.514 366.778C359.716 518.231 684.195 710.607 487.536 769.354C478.027 772.045 468.371 774.187 458.617 775.77C207.574 819.83 129.729 992.999 499.634 1200.03"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M864.43 104.201C864.43 104.201 953.936 233.367 797.259 240.514C640.581 247.661 522.91 265.541 457.801 348.082C453.709 353.259 451.203 359.289 448.015 364.549C356.232 516.372 680.583 711.238 484.562 769.502C475.012 772.185 465.314 774.314 455.518 775.877C204.147 819.298 125.321 991.877 499.554 1200.33"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M863.29 103.301C863.29 103.301 953.242 233.333 794.376 239.52C635.511 245.706 516.536 262.875 452.208 345.719C448.148 350.957 445.569 356.946 442.393 362.382C352.605 514.588 676.755 712.006 481.433 769.65C471.853 772.347 462.124 774.484 452.295 776.048C200.596 818.83 120.851 990.786 499.34 1200.67"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M862.088 102.433C862.088 102.433 952.494 233.299 791.308 238.62C630.121 243.941 509.976 260.252 446.387 343.471C442.755 348.856 439.487 354.479 436.606 360.3C348.781 512.878 672.916 712.753 478.17 769.997C468.57 772.646 458.823 774.731 448.979 776.24C197.086 818.34 116.36 989.706 499.054 1201.08"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M860.98 101.544C860.98 101.544 951.831 233.224 788.343 237.669C624.855 242.113 503.572 257.68 440.794 341.108C437.161 346.536 433.91 352.211 431.066 358.092C345.216 511.063 669.264 713.406 475.145 770.145C465.488 772.83 455.677 774.929 445.768 776.431C193.577 817.85 111.765 988.575 498.809 1201.42"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M859.819 100.655C859.819 100.655 951.135 233.19 785.388 236.737C619.64 240.284 497.146 255.066 435.118 338.786C431.494 344.271 428.254 350.002 425.423 355.935C341.527 509.31 665.539 714.12 472.047 770.355C462.353 773.021 452.508 775.103 442.565 776.591C190.046 817.37 107.327 987.546 498.648 1201.81"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M858.689 99.776C858.689 99.776 950.429 233.136 782.422 235.785C614.415 238.435 490.71 252.432 429.4 336.383C425.78 341.921 422.562 347.71 419.769 353.708C337.848 507.63 661.783 714.826 468.938 770.545C459.209 773.222 449.326 775.298 439.342 776.761C186.557 816.87 102.836 986.466 498.444 1202.18"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M857.53 98.8866C857.53 98.8866 949.724 233.081 779.437 234.844C609.15 236.606 484.285 249.818 423.85 334.153C420.238 339.74 417.037 345.582 414.273 351.633C334.19 505.784 658.131 715.529 465.882 770.683C456.11 773.344 446.184 775.407 436.161 776.859C183.038 816.463 98.3665 985.375 498.241 1202.54"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M856.337 97.9359C856.337 97.9359 948.986 232.965 776.429 233.809C603.872 234.654 477.837 247.111 418.049 331.74C414.438 337.372 411.255 343.267 408.525 349.374C330.354 503.95 654.29 716.121 462.669 770.874C452.869 773.54 442.911 775.593 432.855 777.02C179.465 815.85 93.8537 984.202 497.984 1202.8"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M855.219 97.1294C855.219 97.1294 948.317 233.335 773.496 232.919C598.983 233.153 471.464 244.549 412.405 329.48C408.815 335.191 405.663 341.166 402.977 347.352C326.666 502.353 650.588 716.981 459.646 771.229C449.803 773.888 439.804 775.927 429.706 777.334C176.019 815.536 89.4575 983.255 497.855 1203.31"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M854.09 96.2504C854.09 96.2504 947.579 233.219 770.521 231.999C593.655 231.357 465.07 241.945 406.74 327.18C403.141 332.892 399.982 338.87 397.291 345.062C322.873 500.55 646.789 717.552 456.484 771.316C446.601 773.97 436.56 775.996 426.42 777.381C172.55 814.973 84.935 982.113 497.62 1203.62"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M852.929 95.3614C852.929 95.3614 946.915 233.195 767.555 231.047C588.388 229.477 458.665 239.321 401.064 324.858C397.466 330.633 394.335 336.685 391.7 342.958C319.08 498.85 643.054 718.299 453.408 771.567C443.489 774.233 433.409 776.253 423.229 777.614C168.926 814.464 80.4649 981.023 497.416 1203.98"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M851.779 94.4931C851.779 94.4931 946.2 233.12 764.59 230.095C583.132 227.617 452.281 236.686 395.388 322.537C391.802 328.369 388.692 334.48 386.089 340.812C315.205 497.09 639.319 718.993 450.31 771.778C440.348 774.426 430.226 776.432 420.007 777.784C165.438 814.067 75.9951 979.932 497.193 1204.36"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M850.618 93.6039C850.618 93.6039 945.482 233.045 761.604 229.153C577.896 225.747 445.876 234.062 389.712 320.215C386.127 326.093 383.034 332.258 380.466 338.645C311.475 495.462 635.583 719.687 447.201 771.968C437.204 774.629 427.043 776.629 416.784 777.954C161.938 813.598 71.4824 978.81 496.988 1204.72"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M849.49 92.725C849.49 92.725 944.788 232.96 758.639 228.202C572.61 223.878 439.45 231.345 384.037 317.894C380.462 323.823 377.387 330.039 374.845 336.479C307.642 493.784 631.817 720.372 444.084 772.189C434.051 774.836 423.855 776.819 413.562 778.125C158.42 813.139 67.0028 977.699 496.775 1205.07"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M848.329 91.8361C848.329 91.8361 944.061 232.864 755.674 227.25C567.374 222.007 433.242 228.75 378.361 315.573C374.787 321.548 371.729 327.818 369.222 334.313C303.798 492.137 628.082 721.066 440.975 772.379C430.908 775.031 420.677 777.014 410.349 778.316C154.899 812.681 62.5534 976.65 496.572 1205.43"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M847.168 90.9471C847.168 90.9471 943.386 232.82 752.698 226.329C562.011 219.838 426.796 226.147 372.685 313.252C369.112 319.274 366.072 325.596 363.6 332.146C299.943 490.469 624.346 721.761 437.877 772.59C427.74 775.241 417.44 777.225 407.044 778.529C151.297 812.265 58.0006 975.602 496.265 1205.85"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M846.071 90.182C846.071 90.182 942.805 232.806 749.786 225.48C556.767 218.155 420.424 223.636 367.062 311.034C363.499 317.107 360.477 323.481 358.03 330.083C296.111 488.946 620.653 722.538 434.821 772.883C424.676 775.53 414.365 777.494 403.957 778.76C147.913 811.867 53.6454 974.582 496.218 1206.26"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M844.858 89.1898C844.858 89.1898 942.015 232.638 746.747 224.436C551.479 216.234 413.893 220.868 361.313 308.62C357.751 314.74 354.746 321.167 352.335 327.824C292.153 487.228 616.758 722.82 431.65 773.001C421.487 775.612 411.162 777.548 400.743 778.796C144.34 811.305 49.1224 973.388 495.961 1206.53"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M843.729 88.3106C843.729 88.3106 941.31 232.584 743.761 223.494C546.213 214.405 407.664 218.231 355.658 306.288C352.1 312.462 349.116 318.949 346.743 325.667C288.196 485.665 613.054 723.576 428.552 773.211C418.33 775.839 407.942 777.773 397.459 778.998C140.867 811.292 44.6524 972.297 495.758 1206.89"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M842.569 87.422C842.569 87.422 940.563 232.499 740.818 222.532C541.072 212.565 401.312 215.607 349.983 303.967C346.426 310.188 343.46 316.728 341.122 323.501C284.364 484.039 609.32 724.322 425.445 773.401C415.187 776.04 404.76 777.968 394.237 779.169C137.336 810.709 40.1831 971.207 495.545 1207.24"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M841.418 86.5535C841.418 86.5535 939.836 232.455 737.831 221.591C535.826 210.726 394.969 212.951 344.306 301.645C340.759 307.918 337.81 314.51 335.499 321.335C280.448 482.506 605.513 725.131 422.346 773.612C412.039 776.23 401.563 778.13 390.992 779.298C133.772 810.064 35.6598 970.065 495.319 1207.56"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M840.278 85.6538C840.278 85.6538 939.12 232.38 734.845 220.597C530.569 208.815 388.604 210.253 338.609 299.283C244.01 467.744 645.32 749.445 387.812 779.499C130.303 809.553 31.2108 969.015 495.137 1207.97"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
      </svg>
    );
  }

  if (name === 'contact1') {
    return (
      <svg
        width="700"
        height="864"
        viewBox="0 0 700 864"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-226.956 319.356C-226.956 319.356 -203.516 260.631 -162.757 221.388C-119.262 179.239 -56.8041 153.279 20.4892 227.969C22.9645 230.373 25.5993 232.904 28.1442 235.539C181.714 395.121 73.5763 140.178 225.901 260.059C230.18 263.432 234.447 266.681 238.702 269.806C386.515 377.357 522.442 307.662 568.965 247.702"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-227.116 320.414C-227.116 320.414 -203.231 261.211 -162.305 223.636C-118.49 183.381 -56.466 158.705 20.9775 232.472C23.4623 234.862 26.1585 237.333 28.7129 239.954C182.548 397.136 75.3086 142.409 227.102 262.014C231.386 265.41 235.646 268.689 239.922 271.855C387.058 380.26 523.326 310.938 569.327 247.742"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-227.252 321.407C-227.252 321.407 -202.955 261.683 -161.839 225.832C-117.788 187.414 -56.0711 164.045 21.5795 236.807C24.1536 239.216 26.822 241.606 29.4332 244.142C183.401 399.061 77.1832 144.428 228.313 263.954C232.611 267.361 236.881 270.686 241.161 273.876C387.827 382.886 524.168 314.247 569.539 247.761"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-227.441 322.508C-227.441 322.508 -202.76 262.244 -161.431 228.113C-117.119 191.528 -55.7856 169.579 22.0672 241.371C24.6603 243.752 27.3712 246.11 29.935 248.717C184.216 401.103 78.9529 146.664 229.595 265.882C233.883 269.302 238.157 272.651 242.446 275.888C388.602 385.536 525.086 317.505 569.883 247.768"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-227.601 323.567C-227.601 323.567 -202.499 262.707 -160.98 230.361C-116.445 195.604 -55.4337 175.014 22.5554 245.814C25.1721 248.19 27.9209 250.492 30.5321 253.028C185.017 403.014 81.0595 148.308 230.843 267.706C235.136 271.149 239.406 274.536 243.723 277.792C389.4 388.059 525.999 320.679 570.208 247.68"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-227.751 324.611C-227.751 324.611 -202.313 263.282 -160.533 232.585C-115.757 199.689 -55.0721 180.435 23.0862 250.284C25.7359 252.642 28.5227 254.887 31.1529 257.395C185.809 405 82.9338 150.388 232.059 269.67C236.366 273.123 240.64 276.534 244.966 279.836C390.17 390.685 526.874 323.969 570.5 247.733"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-227.926 325.66C-227.926 325.66 -202.099 263.816 -160.096 234.824C-115.084 203.765 -54.7302 185.885 23.588 254.736C26.2472 257.08 29.086 259.278 31.7351 261.758C186.557 406.958 84.7555 152.455 233.274 271.573C237.595 275.036 241.855 278.498 246.2 281.834C390.93 393.265 527.739 327.213 570.792 247.725"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-228.077 326.704C-228.077 326.704 -201.875 264.335 -159.645 237.072C-114.411 207.841 -54.3641 191.329 24.0483 259.159C26.7405 261.484 29.6078 263.64 32.2759 266.092C187.246 408.915 86.5452 154.479 234.438 273.462C238.773 276.934 243.052 280.43 247.392 283.803C391.761 395.891 528.619 330.466 571.098 247.727"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-228.237 327.763C-228.237 327.763 -201.661 264.868 -159.208 239.31C-113.732 211.94 -53.9364 196.651 24.621 263.658C27.3227 265.969 30.2515 268.064 32.9291 270.502C188.065 410.981 88.4379 156.592 235.724 275.413C240.064 278.908 244.337 282.442 248.696 285.848C392.493 398.451 529.485 333.77 571.39 247.719"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-228.383 328.831C-228.383 328.831 -201.443 265.424 -158.766 241.572C-113.064 216.054 -53.5518 202.129 25.1419 268.143C27.8767 270.435 30.8338 272.488 33.5304 274.898C188.729 412.943 90.2692 158.706 236.958 277.349C241.298 280.845 245.576 284.402 249.944 287.855C393.277 401.025 530.383 336.995 571.738 247.748"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-228.562 329.857C-228.562 329.857 -201.233 265.934 -158.319 243.797C-112.395 220.106 -53.1143 207.437 25.654 272.581C28.4078 274.845 31.4075 276.865 34.123 279.246C189.412 414.938 92.1343 160.739 238.188 279.262C242.532 282.781 246.806 286.376 251.193 289.862C394.061 403.6 531.263 340.248 571.978 247.787"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-228.713 330.901C-228.713 330.901 -201.033 266.457 -157.882 246.035C-111.745 224.187 -52.7532 212.919 26.1705 277.042C28.9478 279.301 31.9854 281.265 34.7294 283.605C190.115 416.905 93.9706 162.815 239.408 281.189C243.767 284.718 248.045 288.336 252.441 291.869C394.836 406.189 532.157 343.51 572.313 247.747"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-228.873 331.96C-228.873 331.96 -200.814 266.953 -157.445 248.274C-111.109 228.319 -52.3494 218.307 26.6868 281.503C29.4971 283.744 32.5727 285.652 35.3262 287.977C190.722 418.951 95.7595 164.961 240.685 283.031C245.048 286.584 249.335 290.249 253.708 293.848C395.658 408.707 533.056 346.735 572.637 247.72"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.024 333.004C-229.024 333.004 -200.642 267.518 -157.074 250.488C-110.393 232.301 -51.9314 223.705 27.2033 285.964C30.0326 288.177 33.1886 289.995 35.9231 292.349C191.377 420.988 97.6433 166.967 241.868 285.014C246.235 288.59 250.527 292.279 255.002 295.907C396.498 411.319 533.983 350.04 572.99 247.773"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.198 334.054C-229.198 334.054 -200.451 268.057 -156.557 252.761C-109.79 236.452 -51.4423 229.028 27.7199 290.426C30.5727 292.634 33.7193 294.467 36.5201 296.721C191.989 422.997 99.5128 169.024 243.102 286.951C247.47 290.527 251.756 294.253 256.237 297.844C397.217 413.748 534.849 353.223 573.283 247.705"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.349 335.098C-229.349 335.098 -200.261 268.594 -156.12 254.999C-109.15 240.546 -51.0624 234.482 28.1981 294.943C31.084 297.132 34.2544 298.899 37.0788 301.149C192.544 425.09 101.283 171.198 244.293 288.92C248.666 292.519 252.957 296.269 257.352 299.925C397.934 416.42 535.624 356.569 573.47 247.79"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.509 336.156C-229.509 336.156 -200.074 269.108 -155.616 257.261C-108.513 244.618 -50.592 239.833 28.7245 299.39C31.6199 301.566 34.8423 303.286 37.7236 305.451C193.247 427.118 103.167 173.204 245.562 290.777C249.948 294.386 254.234 298.173 258.677 301.881C398.87 418.953 536.58 359.771 573.853 247.74"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.669 337.215C-229.669 337.215 -199.898 269.636 -155.193 259.429C-107.844 248.67 -50.1408 245.211 29.2505 303.837C32.179 305.994 35.4393 307.657 38.2637 309.908C193.666 429.201 104.937 175.378 246.753 292.745C251.144 296.378 255.449 300.198 259.887 303.944C399.621 421.546 537.37 363.065 574.069 247.783"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.834 338.25C-229.834 338.25 -199.721 270.164 -154.789 261.748C-107.265 252.825 -49.7279 250.647 29.7763 308.285C32.7378 310.422 36.0265 312.044 38.9078 314.21C194.302 431.144 106.811 177.398 247.992 294.644C252.397 298.286 256.698 302.144 261.154 305.923C400.452 424.051 538.311 366.319 574.446 247.771"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-229.994 339.309C-229.994 339.309 -199.489 270.73 -154.333 263.958C-106.624 256.859 -49.2484 255.984 30.3022 312.732C33.2732 314.855 36.6139 316.43 39.5142 318.568C194.777 433.265 108.633 179.464 249.232 296.543C253.641 300.209 257.946 304.09 262.411 307.916C401.251 426.573 539.243 369.525 574.752 247.773"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-230.145 340.353C-230.145 340.353 -199.383 271.211 -153.897 266.197C-105.989 260.93 -48.7362 261.302 30.8042 317.184C33.8462 319.232 37.1634 320.812 40.0967 322.93C195.266 435.334 110.474 181.563 250.451 298.47C254.861 302.136 259.171 306.041 263.645 309.914C402.119 429.144 540.094 372.82 575.039 247.802"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-230.305 341.412C-230.305 341.412 -199.21 271.716 -153.445 268.444C-105.376 265.005 -48.3037 266.709 31.3401 321.617C34.4102 323.684 37.7748 325.193 40.7271 327.284C195.813 437.441 112.334 183.635 251.714 300.364C256.129 304.053 260.433 307.996 264.936 311.888C402.895 431.61 541.05 376.083 575.383 247.748"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-230.47 342.446C-230.47 342.446 -199.062 272.225 -153.008 270.683C-104.81 269.029 -47.8196 272.069 31.8375 326.106C34.9217 328.183 38.3432 329.607 41.3191 331.693C196.222 439.538 114.142 185.752 252.925 302.305C257.344 306.017 261.653 309.984 266.165 313.923C403.688 434.17 541.868 379.335 575.684 247.786"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-230.63 343.505C-230.63 343.505 -198.919 272.772 -152.571 272.922C-104.147 273.119 -47.3264 277.415 32.3916 330.511C35.4758 332.588 38.9588 333.951 41.9536 336.009C196.712 441.608 116.007 187.786 254.183 304.176C258.616 307.898 262.934 311.911 267.451 315.874C404.548 436.632 542.748 382.588 576.028 247.732"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-230.781 344.55C-230.781 344.55 -198.742 273.239 -152.12 275.17C-103.525 277.18 -46.8327 282.762 32.9176 334.958C36.0159 337.045 39.5323 338.328 42.5696 340.353C197.135 443.715 117.843 189.862 255.422 306.074C259.86 309.82 264.183 313.857 268.709 317.867C405.37 439.15 543.675 385.771 576.334 247.733"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-230.903 345.552C-230.903 345.552 -198.546 273.678 -151.644 277.352C-102.883 281.152 -46.2728 288.07 33.4248 339.433C36.5846 341.459 40.1009 342.742 43.1572 344.739C197.545 445.873 119.675 191.975 256.647 308.025C261.085 311.77 265.412 315.831 269.947 319.888C406.145 441.739 544.498 389.108 576.593 247.805"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-231.106 346.643C-231.106 346.643 -198.417 274.215 -151.222 279.703C-102.29 285.317 -45.8548 293.468 33.9266 344.007C37.1054 346.005 40.6643 347.256 43.7395 349.224C197.925 448.134 121.52 194.221 257.862 310.05C262.305 313.82 266.589 317.974 271.181 322.008C406.999 444.299 545.416 392.427 576.979 247.901"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-231.266 347.702C-231.266 347.702 -198.259 274.71 -150.785 281.88C-101.706 289.313 -45.3233 298.758 34.4198 348.412C37.6221 350.405 41.2048 351.59 44.3131 353.54C198.245 450.212 123.281 196.226 259.05 311.935C263.506 315.714 267.805 319.878 272.396 323.973C407.798 446.761 546.272 395.623 577.205 247.808"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-231.426 348.76C-231.426 348.76 -198.092 275.191 -150.347 284.119C-101.079 293.337 -44.7777 304.057 34.9362 352.873C38.1716 354.847 41.7827 355.99 44.91 357.912C198.626 452.413 125.099 198.329 260.289 313.833C264.74 317.65 269.072 321.795 273.654 325.965C408.629 449.265 547.152 398.876 577.483 247.79"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-231.577 349.805C-231.577 349.805 -198.004 275.639 -149.939 286.339C-100.49 297.356 -44.3125 309.385 35.4243 357.315C38.6928 359.271 42.3323 360.372 45.4786 362.265C198.941 454.59 126.888 200.415 261.481 315.741C265.946 319.567 270.306 323.732 274.873 327.954C409.419 451.741 548.046 402.139 577.817 247.811"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-231.752 350.854C-231.752 350.854 -197.809 276.139 -149.459 288.606C-99.8492 301.389 -43.7765 314.759 35.969 361.796C39.2564 363.723 42.9245 364.781 46.1417 366.6C199.261 456.79 128.734 202.538 262.739 317.673C267.209 321.523 271.649 325.721 276.15 329.98C410.292 454.274 548.926 405.392 578.114 247.827"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-231.902 351.898C-231.902 351.898 -197.59 276.634 -149.022 290.844C-99.2321 305.427 -43.2122 320.031 36.4853 366.257C39.8057 368.165 43.5022 369.182 46.672 371.071C199.561 459.08 130.536 204.693 263.944 319.652C268.405 323.515 272.797 327.784 277.346 332.034C411.071 456.886 549.767 408.701 578.381 247.884"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-232.062 352.957C-232.062 352.957 -197.38 277.144 -148.585 293.082C-98.6191 309.441 -42.6804 325.32 37.002 370.718C40.346 372.622 44.0804 373.582 47.3072 375.387C199.89 461.266 132.392 206.741 265.174 321.565C269.649 325.438 274.018 329.71 278.604 334.026C411.979 459.277 550.685 411.898 578.726 247.829"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-232.213 354.002C-232.213 354.002 -197.288 277.554 -148.134 295.331C-97.9922 313.465 -42.1347 330.62 37.5749 375.217C40.9519 377.103 44.7197 377.982 47.9605 379.797C200.214 463.551 134.256 208.897 266.489 323.473C270.968 327.37 275.314 331.647 279.936 336.029C412.867 461.819 551.57 415.174 579.032 247.831"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-232.387 355.051C-232.387 355.051 -197.111 278.021 -147.659 297.513C-97.3323 317.409 -41.5418 335.849 38.0539 379.612C41.4639 381.479 45.2127 382.387 48.5199 384.103C200.449 465.755 136.036 210.935 267.672 325.334C272.165 329.24 276.477 333.536 281.147 337.97C413.67 464.243 552.478 418.385 579.343 247.795"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-232.538 356.095C-232.538 356.095 -196.901 278.408 -147.26 299.808C-96.772 321.532 -41.0247 341.191 38.5892 384.045C42.0183 385.884 45.838 386.717 49.1357 388.447C200.707 468.017 137.873 213.011 268.92 327.218C273.418 331.148 277.791 335.506 282.414 339.949C414.492 466.761 553.329 421.68 579.644 247.834"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-232.699 357.154C-232.699 357.154 -196.814 278.917 -146.865 302.018C-96.1779 325.452 -40.5261 346.438 39.0113 388.525C42.4734 390.345 46.3075 391.126 49.6383 392.837C200.838 470.315 139.596 215.133 270.06 329.173C274.558 333.103 278.936 337.484 283.568 341.975C415.286 469.199 554.153 424.895 579.884 247.812"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-232.849 358.198C-232.849 358.198 -196.585 279.398 -146.338 304.275C-95.4518 329.481 -39.8718 351.728 39.5753 392.977C43.0704 394.778 46.933 395.517 50.2922 397.186C201.105 472.623 141.451 217.242 271.337 331.077C275.84 335.03 280.213 339.449 284.863 343.972C416.174 471.68 555.094 428.148 580.238 247.804"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.023 359.247C-233.023 359.247 -196.423 279.856 -145.878 306.632C-94.8164 333.613 -39.308 357.122 40.1332 397.589C43.6614 399.372 47.5383 400.059 50.9305 401.709C201.334 474.988 143.277 219.393 272.566 333.051C277.083 337.014 281.461 341.456 286.12 346.027C417.048 474.213 555.992 431.435 580.552 247.853"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.188 360.283C-233.188 360.283 -196.25 280.299 -145.525 308.814C-94.2648 337.566 -38.8042 362.271 40.5651 401.993C44.1123 403.748 48.0317 404.403 51.4429 406.025C201.46 477.385 144.991 221.53 273.744 335.011C278.265 338.997 282.648 343.463 287.326 348.067C417.846 476.736 556.811 434.749 580.807 247.901"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.334 361.35C-233.334 361.35 -196.022 280.78 -145.036 311.005C-93.5952 341.557 -38.2063 367.523 41.1433 406.393C44.714 408.143 48.6619 408.756 52.092 410.35C201.68 479.703 146.893 223.629 275.026 336.876C279.552 340.886 283.943 345.399 288.621 350.125C418.673 479.216 557.752 438.063 581.16 247.954"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.495 362.409C-233.495 362.409 -195.835 281.172 -144.585 313.253C-92.9306 345.525 -37.6324 372.781 41.6597 410.855C45.2634 412.586 49.2257 413.147 52.6889 414.722C201.811 482.062 148.758 225.663 276.246 338.803C280.786 342.823 285.182 347.359 289.841 352.052C419.552 481.65 558.594 441.25 581.438 247.876"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.659 363.444C-233.659 363.444 -195.677 281.668 -144.148 315.492C-92.2944 349.535 -37.0488 378.024 42.1762 415.316C45.813 417.029 49.7942 417.561 53.2858 419.094C202.012 484.469 150.519 227.851 277.49 340.726C282.02 344.759 286.421 349.319 291.085 353.975C420.455 484.079 559.47 444.418 581.73 247.806"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.82 364.503C-233.82 364.503 -195.491 282.12 -143.711 317.73C-91.649 353.53 -36.3952 383.314 42.6922 419.777C46.3621 421.471 50.3577 421.952 53.8824 423.466C202.142 486.89 152.374 229.899 278.71 342.653C283.254 346.696 287.66 351.279 292.418 355.978C421.371 486.517 560.434 447.667 582.121 247.804"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-233.97 365.547C-233.97 365.547 -195.22 282.63 -143.26 319.978C-90.9984 357.549 -35.8724 388.496 43.209 424.238C46.8978 425.904 50.9359 426.352 54.5269 427.768C202.286 489.32 154.092 232.059 279.94 344.565C284.489 348.632 288.889 353.253 293.619 358.055C422.198 489.059 561.281 450.999 582.394 247.885"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-234.131 366.606C-234.131 366.606 -195.095 283.021 -142.822 322.217C-90.5499 361.412 -35.2794 393.725 43.7254 428.699C47.4473 430.346 51.4997 430.743 55.0575 432.238C202.369 491.811 155.928 234.257 281.132 346.534C285.695 350.61 290.114 355.265 294.838 360.104C423.029 491.563 562.132 454.295 582.671 247.929"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-234.295 367.641C-234.295 367.641 -194.895 283.484 -142.385 324.456C-89.8759 365.427 -34.7383 399.001 44.2419 433.161C47.9968 434.789 52.0683 435.157 55.6829 436.569C202.523 494.227 157.769 236.357 282.39 348.405C286.957 352.505 291.381 357.183 296.115 362.069C423.974 493.96 563.04 457.505 583.006 247.888"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-234.456 368.699C-234.456 368.699 -194.708 283.937 -141.906 326.661C-89.103 369.386 -34.0696 404.117 44.7867 437.58C48.5747 439.189 52.6745 439.515 56.3081 440.898C202.643 496.662 159.577 238.413 283.652 350.299C288.22 354.398 292.648 359.1 297.391 364.034C424.82 496.412 563.92 460.697 583.34 247.847"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-234.569 369.687C-234.569 369.687 -194.461 284.329 -141.459 328.886C-88.4576 373.443 -33.4576 409.318 45.3127 442.027C49.1196 443.608 53.2479 443.892 56.9145 445.256C202.792 499.177 161.395 240.517 284.892 352.198C289.454 356.335 293.887 361.06 298.635 366.017C425.665 498.925 564.903 464.04 583.566 247.877"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-234.766 370.802C-234.766 370.802 -194.298 284.847 -141.05 331.166C-87.8023 377.485 -32.9067 414.58 45.7916 446.544C49.6316 448.107 53.7788 448.363 57.4739 449.685C202.8 501.719 163.156 242.706 286.084 354.167C290.651 358.328 295.089 363.076 299.845 368.081C426.535 501.373 565.675 467.302 583.915 247.906"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-234.861 371.885C-234.861 371.885 -194.102 285.286 -140.599 333.414C-87.0961 381.543 -32.2953 419.78 46.3216 451.015C50.2087 452.569 54.3703 452.772 58.108 454.062C202.905 504.267 164.991 244.843 287.332 356.113C291.913 360.283 296.369 365.065 301.121 370.107C427.409 503.845 566.635 470.588 584.249 247.866"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-235.092 372.896C-235.092 372.896 -193.902 285.749 -140.162 335.653C-86.4222 385.558 -31.6974 424.972 46.8194 455.443C50.7395 456.978 54.9296 457.14 58.6862 458.401C202.96 506.8 166.771 246.942 288.543 357.993C293.129 362.186 297.59 366.992 302.35 372.081C428.301 506.288 567.51 473.817 584.55 247.905"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-235.243 373.94C-235.243 373.94 -193.692 286.197 -139.725 337.891C-85.7578 389.586 -31.0759 430.159 47.3264 459.918C51.2655 461.425 55.4886 461.568 59.2737 462.787C203.023 509.38 168.56 249.088 289.767 359.943C294.354 364.137 298.809 368.98 303.589 374.102C429.161 508.75 568.394 477.094 584.832 247.911"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-235.403 374.999C-235.403 374.999 -193.454 286.603 -139.274 340.14C-85.0935 393.676 -30.4778 435.35 47.8431 464.38C51.8152 465.868 56.0527 465.959 59.8708 467.159C203.087 511.961 170.368 251.206 290.997 361.856C295.588 366.073 300.049 370.939 304.837 376.109C430.045 511.207 569.218 480.309 585.139 247.912"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-235.605 376.029C-235.605 376.029 -193.324 287.079 -138.879 342.35C-84.4335 397.62 -29.9128 440.499 48.3172 468.812C52.3318 470.268 56.6072 470.303 60.4253 471.503C203.099 514.527 172.124 253.31 292.175 363.754C296.78 367.981 301.245 372.871 306.043 378.087C430.938 513.65 570.154 483.6 585.435 247.928"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-235.728 377.092C-235.728 377.092 -193.072 287.556 -138.399 344.616C-83.7267 401.677 -29.2535 445.69 48.9183 473.33C52.9661 474.767 57.2225 474.83 61.107 475.932C203.219 517.206 174.007 255.499 293.49 365.724C298.085 369.965 302.583 374.897 307.376 380.151C431.864 516.136 571.034 486.853 585.784 247.958"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-235.888 378.151C-235.888 378.151 -192.858 288.028 -137.901 346.794C-82.9441 405.561 -28.599 450.797 49.4397 477.693C53.5064 479.101 57.8008 479.108 61.7088 480.205C203.287 519.749 175.811 257.532 294.715 367.552C299.324 371.802 303.827 376.759 308.629 382.06C432.714 518.551 571.914 490.106 586.047 247.931"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-236.039 379.196C-236.039 379.196 -192.648 288.476 -137.511 349.103C-82.3745 409.73 -28.0436 456.021 49.9088 482.224C54.0085 483.614 58.3313 483.579 62.2678 484.634C203.293 522.475 177.562 259.735 295.897 369.535C300.511 373.809 305.009 378.803 309.839 384.123C433.645 520.938 572.751 493.33 586.353 247.933"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-236.199 380.254C-236.199 380.254 -192.466 288.967 -137.06 351.351C-81.6533 413.736 -27.4171 461.171 50.4254 486.686C54.5676 488.043 58.9 487.993 62.9028 488.95C203.362 525.079 179.389 261.825 297.169 371.415C301.783 375.689 306.286 380.707 311.125 386.074C434.491 523.451 573.702 496.57 586.66 247.934"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-236.364 381.289C-236.364 381.289 -192.223 289.396 -136.581 353.618C-80.9376 417.84 -26.8197 466.423 50.9839 491.175C55.1592 492.514 59.4964 492.427 63.4753 493.449C203.386 527.9 181.121 264.055 298.375 373.455C302.984 377.767 307.514 382.803 312.349 388.208C435.397 525.965 574.529 499.93 586.937 247.978"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-236.524 382.348C-236.524 382.348 -192.013 289.844 -136.166 355.8C-80.3194 421.755 -26.1783 471.459 51.468 495.594C55.6623 496.904 60.042 496.784 64.0684 497.736C203.422 530.522 182.934 266.136 299.591 375.297C304.205 379.632 308.74 384.692 313.584 390.144C436.277 528.337 575.424 503.131 587.31 247.881"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-236.675 383.392C-236.675 383.392 -191.799 290.316 -135.697 358.081C-79.5944 425.846 -25.5477 476.632 52.0028 500.088C56.2302 501.38 60.6289 501.232 64.6837 502.141C203.423 533.225 184.76 268.287 300.839 377.243C305.467 381.588 310.007 386.671 314.86 392.17C437.17 530.781 576.275 506.366 587.596 247.972"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-236.873 384.507C-236.873 384.507 -191.627 290.821 -135.326 360.357C-79.0242 429.893 -24.9636 481.753 52.4532 504.587C56.723 505.846 61.1171 505.674 65.2145 506.551C203.457 536.031 186.483 270.47 302.003 379.193C306.635 383.561 311.179 388.668 316.042 394.215C438.062 533.224 577.183 509.637 587.874 247.954"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-237 385.486C-237 385.486 -191.375 291.237 -134.851 362.539C-78.3265 433.842 -24.3419 486.878 53.078 509.039C57.3668 510.27 61.8034 510.065 65.9292 510.9C203.544 538.729 188.263 272.508 303.275 381.073C307.907 385.441 312.447 390.586 317.328 396.165C438.979 535.663 578.049 512.881 588.18 247.956"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-237.16 386.544C-237.16 386.544 -191.165 291.685 -134.456 364.749C-77.7467 437.814 -23.786 491.98 53.4677 513.415C57.799 514.613 62.2451 514.394 66.3994 515.187C203.527 541.46 190.038 274.645 304.486 382.892C309.123 387.284 313.681 392.461 318.557 398.078C439.854 538.012 578.887 516.106 588.468 247.863"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-237.268 387.617C-237.268 387.617 -190.908 292.185 -133.92 367.054C-76.9318 441.923 -23.0092 497.21 54.0829 517.942C58.4473 519.122 62.9123 518.875 67.095 519.625C203.665 544.295 191.926 276.858 305.767 384.941C310.418 389.343 314.981 394.544 319.852 400.198C440.817 540.563 579.865 519.425 588.835 247.987"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-237.471 388.647C-237.471 388.647 -190.741 292.605 -133.525 369.264C-76.3099 445.923 -22.4863 502.269 54.5568 522.375C58.9402 523.526 63.4383 523.261 67.6494 523.969C203.68 547.068 193.738 278.999 306.954 386.826C311.916 391.693 316.629 396.807 321.077 402.149C441.71 542.945 580.712 522.635 589.117 247.932"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-237.645 389.696C-237.645 389.696 -190.526 293.077 -133.074 371.512C-75.6219 449.948 -21.8973 507.352 55.0403 526.855C59.4661 527.973 63.9596 527.684 68.2133 528.36C203.695 549.903 195.438 281.126 308.142 388.771C313.121 393.677 317.847 398.834 322.301 404.222C442.575 545.369 581.573 525.916 589.409 247.985"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-237.796 390.741C-237.796 390.741 -190.288 293.544 -132.637 373.751C-74.9856 453.957 -21.3319 512.44 55.6277 531.241C60.0725 532.332 64.5991 532.024 68.8812 532.657C203.815 552.643 197.284 283.188 309.442 390.609C314.413 395.532 319.127 400.707 323.564 406.116C443.5 547.855 582.5 529.099 589.659 247.949"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.013 391.762C-238.013 391.762 -190.144 293.969 -132.256 375.952C-74.3682 457.934 -20.7149 517.542 56.0498 535.721C60.5751 536.722 65.0732 536.457 69.3838 537.047C203.783 555.487 198.978 285.353 310.601 392.536C315.578 397.505 320.293 402.73 324.727 408.189C444.337 550.261 583.271 532.422 589.946 247.978"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.107 392.844C-238.107 392.844 -189.831 294.385 -131.749 378.237C-73.6666 462.09 -20.0554 522.672 56.6227 540.22C61.1621 541.231 65.7076 540.895 70.103 541.481C204.01 558.464 200.88 287.575 311.925 394.553C316.923 399.531 321.657 404.768 326.107 410.243C445.329 552.709 584.212 535.675 590.327 247.99"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.234 393.823C-238.234 393.823 -189.616 294.856 -131.255 380.391C-72.893 465.926 -19.3908 527.704 57.1866 544.611C61.7259 545.621 66.3235 545.239 70.7332 545.773C204.068 561.204 202.688 289.632 313.188 396.385C318.208 401.373 322.961 406.624 327.426 412.114C446.231 555.138 585.106 538.938 590.595 248.047"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.432 394.938C-238.432 394.938 -189.468 295.366 -130.865 382.7C-72.2617 470.035 -18.8255 532.853 57.6558 549.142C62.2093 550.163 66.8447 549.724 71.3067 550.15C204.116 564.081 204.397 291.745 314.384 398.317C319.414 403.341 324.164 408.637 328.613 414.182C447.233 557.511 586.052 542.153 591.006 247.955"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.592 395.996C-238.592 395.996 -189.225 295.856 -130.414 384.948C-71.602 474.04 -18.2036 537.917 58.1722 553.603C62.7826 554.539 67.4132 554.138 71.8231 554.611C204.174 566.944 206.129 294.036 315.548 400.267C320.585 405.32 325.344 410.643 329.804 416.212C448.083 559.987 586.89 545.439 591.246 247.994"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.743 397.041C-238.743 397.041 -189.001 296.314 -129.976 387.187C-70.9516 478.06 -17.61 543.024 58.6887 558.065C63.3321 558.982 67.9722 558.567 72.4344 558.932C204.231 569.93 207.923 296.145 316.782 402.204C321.827 407.278 326.591 412.625 331.052 418.22C448.99 562.44 587.722 548.701 591.542 248.01"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-238.917 398.09C-238.917 398.09 -188.787 296.786 -129.539 389.425C-70.2919 482.065 -16.9976 548.102 59.205 562.526C63.8769 563.401 68.5405 562.981 73.0171 563.294C204.321 572.835 209.787 298.301 317.983 404.158C323.067 409.259 327.865 414.635 332.357 420.264C449.888 564.907 588.705 551.983 591.904 248.049"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-239.068 399.135C-239.068 399.135 -188.572 297.258 -129.088 391.674C-69.604 486.089 -16.3991 553.171 59.7215 566.987C64.4264 567.843 69.109 567.395 73.6095 567.643C204.397 575.731 211.506 300.339 319.204 406.024C324.28 411.145 329.06 416.55 333.52 422.215C450.786 567.251 589.496 555.216 592.112 247.984"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-239.228 400.193C-239.228 400.193 -188.349 297.716 -128.581 393.959C-68.813 490.203 -15.7305 558.288 60.3082 571.496C65.0415 572.31 69.7477 571.857 74.2625 572.053C204.562 578.707 213.313 302.579 320.49 407.975C325.576 413.124 330.362 418.561 334.824 424.26C451.796 569.732 590.39 558.479 592.488 248.033"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-239.379 401.237C-239.379 401.237 -188.148 298.178 -128.2 396.16C-68.2515 494.142 -15.2022 563.309 60.7545 575.909C65.5208 576.705 70.2744 576.182 74.7752 576.368C204.601 581.598 214.994 304.673 321.635 409.831C326.731 415.009 331.524 420.478 335.988 426.21C452.666 572.119 591.228 561.703 592.71 247.978"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-239.553 402.286C-239.553 402.286 -187.924 298.636 -127.819 398.361C-67.7138 498.085 -14.66 568.34 61.2147 580.333C66.0095 581.086 70.7628 580.624 75.3722 580.74C204.738 584.556 216.84 306.857 322.837 411.786C327.952 416.978 332.76 422.464 337.237 428.217C453.62 574.562 592.108 564.956 593.007 247.993"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-239.685 403.364C-239.685 403.364 -187.682 299.127 -127.312 400.647C-66.9416 502.166 -14.0244 573.475 61.8295 584.86C66.5676 585.288 71.3289 585.397 76.0815 585.189C204.993 587.613 218.675 309.055 324.212 413.756C329.303 418.978 334.085 424.494 338.531 430.276C454.546 576.987 593.001 568.219 593.369 248.032"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-239.864 404.39C-239.864 404.39 -187.495 299.58 -126.874 402.885C-66.2534 506.191 -13.4114 578.492 62.2898 589.284C67.0561 589.696 71.8451 589.776 76.6223 589.523C205.088 590.542 220.385 311.168 325.376 415.645C330.51 420.891 335.323 426.443 339.789 432.269C455.472 579.412 593.9 571.566 593.67 248.072"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.025 405.448C-240.025 405.448 -187.271 300.037 -126.428 405.11C-65.5845 510.182 -12.8179 583.538 62.8059 593.745C67.6055 594.132 72.4261 594.185 77.2329 593.904C205.267 593.528 222.159 313.305 326.605 417.558C331.74 422.834 336.555 428.414 341.023 434.266C456.412 581.846 594.808 574.777 593.981 248.035"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.189 406.483C-240.189 406.483 -187.057 300.509 -125.99 407.348C-64.9242 514.187 -12.233 588.597 63.285 598.262C68.1165 598.627 72.9679 598.646 77.8019 598.319C205.489 596.481 223.939 315.465 327.826 419.484C332.981 424.779 337.805 430.386 342.271 436.273C457.324 584.261 595.702 578.039 594.287 248.037"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.349 407.542C-240.349 407.542 -186.842 300.981 -125.539 409.596C-64.2365 518.211 -11.6395 593.643 63.8157 602.611C68.676 602.954 73.5547 602.943 78.4132 602.578C205.598 599.48 225.681 317.559 328.994 421.397C334.155 426.725 338.985 432.363 343.458 438.28C458.307 586.662 596.582 581.292 594.593 248.038"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.538 408.643C-240.538 408.643 -186.656 301.495 -125.14 411.891C-63.6241 522.287 -11.0977 598.735 64.3175 607.185C69.2064 607.51 74.1128 607.47 78.9954 607.063C205.777 602.588 227.418 319.813 330.252 423.39C335.425 428.735 340.26 434.397 344.729 440.344C459.176 589.171 597.423 584.601 594.852 248.11"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.66 409.645C-240.66 409.645 -186.182 301.794 -124.675 414.087C-62.8592 525.953 -10.4895 603.729 64.8624 611.604C69.7943 611.889 74.7405 611.799 79.6587 611.337C206.055 605.519 229.278 321.885 331.548 425.204C336.731 430.579 341.573 436.272 346.044 442.252C460.192 591.492 598.37 587.756 595.224 248.013"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.825 410.68C-240.825 410.68 -185.996 302.308 -124.214 416.321C-62.1288 530.006 -9.90005 608.75 65.3886 616.051C70.3249 616.341 75.2759 616.255 80.1989 615.793C206.239 608.589 230.986 324.12 332.721 427.201C337.916 432.603 342.764 438.329 347.235 444.344C461.057 593.916 599.235 591.06 595.502 248.057"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-240.985 411.738C-240.985 411.738 -185.777 302.742 -123.777 418.56C-61.474 534.049 -9.32045 613.786 65.9049 620.512C70.8805 620.779 75.8694 620.649 80.8241 620.123C206.489 611.622 232.785 326.252 333.969 429.086C339.184 434.506 344.041 440.259 348.511 446.309C462.01 596.421 600.115 594.313 595.808 248.058"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-241.136 412.783C-241.136 412.783 -185.572 303.228 -123.34 420.798C-60.8096 538.077 -8.75479 618.813 66.4213 624.973C71.4296 625.213 76.4489 625.049 81.4305 624.481C206.729 614.731 234.55 328.403 335.199 430.999C340.418 436.454 345.281 442.238 349.76 448.315C462.988 598.799 600.995 597.566 596.128 248.069"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-241.296 413.842C-241.296 413.842 -185.367 303.714 -122.889 423.046C-60.1595 542.097 -8.17516 623.849 66.9376 629.435C71.9751 629.657 77.0221 629.463 82.0273 628.853C207.003 617.699 236.315 330.554 336.419 432.926C341.659 438.398 346.532 444.21 351.007 450.323C463.924 601.209 601.87 600.857 596.434 248.071"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-241.461 414.877C-241.461 414.877 -185.176 304.191 -122.451 425.285C-59.4901 546.149 -7.64706 628.931 67.4545 633.896C72.5211 634.094 77.5954 633.87 82.6245 633.225C207.281 620.751 238.086 332.728 337.663 434.848C342.906 440.348 347.781 446.188 352.256 452.33C464.874 603.629 602.74 604.124 596.731 248.086"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-241.621 415.935C-241.621 415.935 -184.98 304.691 -122.014 427.523C-58.8397 550.168 -7.11838 633.83 67.971 638.357C73.067 638.538 78.169 638.284 83.2215 637.597C207.583 623.798 239.851 334.879 338.883 436.775C344.141 442.296 349.029 448.158 353.513 454.323C465.823 606.049 603.639 607.349 597.037 248.088"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-241.781 416.994C-241.781 416.994 -184.77 305.139 -121.553 429.757C-58.3361 554.375 -6.51034 638.885 68.4876 642.818C73.613 642.982 78.7428 642.698 83.8186 641.97C207.875 626.86 241.617 337.03 340.113 438.688C345.396 444.254 350.308 450.16 354.818 456.368C466.829 608.507 604.576 610.64 597.414 248.137"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-241.885 417.968C-241.885 417.968 -184.574 305.517 -121.069 431.925C-57.5633 558.334 -5.86932 643.86 69.0512 647.209C74.206 647.349 79.3631 647.035 84.4627 646.271C208.252 629.856 243.42 339.124 341.381 440.544C346.664 446.117 351.567 452.037 356.057 458.266C467.77 610.818 605.46 613.793 597.697 248.02"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-242.092 419.096C-242.092 419.096 -184.403 306.082 -120.665 434.243C-56.9274 562.405 -5.33733 649.028 69.5343 651.75C74.7187 651.872 79.9035 651.529 85.0262 650.723C208.548 633.003 244.977 341.523 342.577 442.536C347.843 448.134 352.733 454.073 357.215 460.315C468.672 613.309 606.293 617.117 597.955 248.092"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-242.257 420.132C-242.257 420.132 -184.188 306.554 -120.214 436.492C-56.2396 566.429 -4.82788 653.955 70.0365 656.202C75.2542 656.302 80.47 655.924 85.6184 655.072C208.944 636.094 246.771 343.632 343.806 444.45C349.104 450.078 354.015 456.059 358.506 462.351C469.891 615.542 607.172 620.37 598.261 248.094"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-242.417 421.19C-242.417 421.19 -183.979 307.064 -119.791 438.721C-55.6028 570.378 -4.2665 658.958 70.5532 660.663C75.8007 660.746 81.0444 660.338 86.2155 659.444C209.255 639.127 248.569 345.764 345.027 446.376C350.344 452.024 355.265 458.032 359.754 464.358C470.765 618.013 608.052 623.623 598.558 248.109"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-242.568 422.235C-242.568 422.235 -183.75 307.545 -119.34 440.969C-54.9291 574.393 -3.72911 663.966 71.0696 665.124C76.3467 665.183 81.6176 664.746 86.8123 663.816C209.655 642.18 250.433 347.92 346.256 448.289C351.578 453.976 356.499 460.025 360.983 466.393C471.61 620.527 608.918 626.928 598.845 248.139"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
        <path
          d="M-242.742 423.284C-242.742 423.284 -183.545 308.031 -118.921 443.236C-54.2975 578.44 -3.2104 669.001 71.5672 669.613C213.061 670.771 251.964 313.793 362.236 468.363C472.508 622.932 609.817 630.152 599.17 248.112"
          stroke="#32CD32"
          strokeWidth="0.17"
          strokeMiterlimit="10"
        />
      </svg>
    );
  }

  throw new Error('Invalid name.');
}

export default Decoration;
