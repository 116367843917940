import styled from 'styled-components';

export const CloseButton = styled.button`
  position: absolute;
  top: 36px;
  right: 36px;
  display: flex;
  align-items: center;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 46px;
  justify-content: center;

  svg {
    height: 32px;
    width: 32px;
  }
`;

export const IframeContainer = styled.div`
  max-height: 100%;
  overflow: auto;
`;
