import Header from './containers/header';
import Footer from './containers/footer';
import Home from './containers/home';
import About from './containers/about';
import Employers from './containers/employers';
import HealthPlans from './containers/healthPlans';
import HealthcareProviders from './containers/healthcareProviders';
import PharmaceuticalCompanies from './containers/pharmaceuticalCompanies';
import DrugDiscountCard from './containers/drugDiscountCard';
import Contact from './containers/contact';
import { GlobalStyle } from './globalStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ModalProvider } from 'use-modal-hook';

const App = () => {
  return (
    <ModalProvider>
      <Router>
        <GlobalStyle />
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/employers">
              <Employers />
            </Route>
            <Route exact path="/health-plans">
              <HealthPlans />
            </Route>
            <Route exact path="/healthcare-providers">
              <HealthcareProviders />
            </Route>
            <Route exact path="/pharmaceutical-companies">
              <PharmaceuticalCompanies />
            </Route>
            <Route exact path="/drug-discount-card">
              <DrugDiscountCard />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </ModalProvider>
  );
};

export default App;
