
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {ForCards, ForCardsContainer,} from '../../components/ui/cards/style';
import {Content,TopContent,BackgroundContent,StyledContainer,StyledBackground} from '../../components/ui/containers/style';
import {
  TopTitle, StyledTitle3, StyledParagraph} from '../../components/ui/text/style';
import { VideoContainer } from '../../components/ui/video/style';
import { BottomWave } from '../../components/ui/waves/style';
import { Dots } from '../../components/ui/dots/style';
import { GreenCircle } from '../../components/ui/circle/style';
import { GreenCross } from '../../components/ui/cross/style';
import { GreyCircle } from '../../components/ui/circle/style';
import { GreyCross } from '../../components/ui/cross/style';
import { LeftImages, EllipseImage } from '../../components/ui/image/style';
import { TopWave } from '../../components/ui/waves/style';
import { ImagesContainer } from '../../components/ui/image/style';
import { CardBtn, ContactBtn } from '../../components/ui/buttons/style';
import { ExpensesSectionContainer, CardExpensesContainer, CardExpensesColumn, CardExpensesBlock, CardColumn } from './styles';
import { StyledDiscountCard } from '../../components/discountCard/style';
import CreditCard from '../../assets/discountCard.png';
import { useModal } from 'use-modal-hook';
import ContactModalForm from '../../components/contactModalForm';
import { ForSectionCards, GridFourCards} from '../../components/ui/cards/style';
import { HelpIcon, Calculator, PharmaceuticalIcon, HealthcareIcon} from '../../components/ui/icons';
import flyerPDF from '../../assets/discount-card.pdf';
import { StyledParagraphDiscount } from './styles';

const greyCircles = [
    { width: 106, top: '0%', left: '52%' },
    { width: 204, top: '25%', right: '1%' },
    { width: 198, top: '56%', right: '6%' },
    { width: 106, top: '73%', left: '10%' },
  ];
  const greenCircles = [
    { width: 16, top: '2%', left: '45%' },
    { width: 24, top: '33%', left: '56%' },
    { width: 33, top: '35%', left: '9%' },
  ];
  const greyCrosses = [
    { width: 18, bottom: '100%', left: '37%' },
    { width: 18, bottom: '23%', left: '89%' },
    { width: 18, bottom: '51%', left: '35%' },
    { width: 10, bottom: '100%', left: '30%' },
    { width: 10, bottom: '80%', left: '90%' },
  ];
  const greenCrosses = [
    { width: 18, bottom: '48%', left: '25%' },
    { width: 18, bottom: '100%', left: '25%' },
    { width: 18, bottom: '70%', left: '55%' },
  ];
  const dotsList = [
    { bottom: '80%', left: '25%' },
    { bottom: '110%', left: '15%' },
    { bottom: '140%', left: '25%', rotate: '90' },
    { bottom: '65%', left: '86%' },
    { bottom: '67rem', left: '66%', rotate: '90' },
    { bottom: '110%', left: '96%' },
  ];


const DrugDiscountCard = () => {

    const [showModal] = useModal(ContactModalForm, {});
    const [textIndex, setTextIndex] = useState(0);
    const videoRef = useRef();


    return (
        <StyledContainer
        style={{
          maxWidth: '1440px',
          margin: '0 auto',
          top: '30px',
        }}
      >
        <Content>
        <TopContent style={{
          maxWidth: '1440px',
          margin: '0 auto',
          top: '90px',
        }} className="attendant-section-container">
           
            <StyledParagraphDiscount >
           
                <StyledTitle3
                    as="span"
                    style={{
                    width: '100%',
                    textAlign: 'left',
                    margin: '0px',
                    fontSize:'55px'
                    }}
                >
                    <span>Clip the Card and Save!</span>
                </StyledTitle3>
                <br />
                <br />
                <span>
                  Use the Tip a ScRxipt Drug Discount Card to save up to 80% at over 65,000 pharmacies nationwide, including all major chains.
                </span>
                <ContactBtn style={{
                  marginTop: '2rem',
                  maxWidth: '347px',
                }} onClick={() => window.open(flyerPDF)}>DOWNLOAD CARD</ContactBtn>
                <br />
                <br />
               
            </StyledParagraphDiscount>
            <LeftImages>
              <ImagesContainer>
                {/* <CelPhone1 src={Celphone1} /> */}
                {/* <CelPhone2 src={Celphone2} /> */}
                <StyledDiscountCard src={CreditCard} />
              </ImagesContainer>
            </LeftImages>
          </TopContent>
         
         
          
          <ForCardsContainer style={{gridGap: '2rem', margin:'0rem auto 10rem auto', maxWidth:'1000px'}}>
            <ForSectionCards  onClick={()=>window.open("http://www.drugdiscountcardinfo.com/#pharmacy-sec")}>
              <HealthcareIcon />	
              <span style={{minHeight: '40px'}}>Pharmacy Lookup</span>
              <p>
              Find a participating pharmacy near you.
              </p>
              <CardBtn style={{
                  margin: '1rem auto',
                  maxWidth: '200px',
                }} >tool</CardBtn>
            </ForSectionCards>
            <ForSectionCards  onClick={()=>window.open("https://www.needymeds.org/drug-pricing")}>
              <Calculator />	
              <span>NeedyMeds Drug Pricing Calculator</span>
              <p>
              Compare costs and find the best prices in your area.
              </p>
              <CardBtn style={{
                  margin: '1rem auto',
                  maxWidth: '200px',
                }}>GO TO Calculator</CardBtn>
            </ForSectionCards>
            <ForSectionCards  onClick={()=>window.open("http://www.drugdiscountcardinfo.com/")}>
              <HelpIcon />	
              <span>Frequently Asked Questions (FAQ's)</span>
              <p>
              Do you have questions about the Drug Discount Card? See more.
              </p>
              <CardBtn style={{
                  margin: '1rem auto',
                  maxWidth: '200px',
                }}>VIEW FAQS</CardBtn>
            </ForSectionCards>
            
            
          </ForCardsContainer>

         
      
            
  
          
         
        </Content>
  
        <BackgroundContent>
          <StyledBackground />
          <TopWave />
          <BottomWave />
          {greyCircles.map((circle, key) => (
            <GreyCircle
              key={key}
              width={circle.width}
              top={circle.top}
              left={circle.left}
              bottom={circle.bottom}
              right={circle.right}
            />
          ))}
  
          {greenCircles.map((circle, key) => (
            <GreenCircle
              key={key}
              width={circle.width}
              top={circle.top}
              left={circle.left}
              bottom={circle.bottom}
              right={circle.right}
            />
          ))}
          {greyCrosses.map((cross, key) => (
            <GreyCross
              key={key}
              width={cross.width}
              top={cross.top}
              left={cross.left}
              bottom={cross.bottom}
              right={cross.right}
            />
          ))}
  
          {greenCrosses.map((cross, key) => (
            <GreenCross
              key={key}
              width={cross.width}
              top={cross.top}
              left={cross.left}
              bottom={cross.bottom}
              right={cross.right}
            />
          ))}
  
          {dotsList.map((dot, key) => (
            <Dots
              key={key}
              rotate={dot.rotate}
              top={dot.top}
              left={dot.left}
              bottom={dot.bottom}
              right={dot.right}
            />
          ))}
        </BackgroundContent>
      </StyledContainer>
    )
}

export default DrugDiscountCard;