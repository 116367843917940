import SVGPathCommander from 'svg-path-commander';
import {
  useMemo,
  useCallback,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import { useThrottle } from 'react-use';
import random from 'lodash.random';

const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};
function useMeasure(element) {
  const [rect, setRect] = useState(defaultState);
  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );
  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [element]);
  return rect;
}

// Direction from where the line starts
export const DIRECTION = {
  TOP_LEFT: 'top-left',
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  RIGHT: 'right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM: 'bottom',
  BOTTOM_RIGHT: 'bottom-right',
  LEFT: 'left',
};

export function getCoordOfNode(node, direction, bodyRect, offset) {
  const nodeRect = node.getBoundingClientRect();
  const top = nodeRect.top - bodyRect.top;
  const left = nodeRect.left - bodyRect.left;
  if (direction === DIRECTION.TOP_LEFT) {
    return {
      x: left + (offset?.x ?? 0),
      y: top + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.TOP_RIGHT) {
    return {
      x: left + nodeRect.width + (offset?.x ?? 0),
      y: top + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.BOTTOM_LEFT) {
    return {
      x: left + (offset?.x ?? 0),
      y: top + nodeRect.height + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.BOTTOM_RIGHT) {
    return {
      x: left + nodeRect.width + (offset?.x ?? 0),
      y: top + nodeRect.height + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.TOP) {
    return {
      x: left + nodeRect.width / 2 + (offset?.x ?? 0),
      y: top + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.BOTTOM) {
    return {
      x: left + nodeRect.width / 2 + (offset?.x ?? 0),
      y: top + nodeRect.height + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.RIGHT) {
    return {
      x: left + nodeRect.width + (offset?.x ?? 0),
      y: top + nodeRect.height / 2 + (offset?.y ?? 0),
    };
  }
  if (direction === DIRECTION.LEFT) {
    return {
      x: left + (offset?.x ?? 0),
      y: top + nodeRect.height / 2 + (offset?.y ?? 0),
    };
  }
  throw new Error('Invalid direction');
}

// Calculate the position and size for the <div /> containing the svg
function getSvgContainerStyles({
  nodeA,
  nodeADirection,
  nodeB,
  nodeBDirection,
  containerOffset,
}) {
  const bodyRect = document.body.getBoundingClientRect();
  const nodeACoord = getCoordOfNode(nodeA, nodeADirection, bodyRect);
  const nodeBCoord = getCoordOfNode(nodeB, nodeBDirection, bodyRect);
  const svgContainerHeight =
    Math.abs(nodeACoord.y - nodeBCoord.y) -
    (containerOffset?.top ?? 0) +
    (containerOffset?.bottom ?? 0);
  const svgContainerWidth =
    Math.abs(nodeACoord.x - nodeBCoord.x) -
    (containerOffset?.left ?? 0) +
    (containerOffset?.right ?? 0);
  const svgContainerTop =
    Math.min(nodeACoord.y, nodeBCoord.y) + (containerOffset?.top ?? 0);
  const svgContainerLeft =
    Math.min(nodeACoord.x, nodeBCoord.x) + (containerOffset?.left ?? 0);
  const svgContainerStyles = {
    left: svgContainerLeft,
    top: svgContainerTop,
    height: svgContainerHeight,
    width: svgContainerWidth,
  };

  return svgContainerStyles;
}

function Line(props) {
  const {
    svgWidth,
    svgHeight,
    svgPathD,
    nodeA,
    nodeB,
    nodeADirection,
    nodeBDirection,
    containerOffset,
    svgStyles,
  } = props;
  const [hideLine, setHideLine] = useState(false);
  const [uuid, setUuid] = useState();
  const throttledUuid = useThrottle(uuid, 100);
  const [scaledD, setScaledD] = useState('');
  const [svgContainerStyles, setSvgContainerStyles] = useState({});
  const nodeARect = useMeasure(nodeA);
  const nodeBRect = useMeasure(nodeB);
  const nodeARectId = `${nodeARect.bottom}${nodeARect.top}${nodeARect.left}${nodeARect.right}${nodeARect.width}${nodeARect.height}`;
  const nodeBRectId = `${nodeBRect.bottom}${nodeBRect.top}${nodeBRect.left}${nodeBRect.right}${nodeBRect.width}${nodeBRect.height}`;

  const refreshUuid = useCallback(() => {
    setUuid(random(0, 9999));
  }, []);

  // Recalculate on nodes resize
  useEffect(() => {
    refreshUuid();
  }, [refreshUuid, nodeARectId, nodeBRectId]);

  // Recalculate on window resize
  useEffect(() => {
    window.addEventListener('resize', refreshUuid);
    return () => {
      window.removeEventListener('resize', refreshUuid);
    };
  }, [refreshUuid]);

  useEffect(() => {
    if (!nodeA || !nodeB) {
      return;
    }

    setHideLine(true);

    window.requestAnimationFrame(() => {
      // Calculate `svgPathD` container size and position
      const svgContainerStyles = getSvgContainerStyles({
        nodeA,
        nodeADirection,
        nodeB,
        nodeBDirection,
        containerOffset,
      });

      // Adapt `svgPathD` to container size
      const scaledD = new SVGPathCommander(svgPathD)
        .transform({
          origin: [0, 0, 1],
          scale: [
            svgContainerStyles.width / svgWidth,
            svgContainerStyles.height / svgHeight,
            1,
          ],
        })
        .toString();

      setScaledD(scaledD);
      setSvgContainerStyles(svgContainerStyles);
      setHideLine(false);
    });
    // eslint-disable-next-line
  }, [nodeA, nodeB, throttledUuid]);
  const styles = {
    ...svgContainerStyles,
    position: 'absolute',
    opacity: hideLine ? 0 : 1,
    transition: 'opacity 200ms linear',
  };

  if (
    !nodeA ||
    !nodeB ||
    !svgContainerStyles.width ||
    !svgContainerStyles.height
  ) {
    return null;
  }

  return (
    <div style={styles}>
      <svg
        style={svgStyles}
        width={svgContainerStyles.width}
        height={svgContainerStyles.height}
        viewBox={`0 0 ${svgContainerStyles.width} ${svgContainerStyles.height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={scaledD} stroke="#32CD32" strokeWidth="1" />
      </svg>
    </div>
  );
}

export default Line;
