import styled, { css } from 'styled-components';

export const ContactBtn = styled.button`
  appearence: none;
  background: #32cd32;
  border-radius: 30px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  height: 56px;
  line-height: 14px;
  text-transform: uppercase;
  border: none;
  width: 380px;
  display: block;
  margin: 5rem auto 3rem;
  outline: none;
  cursor: pointer;

  @media (max-width: 450px) {
    margin: 5rem auto 3rem;
  }
`;


export const CardBtn = styled.button`
  appearence: none;
  background: #32cd32;
  border-radius: 30px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  height: 43px;
  line-height: 10px;
  text-transform: uppercase;
  border: none;
  width: 380px;
  display: block;
  margin: 5rem auto 3rem;
  outline: none;
  cursor: pointer;

  @media (max-width: 450px) {
    margin: 5rem auto 3rem;
  }
`;
