import styled, { css } from 'styled-components';
import LinkedIn from '../../assets/LinkedIn.svg';
import Facebook from '../../assets/Facebook.svg';
import Twitter from '../../assets/Twitter.svg';
import LinkedInFooter from '../../assets/LinkedInFooter.svg';
import FacebookFooter from '../../assets/FacebookFooter.svg';
import TwitterFooter from '../../assets/TwitterFooter.svg';

import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 2rem auto auto;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    display: ${({ burgerMenu }) => (burgerMenu ? 'inherit' : 'none')};
  }
`;
export const StyledButtonNav = styled.div`
  text-align: left;
  width: 100%;
  margin-top: 0.5rem;
  flex: 2;
`;
export const StyledNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    
    display: none;
  }

  @media (max-width: 490px) {
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    
        display: none;
   
  }
`;

export const StyledButtonNavSpan = styled(Link)`
  font-weight: 700;
  display: block;
  margin: auto;
  margin-top: 0.2rem;
  padding: 0 10px;
  font-size: 1rem;
  color: ${(props) => (props.footer ? '#FFF' : '#000')};
  white-space: nowrap;
  text-decoration: none;
  ${(props) =>
    props.activenav &&
    css`
      color: #32cd32;
    `};

  ${(props) =>
    props.activenav &&
    !props.footer &&
    css`
      &:after {
        content: '';
        width: 100%;
        display: block;
      }

      @media (max-width: 1000px) {
        border-bottom:0px solid #32cd32;
      }

      @media (min-width: 1000px) {
        border-bottom:3px solid #32cd32;
      }
    `}
`;
const NavIcon = styled.div`
  align-self: flex-end;
  width: 2.5em;
  margin: 10px;
  height: 2.5em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`;
export const StyledLinkedIn = styled(NavIcon)`
  background-image: url(${(props) =>
    props.footer ? LinkedInFooter : LinkedIn});
`;

export const StyledFacebook = styled(NavIcon)`
  background-image: url(${(props) =>
    props.footer ? FacebookFooter : Facebook});
`;

export const StyledTwitter = styled(NavIcon)`
  background-image: url(${(props) => (props.footer ? TwitterFooter : Twitter)});
`;
