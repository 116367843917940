import videojs from 'video.js';
import TextTransition, { presets } from 'react-text-transition';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  ForCards,
  ForSectionCards,
  GridFourCards,
  GridThreeCards,
} from '../../components/ui/cards/style';
import {
  Content,
  TopContent,
  BackgroundContent,
  StyledContainer,
  StyledBackground,
} from '../../components/ui/containers/style';
import {
  TopTitle,
  StyledParagraph,
  StyledTitle3,
} from '../../components/ui/text/style';
import { VideoContainer } from '../../components/ui/video/style';
import { BottomWave } from '../../components/ui/waves/style';
import { Dots } from '../../components/ui/dots/style';
import { GreenCircle } from '../../components/ui/circle/style';
import { GreenCross } from '../../components/ui/cross/style';
import { GreyCircle } from '../../components/ui/circle/style';
import { GreyCross } from '../../components/ui/cross/style';
import { LeftImages } from '../../components/ui/image/style';
import { StyledCreditCard } from '../../components/creditCard/style';
import { TopWave } from '../../components/ui/waves/style';
import { ImagesContainer } from '../../components/ui/image/style';
import { ContactBtn } from '../../components/ui/buttons/style';

import { useModal } from 'use-modal-hook';
import ContactModalForm from '../../components/contactModalForm';
import CreditCard from '../../assets/card.png';
import {
  CaseIcon,
  ChartIcon,
  PharmaceuticalIcon,
  HealthcareIcon,
} from '../../components/ui/icons';

import './styles.css';

const greyCircles = [
  { width: 106, top: '0%', left: '52%' },
  { width: 204, top: '25%', right: '1%' },
  { width: 198, top: '56%', right: '6%' },
  { width: 106, top: '73%', left: '10%' },
];
const greenCircles = [
  { width: 16, top: '2%', left: '45%' },
  { width: 24, top: '33%', left: '56%' },
  { width: 33, top: '35%', left: '9%' },
];
const greyCrosses = [
  { width: 18, bottom: '100%', left: '37%' },
  { width: 18, bottom: '23%', left: '89%' },
  { width: 18, bottom: '51%', left: '35%' },
  { width: 10, bottom: '100%', left: '30%' },
  { width: 10, bottom: '80%', left: '90%' },
];
const greenCrosses = [
  { width: 18, bottom: '48%', left: '25%' },
  { width: 18, bottom: '100%', left: '25%' },
  { width: 18, bottom: '70%', left: '55%' },
];
const dotsList = [
  { bottom: '80%', left: '25%' },
  { bottom: '110%', left: '15%' },
  { bottom: '140%', left: '25%', rotate: '90' },
  { bottom: '65%', left: '86%' },
  { bottom: '67rem', left: '66%', rotate: '90' },
  { bottom: '110%', left: '96%' },
];

const texts = ['Customers', 'Members', 'Patients', 'Employees'];

const Home = () => {
  const [showModal] = useModal(ContactModalForm, {});
  const [textIndex, setTextIndex] = useState(0);
  const videoRef = useRef();

  useEffect(() => {
    const id = setInterval(() => {
      setTextIndex((val) => val + 1);
    }, 2000);
    return () => clearTimeout(id);
  }, []);

  useLayoutEffect(() => {
    videojs(videoRef?.current, { fluid: true });
  }, []);

  return (
    <StyledContainer
      style={{
        maxWidth: '1440px',
        margin: '0 auto',
        top: '30px',
      }}
    >
      <Content>
        <TopContent className="main-title-with-phones-container">
          <TopTitle>
            Help your{' '}
            <span>
              <TextTransition
                text={texts[textIndex % texts.length]}
                springConfig={presets.gentle}
                inline
              />
            </span>{' '}
            afford Healthcare, Wellness and Lifestyle expenses.
          </TopTitle>
          <LeftImages>
            <ImagesContainer>
              {/* <CelPhone1 src={Celphone1} /> */}
              {/* <CelPhone2 src={Celphone2} /> */}
              <StyledCreditCard src={CreditCard} />
            </ImagesContainer>
          </LeftImages>
        </TopContent>

        <TopContent className="attendant-section-container">
          <LeftImages className="video-container">
            <VideoContainer>
              <video
                id="homeVideo"
                ref={videoRef}
                className="video-js vjs-theme-sea"
                controls
                preload="auto"
                poster="/video_poster.jpeg"
              >
                <source src="/tip_a_scrxipt.mp4" type="video/mp4"></source>
                <p classNam="vjs-no-js">
                  To view this video please enable JavaScript, and consider
                  upgrading to a web browser that
                  <a
                    href="https://videojs.com/html5-video-support/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    supports HTML5 video
                  </a>
                </p>
              </video>
            </VideoContainer>
          </LeftImages>
          <StyledParagraph style={{ margin: 0 }}>
            <StyledTitle3
              as="span"
              style={{
                width: '100%',
                textAlign: 'left',
                margin: '0',
              }}
            >
              What is <span>Tip a ScRxipt</span>?
            </StyledTitle3>
            <br />
            <br />
            Controlled Spending as a Service (CSaaS): Tip a ScRxipt is a
            controlled-expense debit card issuing platform that enables
            government, healthcare providers, health plans, foundation and
            community organizations to provide “payment assistance with guard
            rails.”
            <br />
            <br />
            Tip a ScRxipt debit cards increase adherence and improve health
            outcomes by building customized card programs that help participants
            cover approved out-of-pocket (OOP) prescription, healthcare, healthy
            food (Food is Medicine) and lifestyle expenses.
            <br />
            <br />
            Our debit card platform provides customizable guard rails allowing
            you to be generous with the funds you provide, while still being in
            control of how and where funds are spent.
            <br />
          </StyledParagraph>
        </TopContent>
        <GridThreeCards>
          <ForSectionCards onClick={() => window.open('health-plans')}>
            <ChartIcon />
            <span>Health Plans</span>
            <p>
              Prepaid or reloadable debit card for insured beneficiaries to
              cover approved out-of-pocket expenses
            </p>
          </ForSectionCards>
          <ForSectionCards
            onClick={() => window.open('./healthcare-providers')}
          >
            <HealthcareIcon />
            <span>Healthcare Providers</span>
            <p>
              Automate existing payment assistance programs to reduce hospital
              readmissions and medication-related ER visits
            </p>
          </ForSectionCards>
          <ForSectionCards
            onClick={() => window.open('./pharmaceutical-companies')}
          >
            <PharmaceuticalIcon />
            <span>Pharmaceutical Companies</span>
            <p>
              Increase affordability and adherence with a customized payment
              assistance program for your medications
            </p>
          </ForSectionCards>
        </GridThreeCards>
        <TopContent
          style={{
            padding: '2rem',
            display: 'block',
            maxWidth: '800px',
            margin: '4rem auto',
          }}
        >
          <StyledParagraph as="div" style={{ width: '100%' }}>
            <StyledTitle3
              as="span"
              style={{
                width: '100%',
                textAlign: 'left',
                margin: '0',
              }}
            >
              Why <span>Tip a ScRxipt</span>?
            </StyledTitle3>

            <br />
            <br />

            <span>
              1 in 5 American Adults report inability to pay out-of-pocket (OOP)
              for needed medication and healthcare expenses. Medication “Non”
              adherence costs our healthcare system more.
            </span>

            <br />
            <br />
            <span>
              1 in 5 deaths are caused by preventable dietary risks. Diet
              related disease is increasing health care spending and taking
              lives.
            </span>
            <br />
            <br />
            <span>
              People forgo medical treatment, preventative care, and experience
              food insecurity due to the inability to pay. This leads to
              unfilled prescriptions, canceled appointments, hospital
              readmissions and ER visits, diet related diseases and more than
              $528 billion in healthcare consequences.
            </span>
            <br />
            <br />
            <span>
              Tip a ScRxipt provides the platform for all stakeholders to
              provide funds that keep people alive and well.
            </span>
          </StyledParagraph>

          <ContactBtn onClick={() => showModal()}>Contact Us</ContactBtn>
        </TopContent>
      </Content>

      <BackgroundContent>
        <StyledBackground />
        <TopWave />
        <BottomWave />
        {greyCircles.map((circle, key) => (
          <GreyCircle
            key={key}
            width={circle.width}
            top={circle.top}
            left={circle.left}
            bottom={circle.bottom}
            right={circle.right}
          />
        ))}

        {greenCircles.map((circle, key) => (
          <GreenCircle
            key={key}
            width={circle.width}
            top={circle.top}
            left={circle.left}
            bottom={circle.bottom}
            right={circle.right}
          />
        ))}
        {greyCrosses.map((cross, key) => (
          <GreyCross
            key={key}
            width={cross.width}
            top={cross.top}
            left={cross.left}
            bottom={cross.bottom}
            right={cross.right}
          />
        ))}

        {greenCrosses.map((cross, key) => (
          <GreenCross
            key={key}
            width={cross.width}
            top={cross.top}
            left={cross.left}
            bottom={cross.bottom}
            right={cross.right}
          />
        ))}

        {dotsList.map((dot, key) => (
          <Dots
            key={key}
            rotate={dot.rotate}
            top={dot.top}
            left={dot.left}
            bottom={dot.bottom}
            right={dot.right}
          />
        ))}
      </BackgroundContent>
    </StyledContainer>
  );
};

export default Home;
