import styled, { css } from 'styled-components';

export const TopTitle = styled.h1`
  display: block;
  margin-left: 2rem;
  margin-top: -0.5rem;
  width: 50%;
  font-size: 96px;
  line-height: 1;

  & span {
    color: #32cd32;
  }

  @media (max-width: 490px) {
    font-size: 52px;
    width: 80%;
    margin: 2rem auto 4rem;
  }
`;

export const StyledParagraph = styled.h4`
  font-size: 28px;
  font-weight: normal;
  width: 60%;

  strong {
    color: #32cd32;
    font-weight: 400;
  }
`;


export const StyledTitle2 = styled.h2`
  font-size: 53px;
  font-weight: bold;
  width: 46%;
  margin: 12rem auto;
  & span {
    color: #32cd32;
  }

  @media (max-width: 993px) {
    font-size: 36px;
    width: 80%;
    margin: 6rem auto;
  }
`;

export const StyledTitle3 = styled.h3`
  font-size: 35px;
  font-weight: bold;
  width: 50%;
  text-align: center;
  margin: 3rem auto;
  & span {
    color: #32cd32;
  }
  @media (max-width: 882px) {
    width: 80%;
  }
`;

