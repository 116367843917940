import {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import { useThrottle } from 'react-use';
import { getCoordOfNode } from '../line';
import random from 'lodash.random';
import turfCenter from '@turf/center';
import { points as turfPoints } from '@turf/helpers';
import { getCoord } from '@turf/invariant';

const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};
function useMeasureEls(elements) {
  const [rects, setRects] = useState(elements.map(() => defaultState));

  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        const result = [];
        for (const entry of entries) {
          const { x, y, width, height, top, left, bottom, right } =
            entry.contentRect;
          result.push({ x, y, width, height, top, left, bottom, right });
        }
        setRects(result);
      }),
    []
  );

  useLayoutEffect(() => {
    if (elements.every((el) => !el)) {
      return;
    }
    for (const element of elements) {
      observer.observe(element);
    }
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [elements]);

  return rects;
}

// Return a different uuid for each layout change on the passed nodes
// Return a different uuid for each window resize
const useLayoutUuid = (nodes) => {
  const [uuid, setUuid] = useState();
  const throttledUuid = useThrottle(uuid, 100);
  const nodesRects = useMeasureEls(nodes);
  const nodesRectsId = useMemo(() => {
    const result = [];
    for (const nodeRect of nodesRects) {
      result.push(
        `${nodeRect.bottom}${nodeRect.top}${nodeRect.left}${nodeRect.right}${nodeRect.width}${nodeRect.height}`
      );
    }
    return result.join('');
  }, [nodesRects]);

  const refreshUuid = useCallback(() => {
    setUuid(random(0, 9999));
  }, []);

  // Recalculate on nodes resize
  useEffect(() => {
    refreshUuid();
  }, [refreshUuid, nodesRectsId]);

  // Recalculate on window resize
  useEffect(() => {
    window.addEventListener('resize', refreshUuid);
    return () => {
      window.removeEventListener('resize', refreshUuid);
    };
  }, [refreshUuid]);

  return throttledUuid;
};

// Calculates the center of the points, and position a <div /> there
function DecorationContainer(props) {
  const { points, children, style } = props;
  const [styles, setStyles] = useState({});
  const nodes = useMemo(() => points.map((point) => point.node), [points]);
  const layoutUuid = useLayoutUuid(nodes);

  useEffect(() => {
    const bodyRect = document.body.getBoundingClientRect();
    const axis = [];
    for (const { node, direction, offset } of points) {
      if (!node) {
        return;
      }
      const nodeCoord = getCoordOfNode(node, direction, bodyRect, offset);
      axis.push(nodeCoord);
    }
    const features = turfPoints(axis.map(({ x, y }) => [x, y]));
    const centerFeature = turfCenter(features);
    const center = getCoord(centerFeature);

    setStyles({
      top: center[1],
      left: center[0],
      position: 'absolute',
      pointerEvents: 'none',
    });
  }, [points, layoutUuid]);

  return (
    <div style={styles}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'translate(-50%, -50%)',
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default DecorationContainer;
