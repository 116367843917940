import { memo, useRef } from 'react';
import Modal from 'react-modal';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { CloseButton, IframeContainer } from './style.js';

Modal.setAppElement('#root');

const ContactModalForm = memo(({ isOpen, onClose }) => {
  const modalRef = useRef();

  return (
    <Modal
      ref={modalRef}
      onAfterOpen={() => disableBodyScroll(modalRef.current?.node)}
      onAfterClose={() => clearAllBodyScrollLocks()}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        },
        content: {
          inset: 'auto 20px',
          width: 'calc(100% - 40px)',
          maxWidth: '700px',
          margin: '0 auto',
          padding: 0,
        },
      }}
    >
      <CloseButton type="button" onClick={() => onClose()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          style={{ fill: '#000000' }}
        >
          <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
        </svg>
      </CloseButton>

      <IframeContainer>
        <iframe
          id="JotFormIFrame-212286018479057"
          title="Contact Us"
          onLoad={() => window.parent.scrollTo(0, 0)}
          allowtransparency="true"
          allowFullScreen
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/212286018479057"
          frameBorder="0"
          style={{
            minWidth: '100%',
            height: '539px',
            border: 'none',
          }}
          scrolling="no"
        ></iframe>
      </IframeContainer>
    </Modal>
  );
});

export default ContactModalForm;
