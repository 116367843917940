import React from 'react';
import { StyledFooterContainer, StyledCopyright } from './style';
import NavToolBar from '../../components/navToolBar';
const Footer = () => {
  const date = new Date();

  return (
    <StyledFooterContainer>
      <StyledCopyright>Copyright {date.getFullYear()}</StyledCopyright>
      <NavToolBar footer burgerMenu />
    </StyledFooterContainer>
  );
};
export default Footer;
