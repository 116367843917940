

export const PeopleIcon = () => {
    return (
        <svg width="30px" height="30px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#32cd32"><path d="M1 20v-1a7 7 0 017-7v0a7 7 0 017 7v1" stroke="#32cd32" strokeWidth="1.5" strokeLinecap="round"></path><path d="M13 14v0a5 5 0 015-5v0a5 5 0 015 5v.5" stroke="#32cd32" strokeWidth="1.5" strokeLinecap="round"></path><path d="M8 12a4 4 0 100-8 4 4 0 000 8zM18 9a3 3 0 100-6 3 3 0 000 6z" stroke="#32cd32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
   
         )
}

export const CaseIcon = () => {
    return (
        <svg width="30px" height="30px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#32cd32"><path d="M8 7H4a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V9a2 2 0 00-2-2h-4M8 7V3.6a.6.6 0 01.6-.6h6.8a.6.6 0 01.6.6V7M8 7h8" stroke="#32cd32" strokeWidth="1.5"></path></svg>
    )
}

export const ChartIcon = () => {
    return (
       <svg width="30px" height="30px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#32cd32"><path d="M20 20H4V4" stroke="#32cd32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 16.5L12 9l3 3 4.5-4.5" stroke="#32cd32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
    )
}

export const PharmaceuticalIcon = () => {
    return (
       <svg width="30px" height="30px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#32cd32"><path d="M21 8v8a5 5 0 01-5 5H8a5 5 0 01-5-5V8a5 5 0 015-5h8a5 5 0 015 5z" stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13.9 18h-3.8a.6.6 0 01-.6-.6v-2.3a.6.6 0 00-.6-.6H6.6a.6.6 0 01-.6-.6v-3.8a.6.6 0 01.6-.6h2.3a.6.6 0 00.6-.6V6.6a.6.6 0 01.6-.6h3.8a.6.6 0 01.6.6v2.3a.6.6 0 00.6.6h2.3a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-2.3a.6.6 0 00-.6.6v2.3a.6.6 0 01-.6.6z" stroke="#32cd32" stroke-width="1.5"></path></svg>
    )
}

export const HealthcareIcon = () => {
    return (
      <svg width="30px" height="30px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#32cd32"><path d="M18 20l3.824-3.824a.6.6 0 00.176-.424V10.5A1.5 1.5 0 0020.5 9v0a1.5 1.5 0 00-1.5 1.5V15" stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 16l.858-.858a.484.484 0 00.142-.343v0a.485.485 0 00-.268-.433l-.443-.221a2 2 0 00-2.308.374l-.895.895a2 2 0 00-.586 1.414V20M6 20l-3.824-3.824A.6.6 0 012 15.752V10.5A1.5 1.5 0 013.5 9v0A1.5 1.5 0 015 10.5V15" stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6 16l-.858-.858A.485.485 0 015 14.799v0c0-.183.104-.35.268-.433l.443-.221a2 2 0 012.308.374l.895.895a2 2 0 01.586 1.414V20M13.667 12h-3.334V9.667H8V6.333h2.333V4h3.334v2.333H16v3.334h-2.333V12z" stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    )
    }


    export const HelpIcon = () => {
        return (
            <svg width="30px" height="30px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#32cd32"><path stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"></path><path stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M9 9c0-3.5 5.5-3.5 5.5 0 0 2.5-2.5 2-2.5 5M12 18.01l.01-.011"></path></svg>
        )
    }

    export const Calculator = () =>{
        return (
           <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="none" stroke-width="1.5" viewBox="0 0 24 24" color="#32cd32"><path stroke="#32cd32" stroke-width="1.5" d="M1 21V3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2Z"></path><path stroke="#32cd32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M15 7h4M15 15.5h4M15 18.5h4M5 7h2m2 0H7m0 0V5m0 2v2M5.586 18.414 7 17m1.415-1.414L7 17m0 0-1.414-1.414M7 17l1.415 1.414"></path></svg>
        )
    }