import React from 'react';
import {
  NavContainer,
  StyledButtonNav,
  StyledButtonNavSpan,
  StyledNavContainer,
} from './style';

const SignInBar = () => {
  return (
    <NavContainer>
      <StyledNavContainer>
        {/*<StyledButtonNav>
          <StyledButtonNavSpan
            target="_blank"
            to={{ pathname: 'https://tipascrxipt.summitwith.us/' }}
          >
            Admin Sign In
          </StyledButtonNavSpan>
        </StyledButtonNav>
        <StyledButtonNav>
          <StyledButtonNavSpan
            target="_blank"
            to={{ pathname: 'https://tipascrxipt.summitfor.me/' }}
          >
            User Sign In
          </StyledButtonNavSpan>
  </StyledButtonNav>*/}
      </StyledNavContainer>
    </NavContainer>
  );
};

export default SignInBar;
