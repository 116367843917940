import styled from 'styled-components';
import { ReactComponent as BurgerIcon } from '../../assets/burger_menu.svg';

export const Logo = styled.img`
  margin: 1rem;
  display: block;
  max-width: 15rem;
  max-height: 3rem;
  flex: 1;
  cursor: pointer;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

export const StyledHeader = styled.header`
  padding: 0.5rem 2rem 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  transition: height 0.25s linear;

  @media (max-width: 1000px) {
    // width: 85%;
    overflow: hidden;
    justify-content: center;
  }
`;

export const BurgerMenuIcon = styled(BurgerIcon)``;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const BurgerButtonLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBarContainer = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;

    & > div {
      margin: unset;
    }
  }
`;
