import styled, { css } from 'styled-components';

export const StyledDiscountCard = styled.img`
  position: absolute;
  maxWidth: '450px',
  top: '0px',
  margin: '0px',
  max-width: 410px;
  width: 100%;
`;

export const StyledParagraphDiscount = styled.h4`
  font-size: 28px;
  font-weight: normal;
  width: 50%;
  margin-top:120px;

  strong {
    color: #32cd32;
    font-weight: 400;
  }

  @media (max-width: 490px) {
    margin-top: 250px;
  }

  
`;
